import { getAdminVersion } from "api/settings";
import VersionController from "./VersionController";
import { useEffect, useState } from "react";

export default function AdminVersion() {

  const [version, setVersion] = useState('-')

  const update = async () => {
    const data = await getAdminVersion()
    if(data) setVersion(data)
  }

  useEffect(()=>{
    update()
  },[])

  return (
    <VersionController
      app='Admin'
      version={version}
      update={update}
    />
  )
}