import call, { upload } from "utils/call";

export async function getBanners(position) {
  return await call('website/getBanners/'+position)
}

export async function sortBanners(data) {
  await call('website/sortBanners', data)
}

export async function addBanner(position) {
  await call('website/addBanner', {position: position})
}

export async function delBanner(id) {
  await call('website/delBanner', {id: id})
}

export async function saveBanner(data) {
  await call('website/saveBanner', data)
}

export async function updateBanner(data) {
  await upload('website/updateBanner', data)
}