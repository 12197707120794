import { Stack } from "@mui/material"
import OrderListCard from "pages/new-orders/components/OrderListCard"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { filterNewOrderList, newOrdersStates } from "redux/newOrdersSlice"
import SortChip from "../Chips/SortChip"

/***************************************************************************************/
// 订单列表
export default function PickUpOrderListCard() {

  const logisticsName = "自取"
  const logisticsID = 1

  const [orders, setOrders] = useState([])
  const [sort, setSort] = useState({label: '时间', index: 'id'})

  const { newOrders } = useSelector(newOrdersStates)

  useEffect(() => {
    const tempOrders = filterNewOrderList(logisticsID, sort.index)
    setOrders(tempOrders)
  }, [sort, newOrders])
  
  const Actions = () => 
  <Stack direction='row' spacing={1}>
    <SortChip sort={sort} callback={setSort} />
  </Stack>
  
  return (
    <>
    { orders && orders.length > 0 &&
    <OrderListCard 
      title={`${logisticsName} (${orders.length})`}
      actions={<Actions />}
      orders={orders}
    />
    }
    </>
  )
}