import { Chip } from "@mui/material"
import RSTable from "rsui/RSTable"
import { useNavigate } from "react-router-dom"

export default function PurchaseList({ purchases }) {

  const navigate = useNavigate()

  const cols = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: '公司',
      dataIndex: 'supplier',
    },
    {
      title: '日期',
      dataIndex: 'date',
    },
    {
      title: '箱',
      dataIndex: 'box',
    },
    {
      title: '件',
      dataIndex: 'qty',
    },
    {
      title: '费用',
      render: (item) => ('£' + item.amount)
    },
    {
      title: '',
      render: (item) => <Chip label="清单" onClick={()=>showPurchase(item)} />
    },
  ]

  const showPurchase = (item) => {
    navigate('/stock/purchase-details/'+item.id)
  }

  return <RSTable data={purchases} columns={cols} />
}