import AutoGraphIcon from '@mui/icons-material/AutoGraphOutlined';
import MenuItems from "./MenuItems";

const menu = {
  "text": "数据报表",
  "icon": AutoGraphIcon,
  "sub": [
    {
      "text": "销售统计",
      "path": "/analytics/sales",
    },  
    {
      "text": "订单地图",
      "path": "/analytics/orders-map"
    }
  ]
}

export default function MenuItemAnalytics() {

  return <MenuItems menu={menu} />
}