import { useState } from 'react';
import { Chip } from "@mui/material"
import { expiryChecked } from "api/stock"
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function ExpiryCheckChip({itemID, callback}) {

  const [loading, setLoading] = useState(false)

  const handleClick = async () => {
    setLoading(true)
    await expiryChecked(itemID)
    if(callback) callback()
  }

  return <>
  {
    loading?
    <Chip label={<FontAwesomeIcon icon={faEllipsis} fade />} />:
    <Chip label="已检查" onClick={handleClick} />
  }
  </>
}