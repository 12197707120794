import { Stack } from "@mui/material"
import OrderListCard from "pages/new-orders/components/OrderListCard"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { filterNewOrderList, newOrdersStates } from "redux/newOrdersSlice"
import AddressChip from "pages/new-orders/components/Chips/AddressChip"
import SortChip from "pages/new-orders/components/Chips/SortChip"
import MapChip from "pages/new-orders/components/Chips/MapChip"
import columns from "pages/new-orders/components/DPD/DPDAddressColumns"
import TrackingChip from "pages/new-orders/components/Chips/TrackingChip"

/***************************************************************************************/
// 订单列表
export default function DPDOrderListCard() {

  const logisticsName = "DPD"
  const logisticsID = 2 

  const [orders, setOrders] = useState([])
  const [sort, setSort] = useState({label: '时间', index: 'id'})

  const { newOrders } = useSelector(newOrdersStates)

  useEffect(() => {
    const tempOrders = filterNewOrderList(logisticsID, sort.index)
    setOrders(tempOrders)
  }, [sort, newOrders])
  
  const Actions = () => 
  <Stack direction='row' spacing={1}>
    <SortChip sort={sort} callback={setSort} />
    <AddressChip orders={orders} columns={columns} logisticsName={logisticsName} />
    <MapChip orders={orders} logisticsName={logisticsName} />
    <TrackingChip logisticsName={logisticsName} />
  </Stack>
  
  return (
    <>
    { orders && orders.length > 0 &&
    <OrderListCard 
      title={`${logisticsName} (${orders.length})`}
      actions={<Actions />}
      orders={orders}
    />
    }
    </>
  )
}