/* 应付货款提醒 
 * 是否到货，是否上架，是否已付
 */

import { useEffect, useState } from "react"
import { Divider, IconButton, Stack, Typography } from "@mui/material"
import AddIcon from '@mui/icons-material/Add'
import RSDialog from "rsui/RSDialog"
import RSForm from "rsui/RSForm"
import RSCard from "rsui/RSCard"
import DashboardPanel from "../DashboardPanel"
import { ListAltOutlined } from "@mui/icons-material"
import { getBills, updateBill } from "./api"
import RSCheckChip from "rsui/RSCheckChip"

function Bills() {

  const [bills, setBills] = useState([])
  const [total, setTotal] = useState(0)
  const [ open, setOpen ] = useState( false )

  const fetchData = async () => {
    const data = await getBills()
    if(data) {
      setBills(data)
      let n = 0
      for(let i=0; i<data.length; i++) {
        n += Number(data[i].amount)
      }
      setTotal('£'+n.toFixed(2))
    }
  }

  const openDialog = () => {
    setOpen(true)
  }

  const closeDialog = () => {
    setOpen(false)
  }

  useEffect(()=>{
    fetchData()
  }, [])

  return (
    <>
    <DashboardPanel 
      icon={ <ListAltOutlined /> }
      label="未付货款"
      fetchData={fetchData}
      num={total}
      onClick={openDialog}
    />

    <RSDialog
      title="进货备忘"
      open={open}
      onClose={closeDialog}
      noTitle
      noContentPadding
      fullWidth
      maxWidth="xs"
    >
      <RSCard title={"应付款"} action={<AddBillButton callback={fetchData} />}>
        <Stack spacing={2}>
        {bills.map(bill => <Bill key={bill.id} bill={bill} callback={fetchData} />)}
        </Stack>
      </RSCard>
    </RSDialog>
    </>
  )
}

const form = [
  {
    type: 'date',
    name: 'date',
    label: '账期'
  },
  {
    type: 'input',
    name: 'company',
    label: '货源'
  },
  {
    type: 'input',
    name: 'amount',
    label: '金额'
  }
]

function Bill({bill, callback}) {
  const [open, setOpen]= useState(false)

  const openDialog = () => {
    setOpen(true)
  }

  const closeDialog = () => {
    setOpen(false)
  }

  const arrived = async () => {
    await updateBill({id:bill.id, arrived:(bill.arrived==='1')?0:1})
    callback()
  }

  const replenished = async () => {
    await updateBill({id:bill.id, replenished:(bill.replenished==='1')?0:1})
    callback()
  }

  const paid = async () => {
    await updateBill({id:bill.id, paid:(bill.paid==='1')?0:1})
    callback()
  }

  const handleSave = async (data) => {
    await updateBill(data)
    callback()
    closeDialog()
  }

  return (
    <Stack spacing={1}>

      <Stack direction='row' spacing={2} onClick={openDialog}>
        <Typography variant="body2">{bill.company}</Typography>
        <Typography variant="body2">£{bill.amount}</Typography>
        <Typography variant="body2">{bill.date}</Typography>
      </Stack>

      <Stack direction='row'>
        <RSCheckChip label="到货" checked={bill.arrived==='1'} onClick={arrived} />
        <RSCheckChip label="上架" checked={bill.replenished==='1'} onClick={replenished} />
        <RSCheckChip label="支付" checked={bill.paid==='1'} onClick={paid} />
      </Stack>

      <Divider />

      <RSDialog 
        title="应付货款"
        open={open}
        onClose={closeDialog}
      >
        <RSForm 
          data={bill} 
          fields={form} 
          onSave={handleSave}
        />
      </RSDialog>
    </Stack>
  )
}


function AddBillButton({callback}) {

  const [open, setOpen]= useState(false)

  const bill = {
    date: new Date(),
    company: '',
    amount: 0
  }

  const openDialog = () => {
    setOpen(true)
  }

  const closeDialog = () => {
    setOpen(false)
  }


  const handleSave = async (data) => {
    await updateBill(data)
    callback()
    closeDialog()
  }


  return (
    <>
      <IconButton onClick={openDialog} size="small">
        <AddIcon />
      </IconButton>

      <RSDialog 
        title="应付货款"
        open={open}
        onClose={closeDialog}
      >
        <RSForm 
          data={bill} 
          fields={form} 
          onSave={handleSave}
        />
      </RSDialog>
    </>
  )
}

export default Bills