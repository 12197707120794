
import { useState } from 'react'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import { google_map_api_key } from 'utils/configs'
import { getDriverPosition } from 'api/delivery'
import { LocalShippingOutlined } from '@mui/icons-material'
import DashboardPanel from '../DashboardPanel'
import RSDialog from 'rsui/RSDialog'
import { getLocalOrderCount } from './api'

export default function DeliveryMap() {

  const [positionA, setPositionA] = useState()
  const [driverA, setDriverA] = useState('')
  const [open, setOpen] = useState(false)
  const [num, setNum] = useState(null)

  const fetchData = async () => {
    const data = await getLocalOrderCount()
    if(data !== null) setNum(data)
  }

  const openDialog = async () => {
    setOpen(true)
    const data = await getDriverPosition(4)
    if(data && (!positionA || data.lat !== positionA.lat || data.lng !== positionA.lng)) {
      setPositionA({lat: data.lat, lng: data.lng})
      setDriverA(data.name)
    }
  }
  const closeDialog = () => setOpen(false)

  const containerStyle = {
    width: '100%',
    height: '400px'
  };

  // London
  const center = {
    lat: 51.4508609,
    lng: 0.1701028
  };

  return (
    <>
    <DashboardPanel 
      icon={ <LocalShippingOutlined /> }
      label="今日本地配送"
      fetchData={fetchData}
      num={num}
      onClick={openDialog}
    />
    <RSDialog
      title="司机位置"
      open={open}
      onClose={closeDialog}
      fullWidth
    >
      <LoadScript googleMapsApiKey={google_map_api_key}>

        <GoogleMap
          mapContainerStyle={containerStyle}
          center={positionA?positionA:center}
          zoom={11}
        >
          {positionA &&
          <Marker 
            title={driverA}
            position={positionA} 
            icon="images/driver-a.png"
          />
          }
        </GoogleMap>
      </LoadScript>
    </RSDialog>
    </>
  )
}