import { Stack } from '@mui/material'
import OrderPickerContent from './OrderPickerContent';
import OrderPickerHeader from './OrderPickerHeader';
import OrderPickerFooter from './OrderPickerFooter';

export default function OrderPicker({ order }) {


  return (
    <Stack>
      <OrderPickerHeader order={order} />
      <OrderPickerContent order={order} />
      <OrderPickerFooter company={order.company} />
    </Stack>
  )
}