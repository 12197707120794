import { Chip } from "@mui/material";
import { useEffect, useState } from "react";
import RSSelector from "rsui/RSSelector";
import { refund_reason } from "utils/configs";

/**
 * 退款原因 Selector
 * @param { function } onClick 选中回调 
 * @returns 
 */
export function RefundReasonSelector({ onClick }) {

  return (
    <RSSelector 
      name="退款原因"
      selectedKey={0}
      options={refund_reason}
      onSelect={onClick}
    />
  )
}

/**
 * 退款原因 Chip
 * @param {int | string} defaultKey 默认选中的值 
 * @returns 
 */
export default function RefundReason({  defaultKey }) {

  const [label, setLabel] = useState('')

  useEffect(() => {
    if(defaultKey) {
      refund_reason.map(reason => {
        if(reason.key === Number(defaultKey)){
          setLabel(reason.value)
        }
      })
    }
  }, [defaultKey])

  return <Chip label={label} variant="outlined" color="error" />
}