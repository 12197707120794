import { Box, Typography, Stack } from '@mui/material'
import { date } from 'utils/datetime';
import { address, name, postcode } from 'utils/str';

// 收据 头部信息
export default function OrderReceiptHeader({ order }) {
  
  return (
  <Stack sx={{paddingX: '20px'}}>
    <Stack direction='row'>
      <Stack spacing={1}>
        <Typography variant='h5' >Red Squirrel Shop Receipt</Typography>
        <Typography>
          To: {name(order.name, 'name')}<br/>
          {address(order.address_1, 'address')}<br/>
          {order.address_2===""?"":address(order.address_2, 'address')+"\n"}
          {order.address_3===""?"":address(order.address_3, 'address')+"\n"}
          {address(order.county, 'address')} {address(order.city, 'address')}<br/>
          {postcode(order.postcode, 'postcode')}
        </Typography>
      </Stack>
      
      <Box flexGrow={1}></Box>

      <Stack spacing={2} sx={{paddingTop: '40px'}}>
        <Typography>
          <b>Order: {order.order_id}</b><br/>
          Date: {date(order.datetime)}<br/>
          Weight: {order.weight} kg<br/>
          Box: {order.box}<br/>
        </Typography>
      </Stack>

    </Stack>

  </Stack>
  )
}