import RSSelector from "rsui/RSSelector";

export default function SortChip({ filter, setFilter }) {

  const options = [
    {key: 'id', value: 'SKU'},
    {key: 'stock_qty', value: '库存'},
  ]

  const handleSelect = (option) => {
    setFilter({
      ...filter,
      sort: option
    })
  }

  return <RSSelector 
            name="排序"
            selectedKey={filter.sort.key} 
            options={options}
            onSelect={handleSelect} 
         />
}