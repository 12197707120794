import { createSlice } from '@reduxjs/toolkit'

// 物流数据

export const slice = createSlice({
  name: 'logsitics',
  initialState: {
    drivers: null
  },
  reducers: {
    setDrivers: (state, action) => {
      state.drivers = action.payload
    }
  },
})

export const { 
  setDrivers
} = slice.actions

export const logisticsStates = state => state.logistics

export default slice.reducer