/* 优惠码 接口 */

import call from 'utils/call' 

/***************************************************************/
export async function getAllCoupons() {
  console.log("获得优惠码列表")
  return await call('coupon/getAllCoupons')
}

/***************************************************************/
export async function saveCoupon(data) {
  console.log("保存优惠码")
  await call('coupon/saveCoupon', data)
}

/***************************************************************/
export async function deleteCoupon(coupon_id) {
  console.log("删除优惠码")
  await call('coupon/deleteCoupon/'+coupon_id)
}

/***************************************************************/
export async function couponAnalytics(code) {
  console.log("分析优惠码")
  return await call('coupon/analytics/'+code)
}