import { copyOrder } from "api/order"
import { Chip } from '@mui/material'
import { startLoading, stopLoading } from "utils/layout"
import { loadNewOrders } from "redux/newOrdersSlice"

  // 复制订单 用于重发订单
export default function CopyChip({orderID}) {

  const handleClick = async () => {
    if(window.confirm('确定复制此订单?')) {
      startLoading()
      await copyOrder(orderID)
      await loadNewOrders()
      stopLoading()
    }
  }

  return <Chip label="复制" onClick={handleClick}  />
}