import { MenuItem } from "./MenuItems"
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';

// 新订单
export default function MenuItemDelivery() {
 
  const menu = {
    text: '伦敦配送',
    path: '/london-delivery',
    permission: 'driver',
    icon: LocalShippingOutlinedIcon
  }

  return (
    <MenuItem menu={menu} />
  )
}