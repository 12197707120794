import { Typography } from '@mui/material'
import RSCard from 'rsui/RSCard'

// 优惠码 备忘
export default function CouponNote() {
  
  return (
  <RSCard title="代金券说明" >
    <Typography>一个Code可以给所有人人使用，每人限用一次</Typography>
  </RSCard>
  )
}