import { Button, Typography, Stack, TextField, Grid } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { closeVoucherEditor, setVoucher, setVouchers, setVouchersPage, setVouchersPages, voucherStates } from '../slice'
import RSDialog from 'rsui/RSDialog'
import { startLoading, stopLoading } from 'utils/layout'
import { getVouchers, revokeVoucher, sendVoucher } from '../api'


// *****************************************************************************
// 发券窗口

export default function VoucherEditor() {

  const { voucher, voucherDialog } = useSelector(voucherStates)
  const dispatch = useDispatch()

  const handleChange = (e) => {
    const newVoucher = {
      ...voucher,
      [`${e.target.name}`]: e.target.value
    }
    dispatch(setVoucher(newVoucher))
  }

   // 发送
   const handleSubmit = async (lang) => {
    if(voucher.email !== "") {
      startLoading()
      await sendVoucher({voucher: voucher, lang: lang})
      await reloadList()
      closeVoucherEditor()
      stopLoading()
    } 
  }

  // 撤销
  const handleRevoke = async () => {
    if(window.confirm("确定撤销?")) {
      startLoading()
      await revokeVoucher(voucher.id)
      await reloadList()
      closeVoucherEditor()
      stopLoading()
    }
  }

  const reloadList = async () => {
    const data = await getVouchers(1)
    if(data) {
      dispatch(setVouchersPage(1))
      dispatch(setVouchersPages(data.pages))
      dispatch(setVouchers(data.data))
    }
  }

  const Actions = () => 
    <Stack direction="row">
      {voucher.order_id === "0" && <Button onClick={handleRevoke}>撤销</Button>}
      <Button onClick={closeVoucherEditor} >取消</Button>
      <Button onClick={()=>handleSubmit('en')} variant='contained'>发送英文</Button>
      <Button onClick={()=>handleSubmit('cn')} variant='contained'>发送中文</Button>
    </Stack>

  return (
    <RSDialog
      title="发送代金券"
      open={voucherDialog}
      onClose={closeVoucherEditor}
      actions={<Actions/>}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant='h5'>{voucher.description}</Typography>
        </Grid>

        <Grid item xs={6}>
          <TextField 
            fullWidth
            name="name" 
            label="姓名" 
            value={voucher.name}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField 
            fullWidth
            name="email" 
            label="Email" 
            value={voucher.email}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </RSDialog>
  )
}