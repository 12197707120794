import { useState } from 'react'
import { Stack, Typography } from '@mui/material'
import RSDialog from 'rsui/RSDialog'

// *******************************************************************************************************
// Card 订单通知记录

export default function NoticeList({ notices }) {
 
  const [notice, setNotice] = useState(null)
  const [open, setOpen] = useState(false)

  const openDialog = (item) => {
    if(item.email && item.email !== '') {
      setNotice(JSON.parse(item.email))
      setOpen(true)
    }
  }
  
  const closeDialog = () => {
    setNotice(null)
    setOpen(false)
  }


  return (
    <>
      <Stack>
      {
        notices.map((item)=>
        <Stack key={item.id} spacing={0} onClick={()=>openDialog(item)} sx={{cursor:'pointer'}}>
          <Typography>{item.subject}</Typography>
          <Typography variant='caption'>{item.timestamp}</Typography>
        </Stack>
        )
      }
      </Stack>

      <RSDialog 
        open={open}
        onClose={closeDialog}
        title={notice?notice.subject:''}
        maxWidth="sm"
        fullWidth
      >
        {notice &&
          <div dangerouslySetInnerHTML={{ __html: notice.body }} />
        }
      </RSDialog>
    </>
  )
}