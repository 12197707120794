import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { logisticsStates } from "redux/logisticsSlice";
import RSMenu from "rsui/RSMenu";


export default function RSDriverFilterChip({ driver, callback }) {
  const { drivers } = useSelector(logisticsStates)
  const [items, setItems] = useState([])

  const defaultItem = {id: '0', label: '全部'}

  useEffect(() => {
    if(drivers) {
      setItems([
        defaultItem,
        ...drivers
      ])
    }
  }, [drivers])

  const handleCallback = (item) => {
    if(item.id === '0') callback(defaultItem)
    else callback(item)
  }

  return <RSMenu 
          name="司机"
          label={driver.label} 
          items={items} 
          callback={handleCallback} 
         />
}