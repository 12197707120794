import { Grid, Chip, Stack, Table, TableRow, TableCell, TableBody, IconButton, Typography, Box } from '@mui/material'
import { useState } from 'react'
import RSCard from 'rsui/RSCard'
import {  useSelector } from 'react-redux'
import { editOrder, orderStates } from 'redux/orderSlice'
import { address, name, postcode } from 'utils/str'
import { getLatLng } from 'api/order'
import { ReplayRounded } from '@mui/icons-material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsis } from '@fortawesome/free-solid-svg-icons'
import RSEditableTableRow from 'rsui/RSEditableTableRow'

// *******************************************************************************************************
// 追踪按钮

function TrackingChip({ order }) {
  
  const handleClick = () => {
    if(order.logistics_id  === '2') window.open("https://mydeliveries.dpdlocal.co.uk/parcels/"+order.tracking_number)
    else if(order.logistics_id === '5') window.open("https://www.ups.com/mobile/track?trackingNumber="+order.tracking_number)
  }

  return <Chip label="追踪" onClick={handleClick} />
}

// *******************************************************************************************************
// 地图按钮

function MapChip({ order }) {
  
  const handleMap = () => {
    window.open("https://www.google.com/maps/search/"+order.postcode)
  }

  return <Chip label="地图" onClick={handleMap} />
}

// *******************************************************************************************************
// Card 订单物流

export default function OrderShippingCard() {
  
  const { order } = useSelector(orderStates)

  const Actions = () => 
  <Stack direction='row' spacing={1}> 
    {order.postcode !== "" && <MapChip order={order} />}
    {order.tracking_number !== "" && <TrackingChip order={order} />}
  </Stack>

const entityProps = [
  {
    title: "Name",
    entity: "name",
    format: name
  },
  {
    title: "Surname",
    entity: "surname",
    format: name
  },
  {
    title: "Email",
    entity: "email",
    format: name
  },
  {
    title: "Mobile",
    entity: "phone"
  },
  {
    title: "Address L1",
    entity: "address_1",
    format: address,
    max: 34
  },
  {
    title: "Address L2",
    entity: "address_2",
    format: address,
    max: 34
  },
  {
    title: "Address L3",
    entity: "address_3",
    format: address,
    max: 34
  },
  {
    title: "City",
    entity: "city",
    format: address,
    max: 34
  },
  {
    title: "County",
    entity: "county",
    format: address,
    max: 34
  },
  {
    title: "Country",
    entity: "country",
    format: address,
  },
  {
    title: "Postcode",
    entity: "postcode",
    format: postcode
  },
  {
    title: "Instructions",
    entity: "instructions",
  },
  {
    title: "Note",
    entity: "note",
  },
  {
    title: "Tracking ID",
    entity: "tracking_number",
  },
]

const commonProps = {
  item: order,
  callback: editOrder
}

  return (
    
    <RSCard title="物流信息" action={<Actions />} >
      <Grid container>
        <Grid item xs={12}>
        <Table size="small">
          <TableBody>

            <TableRow>
              <TableCell width="10"><b>Order ID:</b></TableCell>
              <TableCell>{order.id}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell><b>Datetime</b></TableCell>
              <TableCell>{order.datetime}</TableCell>
            </TableRow>

            <RSEditableTableRow 
              title="Box" 
              entity="box" 
              item={order}
              callback={editOrder}
            />

            <TableRow>
              <TableCell><b>Weight:</b> </TableCell><TableCell>{order.weight}KG</TableCell>
            </TableRow>

            { entityProps.map( (props, index) => 
              <RSEditableTableRow
                key={index}
                {...props}
                {...commonProps}
              />
            )}

            <LatLngTableRow order={order}/>

          </TableBody>
        </Table>
        </Grid>
      </Grid>
    </RSCard>
  )
}

// *******************************************************************************************************
// 经纬度

function LatLngTableRow({ order }) {

  const [loading, setLoading] = useState(false)

  const reload = async () => {
    setLoading(true)
    const data = await getLatLng(order.id)
    editOrder(data)
    setLoading(false)
  }

  return (
    <TableRow>
      <TableCell><b>LatLng</b> </TableCell>
      <TableCell>
        <Stack direction="row" alignItems="center">

          { loading ? 
          <FontAwesomeIcon icon={faEllipsis} fade />:
          <Typography>
            {order.lat}, {order.lng}
          </Typography>
          }

          <Box flexGrow={1} />
       
          <IconButton size='small' onClick={reload}>
            <ReplayRounded />
          </IconButton>
        </Stack>
      </TableCell>
    </TableRow>
  )
}