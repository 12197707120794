import { useState, useEffect } from "react"
import { Chip, Stack } from "@mui/material"
import RSCard from "rsui/RSCard"
import RSTable from "rsui/RSTable"
import RSTextEditor from "rsui/RSTextEditor"
import ProductSelector from "pages/promotion/buy-more/components/ProductSelector"
import CheckBox from "components/common/CheckBox"
import { showFail } from "utils/RSUtils"
import { addBuyMore, getAllBuyMore, updateBuyMore } from "../api"
import { startLoading, stopLoading } from "utils/layout"
import RSDatePickerChip from "rsui/RSDatePickerChip"

/* 多买多送 */
export default function BuyMoreGetMoreCard()
{
  const [promotions, setPromotions] = useState([])

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id'
    },
    {
      title: '活动',
      render: (promotion) =>  <RSTextEditor 
                                item={promotion} 
                                entity="name_cn"
                                callback={updateEvent}
                                inputWidth="200px"
                              />
    },
    {
      title: 'Event',
      render: (promotion) =>  <RSTextEditor 
                                item={promotion} 
                                entity="name_en"
                                callback={updateEvent}
                                inputWidth="200px"
                              />
    },
    {
      title: '购买产品(或)',
      width: 250,
      render: (promotion) =>  <ProductSelector 
                                products={promotion.buy}
                                callback={v => updateEvent({id:promotion.id, 'buy': v})}
                              />
    },
    {
      title: '总购买数量',
      render: (promotion) =>  <RSTextEditor 
                                item={promotion} 
                                entity="buy_qty"
                                callback={updateEvent}
                                inputWidth="50px"
                              />
    },
    {
      title: '赠送产品(和)',
      width: 250,
      render: (promotion) =>  <ProductSelector 
                                products={promotion.get}
                                callback={v => updateEvent({id:promotion.id, 'get': v})}
                              />
    },
    {
      title: '赠送数量',
      render: (promotion) =>  <RSTextEditor 
                                item={promotion} 
                                entity="get_qty"
                                callback={updateEvent}
                                inputWidth="50px"
                              />
    },
    {
      title: '截止日期', // 由 external dailycheck 每日检查 自动下线
      render: (promotion) =>  <RSDatePickerChip
                                defaultDate={promotion.expiry_date}
                                callback={date => updateEvent({id:promotion.id, expiry_date:date})}
                              />
    },
    {
      title: '状态',
      render: (promotion) => <CheckBox label="上线" checked={promotion.active === '1'} onChange={()=>toggleActive(promotion)} />
    }
  ] 

  const Actions = () => <Stack direction='row'>
    <Chip label="新建" onClick={addData} />
  </Stack>

  // 获取数据
  const fetchData = async () => {
    startLoading()
    const data = await getAllBuyMore()
    if(data) setPromotions(data)
    stopLoading()
  }

  // 增加数据
  const addData = async () => {
    startLoading()
    await addBuyMore()
    await fetchData()
  }

  // 更新本地数据
  const updateData = (id, property, value) => {
    const updatedData = promotions.map(item => {
      if (item.id === id) {
        return { ...item, [property]: value };
      }
      return item;
    });
  
    setPromotions(updatedData)
  }

  const toggleActive = (promotion) => {
    // 某一个条件为空 不能形成有效活动
    if(!promotion.buy || !promotion.get)
    {
      showFail("活动设置有误!")
    } else {
      updateData(promotion.id, 'active', promotion.active === '0'?'1':'0')
      updateBuyMore({id: promotion.id, active: promotion.active === '0'?1:0})
    }
  }

  const updateEvent = (data) => {
    updateData(data)
    updateBuyMore(data)
  }

  useEffect(()=>{
    fetchData()
  }, [])

  return (
  <RSCard title="促销活动-多买多送" action={<Actions />}>
    <RSTable data={promotions} columns={columns}/>
  </RSCard>
  )
}