import { setLoading, setOpenOrderSearch } from "redux/AppSlice";
import { showMessage } from "components/snack/snackSlice";
import store from "redux/store";

/**
 * 检查权限
 * @param {string} permission 权限名称
 * @returns boolean
 */
export function hasPermission(permission) {
  const permissions = store.getState().app.permissions
  if(permissions && permissions.includes(permission)){
    return true
  } else {
    return false
  }
}

// *****************************************************************
// 全局 Loader，
// 如果有 Dialog open 的情况下 最好不要使用

export function startLoading() {
  store.dispatch(setLoading(true))
}

export function stopLoading() {
  store.dispatch(setLoading(false))
}


// **************************************************************************
// 全局 Message
// 反馈提醒

export function showSuccess(msg) {
  store.dispatch(showMessage('success', msg))
}

export function showFail(msg) {
  store.dispatch(showMessage('error', msg))
}


// **************************************************************************
// 全局 Drawer
// 订单搜索 Order Search

export function openOrderSearchDrawer() {
  store.dispatch(setOpenOrderSearch(true))
}

export function closeOrderSearchDrawer() {
  store.dispatch(setOpenOrderSearch(false))
}