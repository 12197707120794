import { Stack } from "@mui/material"
import OrderListCard from "pages/new-orders/components/OrderListCard"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { filterNewOrderList, newOrdersStates } from "redux/newOrdersSlice"
import RSDriverFilterChip from "pages/new-orders/components/RS/RSDriverFilterChip"
import RSAddressChip from "pages/new-orders/components/RS/RSAddressChip"
import columns from "pages/new-orders/components/RS/RSAddressColumns"
import SortChip from "../Chips/SortChip"
import RSLabelChip from "pages/new-orders/components/RS/RSLabelChip"
import AddNewChip from "pages/new-orders/components/RS/AddNewChip"
import MapChip from "../Chips/MapChip"
import BatchNoticeChip from "./actions/BatchNoticeChip"

/***************************************************************************************/
// 红松鼠物流 订单列表
export default function RSOrderListCard() {

  const logisticsName = "红松鼠物流"
  const logisticsID = 4

  const [orders, setOrders] = useState([])
  const [sort, setSort] = useState({label: '时间', index: 'id'})
  const [driver, setDriver] = useState({id: '0', label: '全部'})
  const { newOrders } = useSelector(newOrdersStates)

  useEffect(() => {
    const tempOrders = filterNewOrderList(logisticsID, sort.index)
    // 按司机选项再筛选一遍
    let filteredOrders = []
    if(driver.id !== '0') {
      tempOrders.forEach(order => {
        if(driver.id === order.logistics_route) {
          filteredOrders.push(order) 
        }
      });
    } else filteredOrders = tempOrders
    
    setOrders(filteredOrders)
  }, [sort, driver, newOrders])
  
  const Actions = () => 
  <Stack direction='row' spacing={1}>
    <SortChip sort={sort} callback={setSort} />
    <RSDriverFilterChip driver={driver} callback={setDriver} />
    {driver && driver.id !== '0' && <RSAddressChip orders={orders} driver={driver} />}
    {driver && driver.id !== '0' && <RSLabelChip driverID={driver.id} />}
    {driver && driver.id !== '0' && <MapChip orders={orders} columns={columns} logisticsName={logisticsName} driver={driver} />}
    <BatchNoticeChip />
    <AddNewChip />
  </Stack>
  
  return (
    <>
    { orders && orders.length > 0 &&
    <OrderListCard 
      title={`${logisticsName} (${orders.length}) ${driver.label}`}
      actions={<Actions />}
      orders={orders}
    />
    }
    </>
  )
}

