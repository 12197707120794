import { useSelector } from 'react-redux'
import { Grid, LinearProgress, Stack } from '@mui/material'
import RSDialog from 'rsui/RSDialog'
import ProductEditImgs from './components/ProductEditImgs'
import ProductEditTags from './components/ProductEditTags'
import ProductEditInfo from './components/ProductEditInfo'
import { closeProductEditDialog, productEditStates } from 'redux/productEditSlice'

// *******************************************************************************************************
// 单个订单管理界面

export default function ProductEditDialog() {

  const { product, productEditDialog } = useSelector(productEditStates)
  
  return (
    <RSDialog 
      title="产品编辑" 
      open={productEditDialog}
      onClose={closeProductEditDialog}
      maxWidth="xl"
      fullWidth
    >
      {!product && <LinearProgress />}
      {product && 
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={6}>
          <ProductEditInfo />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Stack spacing={2}>
            <ProductEditTags product={product} />
            <ProductEditImgs product={product} />
          </Stack>
        </Grid>
      </Grid>
      }
    </RSDialog>
  )
}