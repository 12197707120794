import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import TemplateEdit from "./components/TemplateEdit";
import TemplateList from "./components/TemplateList";
import { getEmailTemplates } from "api/email";


export default function EmailTemplate() {
  const [templateList, setTemplateList] = useState([])
  const [selectedTempalte, setSelectedTemplate] = useState(null)

  const fetchData = async () => {
    const data = await getEmailTemplates()
    if(data) setTemplateList(data)
  }

  const onSave = async () => {
    setSelectedTemplate(null)
    await fetchData()
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <Grid container>
      <Grid item xs={3}>
        <TemplateList templates={templateList} setTemplate={setSelectedTemplate} />
      </Grid>

      <Grid item xs={9}>
        { selectedTempalte && <TemplateEdit template={selectedTempalte} callback={onSave} /> }
      </Grid>
    </Grid>
  )
}