import { LoyaltyOutlined } from "@mui/icons-material";
import MenuItems from "./MenuItems";

const menu = {
  "text": "促销活动",
  "icon": LoyaltyOutlined,
  "permission": "admin",
  "sub": [
    {
      "text": "多买多送",
      "path": "/promotion/buy-more"
    },
    {
      "text": "优惠码",
      "path": "/promotion/coupon"
    },
    {
      "text": "代金券",
      "path": "/promotion/voucher"
    },
    {
      "text": "充值码",
      "path": "/promotion/wallet"
    }
  ]
}

export default function MenuItemPromotion() {

  return <MenuItems menu={menu} />
}