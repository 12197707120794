import { useEffect, useState } from 'react'
import { Chip, Dialog } from '@mui/material'
import dayjs from 'dayjs'
import { StaticDatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

/**
 * 日期选择器
 * @param { string } defaultDate 默认日期 'YYYY-MM-DD'
 * @param { function } callback 返回 'YYYY-MM-DD' 选择的日期
 * @returns 
 */
export default function RSDatePickerChip({defaultDate, callback})
{
  const [date, setDate] = useState(null)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if(defaultDate) setDate(defaultDate)
    else setDate(dayjs().format('YYYY-MM-DD'))
  }, [])

  const changeDate = (v) => {
    const newDate = dayjs(v).format('YYYY-MM-DD')
    setDate(newDate)
    setOpen(false)
    if(callback) callback(newDate)
  }

  const openDialog = () =>{
    setOpen(true)
  }

  return (<>
    <Chip label={date} onClick={openDialog} />
    <Dialog open={open}>
      {date &&
      <LocalizationProvider dateAdapter={AdapterDayjs}> 
        <StaticDatePicker 
          value={date}
          onChange={v=>changeDate(v)}  
          ToolbarComponent={() => null} 
          renderInput={(params) => <></>}
        />
      </LocalizationProvider>
      }
    </Dialog>
  </>)
}