import { Chip } from "@mui/material"
import dayjs from "dayjs";

/**
 * Chip 保质期 小于30天红色，小于60天黄色
 * @param { string } product.expiry_date 保质期 'YYYY-MM-DD'
 * @param { string } product.hide 是否下架 '0'上架中，'1'已下架 
 * @returns 
 */
export default function ExpiryDateChip({ product }) {

  const date1 = dayjs(product.expiry_date);
  const today = dayjs(); 
  const daysDifference = date1.diff(today, 'day');
  let label = '-'
  let color = "default";

  if(product.expiry_date !== '0000-00-00') {
    label = product.expiry_date
    if(product.hide === '0') {
      if(daysDifference <= 0) color="error"
    }
  }
 
  return <>{label === '-' ?label:<Chip label={label} color={color} />}</>
}
