/* Header Item 订单搜索 */
import { IconButton } from '@mui/material'
import { SearchOutlined } from "@mui/icons-material"
import { openOrderSearchDrawer } from 'utils/layout'

export default function HeaderOrderSearch() {

  const handleClick = () => {
    openOrderSearchDrawer()
  }

  return (
    <IconButton onClick={handleClick}>
      <SearchOutlined />
    </IconButton>
  )
}