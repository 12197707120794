// Google Map Key
export const google_map_api_key = "AIzaSyCkdCjnrxTiCPfxSNmW7o8X6Lrd6nMDaN8"


// 配送方式
export const delivery_method = [
  {k:'2', label:"DPD"},
  {k:'5', label:"UPS"},
  {k:'6', label:"Fedex"},
  {k:'4', label:"红松鼠"},
  {k:'1', label:"自取"}
]

// 自送线路
export const delivery_route = [
  {k:'0', label:"线路A"},
  {k:'1', label:"线路B"},
  {k:'2', label:"线路C"},
  {k:'3', label:"线路D"},
  {k:'4', label:"线路E"}
]

// 退款原因
export const refund_reason = [
  {key: 0, value: "库存问题"},
  {key: 1, value: "物流问题"},
  {key: 2, value: "员工问题"},
  {key: 3, value: "客户问题"},
  {key: 4, value: "其它问题"},
  {key: 5, value: "合并订单"}
]

// 参考 手动同步 数据库
export const stockRecordType = [
  {name: '-', value: 0},
  {name: '库损-过期', value: 1},
  {name: '库损-损坏', value: 2},
  {name: '库损-丢失', value: 3},
  {name: '库损-市场', value: 4},
  {name: '库损-内部', value: 5},
  {name: '库损-招待', value: 6},
  {name: '入库-进货', value: 7},
  {name: '调整-零整', value: 8}
]
