import { Grid,  Stack } from '@mui/material'
import { useSelector } from "react-redux";
import { AppStore } from 'redux/AppSlice';

import Bills from "./components/Bills/component";
import StockAmount from "./components/StockAmount/component"
import StockRecordsButton from "./components/StockRecordsButton";

import AbnormalOrders from "./components/AbnormalOrders/component";
import DeliveryMap from "./components/DeliveryMap/component";
import SoldoutPercentage from "./components/SoldOutPercentage/component";
import ProductSKUCount from "./components/ProductSKUCount/component";
import AbnormalProducts from './components/AbnormalProducts/component';
import ExpiredProductCount from './components/ExpiredProductCount/component';

export default function Dashboard() {

  const { permissions } = useSelector(AppStore)

  return (
    <Grid container spacing={2} sx={{displayPrint: 'none'}}>
      
      <Grid item xs={3} md={2}>
        <Stack spacing={1}>
          <DeliveryMap />
          <StockRecordsButton />
          <ExpiredProductCount />
          <SoldoutPercentage />
          <ProductSKUCount />
        </Stack>
      </Grid>
    
      { permissions.includes('admin') &&
      <Grid item xs={3} md={2}>
        <Stack spacing={1}>
           
          <Bills /> 
          <StockAmount />
          
          <AbnormalOrders />
          <AbnormalProducts />

        </Stack>
      </Grid>
      }

      <Grid item xs={2}>
        
      </Grid>

    </Grid>

  )
}