

import { Chip } from '@mui/material'
import { openProductEditDialog } from 'redux/productEditSlice';

/********************************************************************/
/* 产品编辑按钮 */

export default function ProductEditChip({ product, callback }) {

  return <Chip label="编辑" onClick={()=>openProductEditDialog(product.id, callback)} />

}