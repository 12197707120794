import { Stack } from '@mui/material'
import Postcode from '../components/Postcode'

export default function SpecialArea() {

  return (
    <Stack spacing={2}>
      <Postcode title="偏远地区" area={2} placeholder="Postcode片段" />
    </Stack>
  )
}