import { useState, useEffect } from "react"
import { Grid } from "@mui/material"
import SalesTable from "./SalesTable"
import SalesChart from "./SalesChart"
import { salesChart, salesTable } from "api/analytics"

// 年销售
export default function AnnualSales () {

  const [data, setData] = useState([])
  const [chartData, setChartData] = useState(null)

  const loadData = async () => {
    const res = await salesTable({type: 1})
    if(res) setData(res)
    const res2 = await salesChart({type: 1})
    if(res2) setChartData(res2)
  }

  useEffect(() => {
    loadData()
  }, [])

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}> 
        <SalesTable title="年销售(K)" data={data} />
      </Grid>
      <Grid item xs={6}>
        {chartData && <SalesChart title="年销售(K)" data={chartData} />}
      </Grid>
    </Grid>
  )
}
