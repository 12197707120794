import axios from "axios"
import { showFail } from "components/snack/snack";
import { getAuth } from "firebase/auth"
import fileDownload from "js-file-download";

const productionEndpoint = "https://v2.redsquirrel.shop/";
const developmentEndpoint = "http://localhost:8080/";

function endpoint(){
  return server()+'backend/'
}

function server(){
  if(process.env.NODE_ENV === "development") return developmentEndpoint;
  else return productionEndpoint;
}

/**************************************************************************/
// 获得 Firebase IdToken
const getIdToken = async () => {
  const auth = getAuth()
  const user = auth.currentUser
  if(user) {
    return await user.getIdToken()
  } else {
    return null
  }
}

const request = async (api, data = null, config = null) => { 

  const url = endpoint()+api

  if(data) {
    return await Post(url, data, config)
  } else {
    return await Get(url, config)
  }
}

/**
 * 登录后才能使用
 * @param { string } api 'xxx/xxx'
 * @param { object } data {...}
 * @returns 
 */
const call = async (api, data = null) => { 
  try {
    const idToken = await getIdToken()

    if(idToken) {
      const config = {
        headers: {
          'Rs-Id-Token': idToken
        }
      }
      return request(api, data, config) 
    } else {
      return request(api, data)
    }
  } catch (err) {
    return null
  }
}

// 上传
export const upload = async (api, data = null) => {
  try {
    const idToken = await getIdToken()
    //console.log(idToken)
    if(idToken) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Rs-Id-Token': idToken
        }
      }

      return request(api, data, config)
       
    } 
  } catch (err) {
    return null
  }
}

export const download = async (api, data, filename) => {
  try {
    const idToken = await getIdToken()
    //console.log(idToken)
    if(idToken) {
      const config = {
        headers: {
          'Rs-Id-Token': idToken
        },
        responseType: 'blob'
      }

      const res = await request(api, data, config)
      if(res) fileDownload(res, filename)
    } 
  } catch (err) {
    return null
  }
}


// get method
const Get = async (url, config = null) => {
  console.log('Get: '+url)

  try {
    const res = await axios.get(url, config)
    console.log("Respond data")
    console.log(res.data)
    return res.data
  } catch (err) {
    errHandler(err)
    return null
  }
}

// post method
const Post = async (url, postData, config = null) => {
  console.log('Post: '+url)
  console.log('Post data')
  console.log(postData)

  const headers = {
    'Content-Type': 'application/json'
  }

  // 通用 header 
  if(config) {
    config.headers = {
      ...config.headers,
      ...headers
    }
  } else {
    config = {
      headers: headers
    }
  }

  try { 
    const res = await axios.post(url, postData, config)
    console.log("Respond data")
    console.log(res.data)
    return res.data
  } catch (err) {
    errHandler(err)
    return null
  }
}

// 错误返回处理 非200 一般为系统或网络错误
const errHandler = (error) => {
  if (error.response) {
    if (error.response.data.message) {
      // Handle specific error message
      console.log(`Error ${error.response.status}: ${error.response.data.message}`);
      showFail(`Error ${error.response.status}: ${error.response.data.message}`)
    } else if (error.response.data.messages) {
      // Handle another specific error message
      console.log(`Error ${error.response.status}: ${error.response.data.messages.error}`);
      showFail(`Error ${error.response.status}: ${error.response.data.messages.error}`)
    } else {
      // Handle other cases where error.response exists
      console.log(`Error ${error.response.status}: ${error.response.statusText}`);
      showFail(`Error ${error.response.status}: ${error.response.statusText}`)
    }
  } else if (error.request) {
    // Handle error where no response was received
    console.log(`No response received from the server`);
    showFail(`No response received from the server`)
  } else {
    // Handle other error cases
    console.log(`Error: ${error.message}`);
    showFail(`Error: ${error.message}`)
  }
}


/* 获得 Public MD 文档*/
export const getMD = async (filename) => {
  const url = endpoint() + 'markdown/show'
  return await Post(url, {filename: filename})
}

// 需要登录后才能使用的 call api 方法
export default call