/* 折扣相关 */

import call from 'utils/call' 

/***************************************************************/
export async function setProductDiscount(data) {
  console.log("设置产品折扣")
  await call('discount/setDiscount', data)
}

/***************************************************************/
export async function setProductDiscountNote(data) {
  console.log("设置产品折扣备注")
  await call('discount/setNote', data)
}

/***************************************************************/
export async function setProductDiscountExpiry(data) {
  console.log("设置产品折扣有效期")
  await call('discount/setExpiry', data)
}
