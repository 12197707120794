import { useEffect, useState } from 'react'
import { Req } from 'utils/RSRequest'
import { Chip, Grid, TextField } from '@mui/material'
import RSCard from 'rsui/RSCard'
import { addPostcode, delPostcode, listPostcode } from 'api/logistics'

export default function Postcode(props) {
  const [postcodes, setPostcodes] = useState([])
  const { area, title, placeholder } = props
  var firstLetter = null

  useEffect(()=>{
    loadData()
  }, [])

  const loadData = async () => {
    const data = await listPostcode(area)
    if(data) setPostcodes(data)
  }

  const handleAdd = async (e) => {
    const v = e.target.value
    if(v !== '' && e.keyCode === 13) {
      await addPostcode({area:area, code_1:v})
      loadData()
    }
  }

  const handleDel = async (id) => {
    if(window.confirm('确定删除?')) {
      await delPostcode({id: id})
      loadData()
    }
  }

  return (

    <RSCard title={title} action={<TextField label={placeholder} onKeyDown={handleAdd} size="small" />}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        { postcodes && postcodes.map((postcode) => {
            if(!firstLetter) firstLetter = postcode.code_1.charAt(0)
            var fl = firstLetter
            firstLetter = postcode.code_1.charAt(0)
            return (
            <span key={postcode.id}>
            {firstLetter !== fl && <><br/><br/></>}
            <Chip label={postcode.code_1} onClick={()=>handleDel(postcode.id)} sx={{mr: 1, mb: 1}} />
            </span>
            )
          })
        }
        </Grid>
      </Grid>
    </RSCard>
  )
}