import { useEffect, useState } from 'react'
import RSMenu from 'rsui/RSMenu'
import { useSelector } from 'react-redux'
import { logisticsStates, setDrivers } from 'redux/logisticsSlice'
import { getDrivers } from 'api/logistics'
import store from 'redux/store'

// *******************************************************************************************************
// 按钮 切换司机

export default function DriverChip({ order, callback }) {

  const { drivers } = useSelector(logisticsStates)
  const [driver, setDriver] = useState('')

  const changeDriver = (driver) => {
    setDriver(driver.label)
    callback({
      id: order.id,
      logistics_route: driver.id
    })
  }

  useEffect(() => {
    if(drivers) {
      
      if(order.logistics_route === '0') {
        // 如果没有安排司机 默认一个司机
        console.log("自动安排司机")
        changeDriver(drivers[0])
      } else {
        // 如果有司机了 找到司机名
        // console.log("已有司机")
        drivers.map(d => {
          if(d.id === order.logistics_route) {
            setDriver(d.label)
          }
        })
      }
    } 
  }, [])

  return (
    <>
    { drivers && 
      <RSMenu 
        name="司机"
        label={driver} 
        items={drivers} 
        callback={changeDriver} 
      />
    }
    </>
  )
}