/* 产品的近期销售记录 */

import { useState } from 'react'
import { Chip, Stack, Typography } from '@mui/material'
import RSDialog from 'rsui/RSDialog'
import { strFormat } from 'utils/RSUtils';
import { recentSales } from 'api/product';
import { date } from 'utils/datetime';
import { openOrderDialog } from 'components/order/dialog/OrderDialog';

export default function ProductRecentSalesChip({product}) {
  
  const [orders, setOrders] = useState([])
  const [open, setOpen] = useState(false)

  const openDialog = () => {
    loadOrders()
    setOpen(true)
  }

  const closeDialog = () => {
    setOpen(false)
  }

  const loadOrders = async () => {
    const data = await recentSales(product.id)
    if(data) setOrders(data)
  }


  const handleClick = (order) => {
    openOrderDialog(order.id)
  }

  return (
    <>
    <Chip label="销售" onClick={openDialog} />

    <RSDialog 
      open={open}
      title={product.name_cn+" 近期销售"}
      onClose={closeDialog}
    >
      <Stack spacing={2}>
        {orders && orders.map(order => 
          <Typography key={order.id} onClick={()=>handleClick(order)} sx={{cursor: 'pointer'}}>
            {date(order.datetime)} - {order.order_id} - {strFormat(order.name, 'name')} - {order.sales_qty}
          </Typography>
        )}
      </Stack>
    </RSDialog>
    </>
  )
}