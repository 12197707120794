import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({
  name: 'snack',
  initialState: {
    severity: 'info',
    message: '',
    open: false
  },
  reducers: {
    setSeverity:(state, action) => {
      state.severity = action.payload
    },
    setMessage:(state, action) => {
      state.message = action.payload
    },
    setOpen: (state, action) => {
      state.open = action.payload
    },
  },
})

export const { 
  setSeverity,
  setMessage,
  setOpen,
} = slice.actions

export const snackStates = state => state.snack

export default slice.reducer

export const showMessage = (severity, message) => (dispatch) => {
  dispatch(setSeverity(severity))
  dispatch(setMessage(message))
  dispatch(setOpen(true))
}


