import { Chip, Typography, Stack } from '@mui/material'
import RSCard from 'rsui/RSCard'
import RSTable from 'rsui/RSTable'
import { useDispatch, useSelector } from 'react-redux'
import { closeTemplateEditor, openVoucherEditor, openTemplateEditor, setTemplates, voucherStates } from '../slice'
import RSDialog from 'rsui/RSDialog'
import RSForm from 'rsui/RSForm'
import { startLoading, stopLoading } from 'utils/layout'
import { deleteTemplate, getAllTemplates, saveTemplate } from '../api'
import { useEffect } from 'react'

// *****************************************************************************
// 代金券模板

export default function VoucherTemplate() {

  const { templates } = useSelector(voucherStates)

  const dispatch = useDispatch()

  const fetchData = async () => {
    const data = await getAllTemplates()
    if(data) dispatch(setTemplates(data))
  }

  useEffect(()=>{
    fetchData()
  }, [])

  const AddNewChip = () => <Chip label="新建" onClick={handleAddNew} />

  const handleAddNew = () => {
    const newTemplate = {
      condition: 0,
      deduction: 0,
      description: '',
      weeks: 0
    }
    openTemplateEditor(newTemplate)
  }
    
  return (
  <RSCard
    title="代金券模板" 
    action={<AddNewChip />}
  >
    {templates && <TemplateTable />}
    <TemplateEditor />
  </RSCard>
  )
}

// *****************************************************************************
// 模板列表

function TemplateTable() {

  const { templates } = useSelector(voucherStates)

  const TemplateInfo = ({ template }) => (
    <Stack spacing={0}>
      <Typography>{template.description}</Typography>
      <Typography variant='caption'>{template.weeks}周有效期 满 £{Number.parseFloat(template.condition).toFixed(0)} 减 £{Number.parseFloat(template.deduction).toFixed(0)}</Typography>
    </Stack>
  )


  const EditChip = ({ template }) => <Chip label="编辑" onClick={()=>openTemplateEditor(template)} />

  const SendChip = ({ template }) => <Chip label="发券" onClick={()=>handleSend(template)} />

  const handleSend = template => {
    const voucher ={
      email: '',
      name: '',
      condition: template.condition,
      deduction: template.deduction,
      description: template.description,
      weeks: template.weeks
    }
    openVoucherEditor(voucher)
  }

  const Actions = ({ template }) => (
    <Stack direction="row">
      <EditChip template={template} />
      <SendChip template={template} />
    </Stack>
  )

  const columns = [
    {
      title: '模板',
      render: (template) => <TemplateInfo template={template} />
    },
    {
      title: '',
      render: (template) => <Actions template={template} />
    }
  ]

  return <RSTable data={templates} columns={columns} />
}

// *****************************************************************************
// 模板编辑窗口

function TemplateEditor() {

  const dispatch = useDispatch()
  const { template, templateDialog } = useSelector(voucherStates)

  const form = [
    {
      contents: [
        {
          type: 'input',
          name: 'condition',
          label: '最低消费'
        },
        {
          type: 'input',
          name: 'deduction',
          label: '抵扣金额'
        },
        {
          type: 'input',
          name: 'weeks',
          label: '有效期*周'
        }
      ]
    },
    {
      type: 'input',
      name: 'description',
      label: '备注'
    }
  ]

  const handleSave = async (data) => {
    startLoading()
    await saveTemplate(data)
    await fetchListData()
  }

  const handleDelete = async () => {
    startLoading()
    if(template) await deleteTemplate(template.id)
    await fetchListData()
  }

  const fetchListData = async () => {
    const data = await getAllTemplates()
    if(data) dispatch(setTemplates(data))
    closeTemplateEditor()
    stopLoading()
  }

  return (
    <RSDialog
      title="代金券模板编辑"
      open={templateDialog}
      onClose={closeTemplateEditor}
    >
      <RSForm 
        data={template} 
        fields={form}
        onSave={handleSave}
        onDelete={handleDelete}
      />
    </RSDialog>
  )
}