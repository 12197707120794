import { createSlice } from '@reduxjs/toolkit'

// 登录状态
export const slice = createSlice({
  name: 'CustomerOrders',
  initialState: {
    open: false,
  },
  reducers: {
    openCustomerOrdersDialog: (state) => {
      state.open = true
    },
    closeCustomerOrdersDialog: (state) => {
      state.open = false
    }
  },
})

export const { openCustomerOrdersDialog, closeCustomerOrdersDialog } = slice.actions
export const CustomerOrdersOpen = state => state.CustomerOrders.open
export default slice.reducer

