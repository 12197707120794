import { useState } from "react"
import { Button, Chip, InputAdornment, Stack, TextField } from "@mui/material"
import { generateCode } from "../api"
import RSDialog from "rsui/RSDialog"
import { startLoading, stopLoading } from "utils/layout"

export default function WalletCodeGenerateButton({reload}) {

  const [open, setOpen] = useState(false)
  const [amount, setAmount] = useState(0)

  const handleClick = async () => {
    setOpen(true)
  }

  const handleChange = (e) => {
    setAmount(e.target.value)
  }

  const handleSubmit = async () => {
    if(amount > 0) {
      startLoading()
      await generateCode(amount)
      stopLoading()
      setOpen(false)
      reload()
    }
  }

  return (
    <>
    <Chip label="新建" onClick={handleClick} />

    <RSDialog
      title="新建充值码"
      open={open} 
      onClose={()=>setOpen(false)}
      maxWidth="xs"
    >
      <Stack direction="row" spacing={1}>
        <TextField 
          value={amount} 
          onChange={handleChange} 
          type="number" 
          InputProps={{
            startAdornment: <InputAdornment position="start">£</InputAdornment>,
          }}
        />
        <Button 
          variant="contained"
          onClick={handleSubmit}
        >生成</Button>
      </Stack>
    </RSDialog>
    </>
  )
}