// 字符串处理

// 名字
export function name(str) {
  return firstLetterCaptial(str)
}

// 地址
export function address(str) {
  return firstLetterCaptial(str)
}

// 邮编
export function postcode(str) {
  return str.toUpperCase()
}

// 金额
export function money(str) {
  return Number(str).toFixed(2)
}

// 语言 
export function language(str) {
  switch (str) {
    case 'cn':
      return '简体中文'
    case 'en':
      return '英文'
    case 'hk':
      return '繁体中文(香港)'
    default:
      return '未知'
  }
}

// 每个单词的首字母大写
function firstLetterCaptial(str) {
  if(!str) return '';
  const arr = str.split(" ");
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  return arr.join(" ");
}