import { Chip } from "@mui/material"

// 毛利率
export default function MarginChip({ product }) {
  let color = 'default'
  let rate = '-'
  const cost_price = Number(product.cost_price)
  const discount_price = Number(product.discount_price)
  const price = Number(product.price)

  if(cost_price) {
    if(discount_price) rate = (discount_price - cost_price) / discount_price
    else rate = (price - cost_price) / price
    rate = rate * 100
  }
  if(rate < 40) color = 'warning'
  if(rate < 30) color = 'error'

  return <Chip label={Number(rate).toFixed(2)+'%'} color={color} />
}
