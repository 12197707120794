import { useEffect, useState } from "react";
import AllInboxIcon from '@mui/icons-material/AllInbox';
import { getStockAmount } from "./api";
import DashboardPanel from "../DashboardPanel";

/**
 * 库存总价值
 * @returns 
 */
export default function StockAmount() {

  const [amount, setAmount] = useState(0)
  
  useEffect(() => {
    fetchData()
  }, []) 

  const fetchData = async () => {
    const data = await getStockAmount()
    if(data) setAmount('£'+data)
  }

  return (
    <DashboardPanel 
      icon={ <AllInboxIcon /> }
      label="总库存"
      fetchData={fetchData}
      num={amount}
    /> 
  )
}