import { Box, Chip, Divider, Stack, Typography } from "@mui/material"
import { openOrderDialog } from "components/order/dialog/OrderDialog"
import { name, address, postcode } from "utils/str"
import { OrderStatus } from "./status/StatusChip"

/**
 * 订单列表
 * @param { array } orders 订单列表
 * @param { function } onClick 点击订单反馈 
 * @returns 
 */
export default function OrderList({ orders, onClick }) {

  const handleClick = (order) => {
    openOrderDialog(order.id)
    if(onClick) onClick()
  }

  return (
    <Stack>
      {orders && orders.map(order => 
      <Box key={order.id}>
        <Stack direction='row' spacing={1}>

          <Stack spacing={0} onClick={()=>handleClick(order)} style={{cursor: 'pointer'}} >
            <Typography>{name(order.name)} -  {address(order.city)} - {postcode(order.postcode)}</Typography>
            <Typography>{order.order_id}</Typography>
            <Typography>{order.datetime}</Typography>
          </Stack>

          <Box flexGrow={1}></Box>

          <Stack spacing={1}>
            <Stack direction='row-reverse' spacing={1}>
              <Chip label={'£'+order.amount} />
            </Stack>
            <Stack direction='row-reverse'>
              <OrderStatus status={order.status} />
            </Stack>
          </Stack>

        </Stack>
        <Divider sx={{paddingTop:'0.5rem'}} />
      </Box>
      )}
    </Stack>
  )
}