/* 库存 接口 */
import call from 'utils/call' 

/***************************************************************/
export async function getRecordList(page) {
  console.log('库存调整记录')
  return await call('stock/recordList', {page: page})
}

/***************************************************************/
export async function updateRecord(data) {
  console.log('更新调整记录')
  await call('stock/updateRecord', data)
}

/***************************************************************/
export async function deleteRecord(data) {
  console.log('删除库存调整记录')
  await call('stock/deleteRecord', data)
}

/***************************************************************/
export async function getProductQty(productID) {
  console.log('产品数量')
  return await call('stock/productQty/'+productID)
}

/***************************************************************/
/* 进货记录 */

/***************************************************************/
export async function getPurchaseList(page) {
  console.log('进货记录列表')
  return await call('purchase/list/'+page)
}

/***************************************************************/
export async function getPurchaseDetails(purchase_id) {
  console.log('进货记录清单')
  return await call('purchase/details/'+purchase_id)
}

/***************************************************************/
export async function updatePurchase(data) {
  console.log('更新 进货记录信息')
  return await call('purchase/update', data)
}

/***************************************************************/
export async function addPurchase() {
  console.log('新建进货记录')
  await call('purchase/add')
}

/***************************************************************/
export async function savePurchaseItem(data) {
  console.log('更新进货记录细则')
  await call('purchase/updateItem', data)
}

/***************************************************************/
export async function deletePurchaseItem(item_id) {
  console.log('删除进货记录细则')
  await call('purchase/delItem/'+item_id)
}

/***************************************************************/
export async function getExpiryCheckList(days) {
  console.log('临期产品列表')
  return await call('stock/expiry/'+days)
}

/***************************************************************/
export async function expiryChecked(itemID) {
  console.log('临期产品已检查')
  return await call('stock/expiryChecked/'+itemID)
}