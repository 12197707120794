// UI
import { Divider, Stack } from "@mui/material"

// Components
import OrderReceiptHeader from "./OrderReceiptHeader"
import OrderReceiptFooter from "./OrderReceiptFooter"
import OrderReceiptContent from "./OrderReceiptContent"

export default function OrderReceipt({receipt}) {

  return (
    <Stack>
      <Stack spacing={2}>
        <OrderReceiptHeader order={receipt} />
        <Divider />
        <OrderReceiptContent order={receipt} />
        <OrderReceiptFooter company={receipt.company} />
      </Stack>
    </Stack>
  )
  
}