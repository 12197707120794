import { Stack, Typography, TableRow, TableCell, TableBody, TableContainer, Box } from '@mui/material'
import RSCard from 'rsui/RSCard'

import { useSelector } from 'react-redux'
import { orderStates } from 'redux/orderSlice'
import RSTable from 'rsui/RSTable';
import columns from '../../columns/OrderItemsColumns'
import CopyChip from '../../actions/CopyChip';
import EditOrderChip from 'components/order/actions/EditOrderChip';
import { money } from 'utils/str';
import ReceiptChip from 'components/order/actions/ReceiptChip';
import PickerChip from 'components/order/actions/PickerChip';

export default function OrderItemsCard() {
  const { order } = useSelector(orderStates)


  const FeeRow = (props) => {
    const { item, amount } = props 
    return (
    <TableRow>
      <TableCell colSpan={2}></TableCell>
      <TableCell colSpan={2}><b>{item}</b></TableCell>
      <TableCell>£{amount}</TableCell>
    </TableRow>
    )
  }

  const VoucherRow = (props) => {
    const { item, note, amount } = props 
    return (
    <TableRow>
      <TableCell></TableCell>
      <TableCell><b>{note}</b></TableCell>
      <TableCell colSpan={2}><b>{item}</b></TableCell>
      <TableCell><Typography color="error">-£{amount}</Typography></TableCell>
    </TableRow>
    )
  }

  const TotalRow = (props) => {
    const { item, amount } = props 
    return (
    <TableRow>
      <TableCell colSpan={2}></TableCell>
      <TableCell colSpan={2}><Typography variant='body1'><b>{item}</b></Typography></TableCell>
      <TableCell><Typography variant='body1'><b>£{amount}</b></Typography></TableCell>
    </TableRow>
    )
  }

  const Actions = () => {
    return (
    <Stack direction="row" spacing={1}>
      <ReceiptChip />
      <CopyChip orderID={order.id} />
      <EditOrderChip order={order} />
      <PickerChip />
    </Stack>)
  }

  const Rows= () => 
    <TableBody>
      {order.pack_fee !== "0.00" && <FeeRow item="包装费" amount={money(order.pack_fee)} key="pack_fee" />}
      {order.shipping_fee !== "0.00" && <FeeRow item="运费" amount={money(order.shipping_fee)} key="shipping_fee" />}
      {order.vip_discount !== "0.00" && <VoucherRow item="VIP折扣" amount={money(order.vip_discount)} key="vip_discount" />}
      {order.gift_fee !== "0.00" && <VoucherRow item="代金券" note={order.code} amount={money(order.gift_fee)} key="gift_fee" />} 
      {order.wallet !== "0.00" && <VoucherRow item="松鼠钱包" amount={money(order.wallet)} key="wallet" />}
      <TotalRow item="消费总计" amount={order.amount} key="amount" />
    </TableBody>

  return (
    <TableContainer component={RSCard} title="订单明细" action={<Actions />} >
      <RSTable 
        data={order.items} 
        columns={columns} 
        rows={<Rows />}
      />
    </TableContainer>
  )
}