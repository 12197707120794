import { Checkbox, FormControlLabel, Typography } from "@mui/material"

const CheckBox = (props) => {

  const { label, checked, onChange } = props

  const handleChange = () => {
    onChange()
  }

  return (
    <FormControlLabel 
      control={
        <Checkbox 
          checked={checked} 
          onChange={handleChange} 
          size="small"
        />
      } 
      label={<Typography variant="body2">{label}</Typography>}
    />
  )
}

export default CheckBox