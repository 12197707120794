import { createSlice } from '@reduxjs/toolkit'

// 登录状态
export const slice = createSlice({
  name: 'Tracking',
  initialState: {
    open: false,
  },
  reducers: {
    openTrackingDialog: (state) => {
      state.open = true
    },
    closeTrackingDialog: (state) => {
      state.open = false
    }
  },
})

export const { openTrackingDialog, closeTrackingDialog } = slice.actions
export const TrackingOpen = state => state.Tracking.open
export default slice.reducer

