import { useNavigate } from 'react-router-dom'
import DashboardPanel from '../DashboardPanel'
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import { useEffect, useState } from 'react';
import { getExpiredProductCount } from './api';

/* Dashboard 产品临近过期 Button */
export default function ExpiredProductCount() {

  const navigate = useNavigate()

  const handleClick = () => navigate('/stock/expiry')

  const [num, setNum] = useState(null)
 
  const fetchData = async () => {
    const data = await  getExpiredProductCount()
    if(data) setNum(data)
  }

  useEffect(() => {
    fetchData()
  }, []) 

  return (
    <DashboardPanel 
      label="产品保质期检查" 
      icon={<InventoryOutlinedIcon />}
      onClick={handleClick}
      num={num}
      color="error"
    />
  )
}