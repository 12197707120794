import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({
  name: 'app',
  initialState: {
    // auth
    uid: null, // firebase uid
    signed: null,
    permissions: null, // 用户权限
    company: null, // 公司信息 电话 地址等

    // Layout
    siderOpen: true,
    siderMenuSelected: null,
    siderItemSelected: '后台首页',

    openOrderSearch: false, // 订单搜索
    openProductSearch: false, // 产品搜索
    selectProductSearch: ()=>{}, // 产品搜索, 选中产品后
    loading: false,
  },
  reducers: {
    setSigned:(state, action) => {
      state.signed = action.payload
    },
    setMainMenuSelected:(state, action) => {
      state.mainMenuSelected = action.payload
    },
    setCompany:(state, action) => {
      state.company = action.payload
    },
    setPermissions:(state, action) => {
      state.permissions = action.payload
    },

    // Layout

    setSiderOpen:(state, action) => {
      state.siderOpen = action.payload
    },
    setSiderMenuSelected:(state, action) => {
      state.siderMenuSelected = action.payload
    },
    setSiderItemSelected:(state, action) => {
      state.siderItemSelected = action.payload
    },
    setOpenOrderSearch:(state, action) => {
      state.openOrderSearch = action.payload
    },

    // 产品搜索
    setOpenProductSearch:(state, action) => {
      state.openProductSearch = action.payload
    },
    setSelectProductSearch:(state, action) => {
      state.selectProductSearch = action.payload
    },
    

    setLoading:(state, action) => {
      state.loading = action.payload
    },
  },
})

export const { 
  
  // auth

  setSigned,
  setMainMenuSelected,
  setPermissions,
  setCompany,

  // layout

  setSiderOpen,
  setSiderMenuSelected,
  setSiderItemSelected,

  setOpenOrderSearch,
  setOpenProductSearch,
  setSelectProductSearch,

  setLoading
} = slice.actions
export const AppStore = state => state.app
export const appStates = state => state.app
export default slice.reducer