import { useEffect, useState, useRef } from 'react'
// Redux
import { useSelector } from 'react-redux'
import { orderStates, setOpen } from 'redux/orderSlice'
// UI
import { Button, Chip, ListItem, Stack, TextField } from '@mui/material'
import RSCard from 'rsui/RSCard'
import RSDialog from 'rsui/RSDialog'
import { language } from 'utils/str'
import NoticeList from './components/NoticeList'
import { getOrderNoticeList } from 'api/order'
import { getEmailTemplates, sendEmail } from 'api/email'
import { startLoading, stopLoading } from 'utils/layout'

// *******************************************************************************************************
// Card 订单通知记录

export default function OrderNoticeCard() {
  const { order } = useSelector(orderStates)
  const [notices, setNotices] = useState([])

  const fetchData = async () => {
    const data = await getOrderNoticeList(order.id)
    if(data) setNotices(data)
  }

  useEffect(() => {
    fetchData()
  }, [])

  const Actions = () => 
  <Stack direction="row" spacing={1}>
    <Chip label={ language(order.lang) } />
    <NoticeChip order={order} callback={fetchData} />
  </Stack>

  return (
    <RSCard title="通知记录" action={<Actions />}>
      <NoticeList notices={notices} />
    </RSCard>
  )
}

// *******************************************************************************************************
// 通知组件
// 可以编辑通知内容，发送通知到客户邮箱

function NoticeChip({ order, callback }) {

  const [openEmail, setOpenEmail] = useState(false)
  const [openOptions, setOpenOptions] = useState(false)

  const [templateList, setTemplateList] = useState([])

  const [email, setEmail] = useState()

  const openEmailDialog = () => setOpenEmail(true)
  const closeEmailDialog = () => setOpenEmail(false)

  const openOptionsDialog = async () => {
    setOpenOptions(true)
    const data = await getEmailTemplates()
    if(data) setTemplateList(data)
  }
  const closeOptionsDialog = () => setOpenOptions(false)

  const select = (template) => {
    if(order.lang === "cn") {
      setEmail({
        customer_id: order.customer_id,
        order_id: order.id,
        email: order.email,
        subject: template.subject_cn,
        body: template.body_cn
      })
    } else {
      setEmail({
        customer_id: order.customer_id,
        order_id: order.id,
        email: order.email,
        subject: template.subject_en,
        body: template.body_en
      })
    }
  
    closeOptionsDialog()
    openEmailDialog()
  }

  const changeSubject = (e) => {
    setEmail({
      ...email,
      subject: e.target.value
    })
  }

  const changeBody = (e) => {
    setEmail({
      ...email,
      body: e.target.value
    })
  }


  const handleSendEmail = async () => {
    startLoading()
    await sendEmail(email)
    stopLoading()
    closeEmailDialog()
    callback()
  }

  return (
    <>
    <Chip label="通知" onClick={openOptionsDialog} />

    <RSDialog
      title="邮件模板"
      open={openOptions}
      onClose={closeOptionsDialog}
      maxWidth="sm"
      fullWidth
    >
      {templateList.map(template => 
        <ListItem key={template.id} sx={{ cursor: 'pointer'}} onClick={()=>select(template)}>
          {template.subject_cn} <br/>
          {template.subject_en}
        </ListItem>
      )}
    </RSDialog>

    <RSDialog
      title="客户通知"
      open={openEmail}
      onClose={closeEmailDialog}
      maxWidth="sm"
      fullWidth
      actions={<Button variant='contained' onClick={handleSendEmail}>发送</Button>}
    >
      {email && 
      <Stack>
        <TextField label="标题" value={email.subject} onChange={changeSubject} />
        <TextField label="正文" multiline value={email.body} onChange={changeBody} />
      </Stack>
      }
    </RSDialog>
    </>
  )
}


