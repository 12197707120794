import { Card, CardContent, CardHeader, Divider, Typography } from "@mui/material"

export default function RSCard({title, action, headerCss, cardContentProps, children}) {

  return (
    <Card>
      <CardHeader 
        sx={{...headerCss, background: "#eee"}} 
        title={<Typography variant="body2" sx={{fontWeight: 'bold'}}>{title}</Typography>} 
        action={action}
      />
      <Divider />
      <CardContent {...cardContentProps}>
        {children}
      </CardContent>
    </Card>
  )
}