import { useState, useEffect } from 'react'
import { Avatar, Chip, Drawer, List, ListItemAvatar, ListItemButton, ListItemText, Pagination, Stack, TextField, Typography } from '@mui/material'
import RSCheckChip from 'rsui/RSCheckChip'
import { searchProduct } from 'api/product'
import { useDispatch, useSelector } from 'react-redux'
import { appStates, setOpenProductSearch, setSelectProductSearch } from 'redux/AppSlice'
import store from 'redux/store'

function openProductSearchDrawer() {
  store.dispatch(setOpenProductSearch(true))
}

function closeProductSearchDrawer() {
  store.dispatch(setOpenProductSearch(false))
}

/**
 * 产品搜索按钮
 * @param { string } label 标签名称 默认值'产品'
 * @param { function } onSelect 点击产品后的方法
 * @returns 
 */
export function ProductSearchChip({ label = "产品", onSelect = () => {} }) {

  const dispatch = useDispatch()

  const openDrawer = () => {
    dispatch(setSelectProductSearch(onSelect))
    openProductSearchDrawer()
  }

  return <Chip label={label} onClick={openDrawer} />
}

/**
 * 产品搜索抽屉
 * @param { string } label 标签名称 默认值'产品'
 * @param { function } onClick 点击产品后的方法
 * @returns 
 */
export default function ProductSearchDrawer() {

  const { openProductSearch, selectProductSearch } = useSelector(appStates)
  
  return (
    <Drawer 
      anchor={'right'}
      open={openProductSearch}
      onClose={closeProductSearchDrawer}
    >
      <Stack sx={{width: 300}} pb={2} m={1}>
        <SearchStack onClick={selectProductSearch} />
      </Stack>
    </Drawer>
  )
}

export function SearchStack({onClick}) {

  const label = "产品搜索"
  const [keywords, setKeywords] = useState('')
  const [result, setResult] = useState()
  const [include, setInclude] = useState(false)
  const [page, setPage] = useState(1)

  const changeKeywords = (event) => {
    setKeywords(event.target.value)
  }

  const handlePressEnter = (event) => {
    if(event.key === 'Enter'){
      if(keywords && keywords.length >= 1) {
        setPage(1)
        search()
      }
    }
  }

  const search = async () => {
    if(keywords === '') return;
    const conditions = {
      keywords: keywords,
      page: page,
      include_hide: include
    }

    const data = await searchProduct(conditions)
    if(data) {
      setResult(data)
    }
  }

  const changePage = (e, p) => {
    setPage(p)
  }

  const checkInclude = (checked) => {
    setInclude(checked)
  }

  useEffect(() => {
    search()
  }, [page, include])

  return (
    <Stack spacing={0}>
        
      <TextField
        label={label}
        fullWidth
        margin="dense"
        value={keywords}
        onChange={changeKeywords}
        onKeyDown={handlePressEnter}
        InputProps={{
          endAdornment: <RSCheckChip label="含下架" checked={include} onClick={checkInclude} />
        }}
      />

      {result && <ProductSearchResult products={result.products} onClick={onClick}/>}

      {result && result.pages > 1 && <Pagination count={result.pages} onChange={changePage} page={page} />}

    </Stack>
  )
}

// *************************************************************
// 产品列表

function ProductSearchResult({ products, onClick }) {

  return (
    <List>
    {products && products.map(product => 
      <ListItemButton key={product.id} onClick={()=>onClick(product)}>
        <ListItemAvatar>
          <Avatar src={product.img+'?x-oss-process=style/100x100'} alt="" variant="rounded" />
        </ListItemAvatar>
        <ListItemText 
          primary={<Typography variant='body' sx={product.hide === '1'?{textDecoration: 'line-through'}:{}}>{product.name_cn}</Typography>} 
          secondary={'#'+product.id+' 库存:'+product.stock_qty + ' 保质期:'+product.expiry_date}
          secondaryTypographyProps={{sx: {fontSize: '0.75rem'}}}
        />
      </ListItemButton>  
    )}
    </List>
  )
}