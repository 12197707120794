import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({
  name: 'chatBox',
  initialState: {
    role: 'agent',
    open: false, // Dialog
    sending: false, // 正在发送消息
    sendingFailed: false,
    conversation: null, // 对话属性 
    messages: {}, // 对话内容
    fontSize: '1rem', // 聊天框字体大小
    inputText: '', // 对话框文字
    showActionPanel: false,
    showEmojiPanel: false
  },
  reducers: {
    setOpen:(state, action) => {
      state.open = action.payload
    },
    setRole:(state, action) => {
      state.role = action.payload
    },
    setSending:(state, action) => {
      state.sending = action.payload
    },
    setSendingFailed:(state, action) => {
      state.sendingFailed = action.payload
    },
    setConversation:(state, action) => {
      state.conversation = action.payload
    },
    setMessages:(state, action) => {
      state.messages = action.payload
    },
    setInputText:(state, action) => {
      state.inputText = action.payload
    },
    setFontSize:(state, action) => {
      state.fontSize = action.payload
    },
    addEmoji:(state, action) => {
      state.inputText += action.payload
    },
    setShowEmojiPanel:(state, action) => {
      state.showEmojiPanel = action.payload
    },
    setShowActionPanel:(state, action) => {
      state.showActionPanel = action.payload
    }
  },
})

export const { 
  setOpen,
  setRole,
  setSending,
  setSendingFailed,
  setConversation,
  setMessages,
  setInputText,
  addEmoji,
  setFontSize,
  setShowEmojiPanel,
  setShowActionPanel
} = slice.actions

export const chatBoxStates = state => state.chatBox

export default slice.reducer