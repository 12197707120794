import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import MenuItems from "./MenuItems";

const menu = {
  "text": "客户管理",
  "icon": AccountCircleOutlinedIcon,
  "permission": "staff",
  "sub": [
    {
      "text": "钱包充值",
      "path": "/customer/wallet"
    },    
    {  
      "text": "VIP管理",
      "path": "/customer/vip"
    },
  ]
}

export default function MenuItemCustomer() {

  return <MenuItems menu={menu} />
}