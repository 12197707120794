import { Typography, Pagination, Stack, Chip } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { openVoucherEditor, setVouchers, setVouchersPage, setVouchersPages, voucherStates } from '../slice'
import RSCard from 'rsui/RSCard'
import RSTable from 'rsui/RSTable'
import { getVouchers } from '../api'
import { useEffect } from 'react'
import VoucherEditor from './VoucherEditor'

// 代金券 列表
export default function VoucherList() {
  
  const dispatch = useDispatch()
  const { voucher, vouchers, vouchersPage, vouchersPages } = useSelector(voucherStates)

  const fetchData = async () => {
    const data = await getVouchers(vouchersPage)
    if(data) {
      dispatch(setVouchersPages(data.pages))
      dispatch(setVouchers(data.data))
    }
  }

  useEffect(() => {
    fetchData()
  }, [vouchersPage])

  const changePage = (e, p) => { 
    dispatch(setVouchersPage(p));
  }


  const VoucherCode = ({voucher}) => 
  <Stack spacing={0}>
    <Typography>{voucher.code}</Typography>
    <Typography variant='caption'>{'满 £'+Number(voucher.condition).toFixed(0)+' 减 £'+Number(voucher.deduction).toFixed(0)}</Typography>
  </Stack>

  const VoucherNote = ({voucher}) => 
  <Stack spacing={0}>
    <Typography>{voucher.description}</Typography>
    <Typography variant='caption'>{'有效期 '+voucher.expiry_date}</Typography>
  </Stack>

  const VoucherEmail = ({voucher}) => 
  <Stack spacing={0}>
    <Typography>{voucher.name}</Typography>
    <Typography variant='caption'>{voucher.email}</Typography>
  </Stack>

  const StateChip = ({voucher}) => {
    if(Number(voucher.order_id) > 0){ 
      return <Chip label="已使用" color="success" />
    }
    else if(Number.parseInt(voucher.order_id) < 0) {
      return <Chip label="已撤销" color="error" />
    }
    else if(Date.parse(voucher.expiry_date) < new Date()) {
      return <Chip label="已过期" color="warning" />
    }else {
      return <Chip label="重发" onClick={()=>openVoucherEditor(voucher)} />
    }
  }

  const columns = [
    {
      title: '代金券',
      render: (voucher) => <VoucherCode voucher={voucher} />,
    },
    {
      title: '备注',
      render: (voucher) => <VoucherNote voucher={voucher} />,
    },
    {
      title: '客户',
      render: (voucher) => <VoucherEmail voucher={voucher} />,
    },
    {
      title: '',
      render: (voucher) => <StateChip voucher={voucher} />,
    },
  ]

  return (
  <RSCard title="代金券" >
    <Stack>
      <RSTable data={vouchers} columns={columns}/>
      <Pagination count={vouchersPages} onChange={changePage} page={vouchersPage} />
      { voucher && <VoucherEditor />}
    </Stack>
  </RSCard>
  )
}