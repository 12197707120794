import { Typography, Table, TableHead, TableRow, TableCell, TableBody, TableContainer } from '@mui/material'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

export default function OrderPickerContent({ order }) {

  const ItemHead = () => {
    return (
    <TableRow>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell>Item</TableCell>
      <TableCell>Unit Price</TableCell>
      <TableCell>Qty</TableCell>
      <TableCell>Total</TableCell>
    </TableRow>
    )
  }

  const ItemRow = ({ item }) => <TableRow>
    <TableCell><CheckBoxOutlineBlankIcon /></TableCell>
    <TableCell><img src={item.img} alt="" width="50" /></TableCell>
    <TableCell>
      #{item.product_id} {item.name_cn}<br/>{item.name_en}
    </TableCell>
    <TableCell>£{item.price}</TableCell>
    <TableCell><Typography variant='h5'><b>{item.qty}</b></Typography></TableCell>
    <TableCell>£{item.amount}</TableCell>
  </TableRow>

  const FeeRow = (props) => {
    const { item, amount } = props 
    return (
    <TableRow>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell><b>{item}</b></TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell>£{amount}</TableCell>
    </TableRow>
    )
  }

  const VoucherRow = (props) => {
    const { item, note, amount } = props 
    return (
    <TableRow>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell><b>{item}</b> {note}</TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell><Typography color="error">-£{amount}</Typography></TableCell>
    </TableRow>
    )
  }

  const TotalRow = (props) => {
    const { item, amount } = props 
    return (
    <TableRow>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell><b>{item}</b></TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell><b>£{amount}</b> </TableCell>
    </TableRow>
    )
  }

  return (
    <TableContainer >
      <Table size="small">
        <TableHead>
          <ItemHead />
        </TableHead>
        {order.items && 
        <TableBody>

          {order.items.map((item, index) => 
            item.frozen === '0' && item.chilled === '0' && <ItemRow item={item} key={index} />
          )}


          { order.chilled === "1" && 
          <TableRow>
            <TableCell></TableCell>
            <TableCell colSpan={6}><b>冷藏 Chilled</b></TableCell>
          </TableRow>
          }

          { order.chilled === "1" &&  
            order.items.map((item, index) => 
              item.chilled === '1' && <ItemRow item={item} key={index} />
          )}

          { order.frozen === "1" && 
          <TableRow>
            <TableCell></TableCell>
            <TableCell colSpan={6}><b>冷冻 Frozen</b></TableCell>
          </TableRow>
          }


          { order.frozen === "1" &&  
            order.items.map((item, index) => 
              item.frozen === '1' && <ItemRow item={item} key={index} />
          )}

  
          { order.pack_fee !== "0.00" && 
            <FeeRow item="Packing Fee" amount={order.pack_fee} />
          }

          { order.shipping_fee !== "0.00" && 
            <FeeRow item="Shipping Fee" amount={order.shipping_fee} />
          }

          { order.vip_discount !== "0.00" && 
            <VoucherRow item="VIP Discount" amount={order.vip_discount} />
          }

          { order.gift_fee !== "0.00" && 
            <VoucherRow item="Voucher Discount" note={order.code} amount={order.gift_fee} />
          }

          { order.wallet !== "0.00" && 
            <VoucherRow item="RS Wallet"  amount={order.wallet} />
          }

          <TotalRow item="Total(inc. VAT)" amount={order.amount} />

        </TableBody>
        }
      </Table>
    </TableContainer>
  )
}