import { Box, Dialog, DialogContent, DialogTitle, Divider, IconButton, Stack, Typography } from "@mui/material"
import { useImperativeHandle, useState, forwardRef } from "react"
import CloseIcon from '@mui/icons-material/Close';

const RSDialog = forwardRef((props, ref) => {

  const [open, setOpen] = useState(false)
  const { title, subtitle, width, action, children, bg, onOpen, onClose, show } = props

  useImperativeHandle(ref, ()=> ({
    open() {
      setOpen(true)
    },
    close() {
      setOpen(false)
    },
    openDialog() {
      openDialog()
    },
    closeDialog() {
      closeDialog()
    }
  }))

  const openDialog = () => {
    setOpen(true)
    if(onOpen) onOpen()
  }

  const closeDialog = () => {
    setOpen(false)
    if(onClose) onClose()
  }

  return (
    <Dialog open={show?show:open} onClose={closeDialog} fullWidth={true} maxWidth={width}>
      <DialogTitle sx={{background: "#eee"}} >
        <Stack direction="row" spacing={2}>
          <Stack sx={{flexGrow: 1}} spacing={0}>
            <Typography variant='h5'>{title}</Typography>
            {subtitle && <Typography variant='caption'>{subtitle}</Typography>}
          </Stack>
          <Box>
            <Stack direction="row" spacing={1}>
              {action}
              <IconButton onClick={closeDialog} size='small'><CloseIcon /></IconButton>
            </Stack>
          </Box>
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContent sx={bg?{background: bg}:{}}>
        {children}
      </DialogContent>
    </Dialog>
  )

})

export default RSDialog