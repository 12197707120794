// 订单排序
import RSMenu from 'rsui/RSMenu'

export default function SortChip ({sort, callback}) {

  const items = [
    {label: '时间', index: 'id'},
    {label: '姓名', index: 'name'},
    {label: '冷冻', index: 'frozen'},
    {label: '邮编', index: 'postcode'}
  ]

  const handleItem = (item) => {
    callback(item)
  }

  return (
    <RSMenu 
      name="排序"
      label={sort.label} 
      items={items} 
      callback={handleItem} 
    />
  )
}

