import { useState } from "react";
import { Chip } from "@mui/material";
import RSDialog from "rsui/RSDialog";
import RSForm from "rsui/RSForm";
import { updateSupplier } from "api/supplier";

// 供货商信息编辑

export default function SupplierEditor({ supplier, callback }) {

  const [open, setOpen] = useState(false)

  const openDialog = () => {
    setOpen(true)
  }

  const closeDialog = () => {
    setOpen(false)
  }

  const fields = [
    {
      contents: [
        {
          type: 'input',
          name: 'name_cn',
          label: 'Name CN'
        },
        {
          type: 'input',
          name: 'name_en',
          label: 'name EN'
        }
      ]
    },
    {
      contents: [
        {
          type: 'input',
          name: 'address_1',
          label: 'Address Line 1'
        },
        {
          type: 'input',
          name: 'address_2',
          label: 'Address Line 2'
        }
      ]
    },
    {
      contents: [
        {
          type: 'input',
          name: 'city',
          label: 'City'
        },
        {
          type: 'input',
          name: 'postcode',
          label: 'Postcode'
        }
      ]
    },
    {
      contents: [
        {
          type: 'input',
          name: 'email',
          label: 'Email'
        },
        {
          type: 'input',
          name: 'tel',
          label: 'Tel'
        }
      ]
    },
    {
      type: 'input',
      name: 'note',
      label: '备注'
    },
    {
      type: 'checkbox',
      name: 'hide',
      label: '永久隐藏'
    }
  ]

  const handleSave = async (formData) => {
    await updateSupplier(formData)
    callback()
    closeDialog()
  }

  return (
    <>
      <Chip label={supplier.id?"编辑":"新建"} onClick={openDialog} />
      <RSDialog 
        title="供货商信息"
        open={open}
        onClose={closeDialog}
      >
        <RSForm 
          data={supplier} 
          fields={fields} 
          onSave={handleSave} 
        />
      </RSDialog>
    </>
  )
}