/* 本地配送 司机相关 */

import call from 'utils/call' 

/***************************************************************/
export async function getTasks() {
  console.log("获得今日配送任务")
  return await call('delivery/getTasks')
}

/***************************************************************/
export async function noticeNext(data) {
  console.log("发送通知下一站")
  return await call('delivery/noticeNext', data)
}

/***************************************************************/
export async function noticeDone(data) {
  console.log("发送通知已送达")
  return await call('delivery/noticeDone', data)
}

/***************************************************************/
export async function updateLatLng(data) {
  console.log("更新位置")
  return await call('delivery/updateLatLng', data)
}

/***************************************************************/
export async function getDriverPosition(driver_id) {
  console.log("获得司机位置")
  return await call('dashboard/getDriverPosition/'+driver_id,)
}