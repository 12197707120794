
import { auth } from './RSFirebase'
import axios from 'axios'
import Qs from 'qs'
import fileDownload from 'js-file-download'
import {RSLog, showFail} from 'utils/RSUtils'

import store from 'redux/store'
import { showMessage } from 'components/snack/snackSlice'

const api = "https://adminapi.redsquirrel.shop/"
let endpoint = "https://v2.redsquirrel.shop//"
if(process.env.NODE_ENV === 'development') {
  endpoint = "http://localhost:8080/backend/";
}

export const Req = (api, data = null, method = null, filename = null) => { return new Promise((resolve, reject) => {

  // 返回处理
  const resHandler = async (res) => {
    // console.log('调取成功')
    if(res.status === 200) {
      const data = res.data
      console.log(data)
      if(data.error) {
        if(data.error === 400) {
          showFail(data.message)
        }
        else if(data.error === 401) {
          console.log('用户认证失败 登出')
          //auth.signOut()
          showFail('没有权限')
        }
        reject(data.message)
      }
      if(method === 'download') {
        fileDownload(res.data, filename)
      }
      resolve(res.data)
    } else reject('异常错误')
  }

  // 服务器端异常处理
  const errHandler = (error) => {

    if(error.response) {
      if(error.response.data.message) { // 400
        showFail(error.response.data.message)
        reject(error.response.data.message)
       }
   
      else if(error.response.data.messages) { // 400
      showFail(error.response.data.messages.error)
      reject(error.response.data.messages.error)
        
      } else if (error.request) {
        showFail(error.request)
        reject(error.request)
      } else {
        showFail(error.message)
        reject(error.message)
      }
    }
    reject('API读取错误') 
  }

  auth.currentUser.getIdToken().then((idToken) =>{
    const url = endpoint+api
    
    let config = {
      headers: {
        'Content-Type': 'application/json',
        'Rs-Id-Token': idToken
      }
    }
    
    if(method === 'upload') {
      config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Rs-Id-Token': idToken
        }
      }
    } else if (method === 'download') {
      config = {
        ...config,
        responseType: 'blob'
      }
    }

    if(data) {
      console.log('Post: '+url)
      axios.post(url, data, config)
        .then(res => {
          resHandler(res)
          return res.data
        })
        .catch(err => {
          errHandler(err)
          return null
        })
    } else {
      console.log('Get: '+url)
      axios.get(url, config)
        .then(res => {
          resHandler(res)
          return res.data
        })
        .catch(err => {
          errHandler(err)
          return null
        })
    }
  })

})}



// Request Get
export function RSGet(uri, callback) {
  RSPost(uri, {}, callback)
}

// Request Post
export function RSPost(uri, postData, callback) {
  auth.currentUser.getIdToken().then((idToken) =>{
    const data = { 
      idToken: idToken, 
      ...postData
    }
    const url = api+uri
    const headers = {'Content-Type': 'application/json'}

    axios.post(url, Qs.stringify(data), headers)
      .then(res => {
        const data = res.data
        RSLog('Post: '+url)
        RSLog(data)
        callback(data)
      })
      .catch(function (error) {
        RSLogError(error)
        callback(false)
      })
    })
}


export function RSUpload(uri, data, callback) {
  auth.currentUser.getIdToken().then((idToken) =>{
    const url = api+uri
    data.append("idToken", idToken)
    const headers = { 
      "Content-Type": "multipart/form-data",
    }
    console.log('Upload: '+url)
    axios.post(url, data, headers).then(callback)
  })
}

export function RSDownload(uri, data, filename) {
  auth.currentUser.getIdToken().then((idToken) =>{
    const url = api+uri
    const postdata = { ...data, idToken: idToken }
    RSLog('Download '+url)
    RSLog(Qs.stringify(postdata))
    axios.post(url, Qs.stringify(postdata), {responseType: 'blob'})
    .then(res => {
      fileDownload(res.data, filename)
    })
  })
}

function RSLogError(error) {
  console.log(error)
  if(error.response) {
   RSLog(error.response.data.message)

    if(error.response.data.message) { // 400
      store.dispatch(showMessage('error',error.response.data.message))
    }

    if(error.response.data.messages) { // 400
      store.dispatch(showMessage('error',error.response.data.messages.error))
    }
   
  //RSLog(error.response.status)
  //RSLog(error.response.headers)
  } else if (error.request) {
    RSLog(error.request);
  } else {
    RSLog(error.message);
  }
}