// UI
import { Typography, Stack } from '@mui/material'

export default function OrderPickerFooter ({company}) {
 
  return (
    <Stack>
      <Typography>
        感谢您在红松鼠超市购物。如果您有什么没有买到的，请通知我们进货。如果您对我们的产品或服务有任何疑问，欢迎您随时与我们联系。<br/>
        <br/>
        Thank you for shopping with us. If you would need other products that we currently do not stock, please kindly let us know, we will do our best to stock them soon.
        <br/>
        If you have any questions about our products or services, please feel free to contact us at any time.<br/>
        <br/>
        Tel: {company.tel}<br/>
        Email: {company.email}<br/>
        Address: {company.address}
      </Typography>
    </Stack>
  )
}