// Mui Theme
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiGrid: {
      defaultProps: {
        spacing: 2
      }
    },
    MuiSvgIcon: {
      defaultProps: {
        fontSize: "small"
      }
    },
    MuiStack: {
      defaultProps:{
        spacing: 2
      }
    },
    MuiTypography: {
      defaultProps: {
        variant: "body2",
      }
    },
    MuiChip: {
      defaultProps: {
        size: "small"
      }
    },
    MuiTable: {
      defaultProps: {
        size: "small"
      }
    },
    MuiPagination: {
      defaultProps: {
        variant: "outlined",
        shape: "rounded",
        hideNextButton: true,
        hidePrevButton: true, 
        defaultPage: 1 
      }
    },
    MuiAlert: {
      styleOverrides: {
        action: {
          padding: '3px 0 0 16px'
        }
      }
    }
  }
})

export default theme