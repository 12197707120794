import store from 'redux/store'
import { getAuth } from 'firebase/auth'
import call from 'utils/call'
import { setPermissions, setSigned } from 'redux/AppSlice'

// 登录
export async function login() {
  await checkPermissions()
}

// 获取权限
async function checkPermissions() {
  const res = await call('auth/permission')
  if(res) {
    store.dispatch(setPermissions(res))
    store.dispatch(setSigned(true))
    checkVersion()
  } else {
    logout()
  }
}

// 获取版本
async function checkVersion() {
  console.log('获取版本')
  const res = await call('auth/version')
  if(res) {
    const version = res
    
    // 检查版本
    var localVersion = localStorage.getItem('version')

    if(!localVersion) {
      // 第一次浏览 本地没有version信息 保存version
      localStorage.setItem('version', version)
    } else if (Number(localVersion) !== Number(version)) {
      // 版本有更新 自动刷新页面
      localStorage.setItem('version', version)
      window.location.reload()
    }
  } 
}

// 登出
export function logout() {
  const auth = getAuth()
  store.dispatch(setSigned(false)) 
  auth.signOut()
}

/**************************************************************************/
// 是否有权限
export function hasPermission(permission) {
  const permissions = store.getState().app.permissions
  if(permissions && permissions.includes(permission)){
    return true
  } else {
    return false
  }
}