import { Grid, LinearProgress, Stack } from "@mui/material"
import RSDialog from "rsui/RSDialog"

// Redux
import store from 'redux/store'
import { useSelector } from 'react-redux'
import { loadOrder, orderStates, setOpen } from 'redux/orderSlice'

// Components
import OrderStatusCard from "./cards/OrderStatusCard"
import CustomerCard from "components/customer/CustomerCard"
import OrderNoticeCard from "./cards/notice/OrderNoticeCard"
import OrderItemsCard from "./cards/OrderItemsCard"
import { startLoading, stopLoading } from "utils/layout"
import OrderShippingCard from "./cards/OrderShippingCard"
import OrderPaymentCard from "./payment/OrderPaymentCard"


export async function openOrderDialog(orderID) {
  startLoading()
  await loadOrder(orderID)
  stopLoading()
  store.dispatch(setOpen(true))
}

export function closeOrderDialog() {
  store.dispatch(setOpen(false))
}

function OrderDialog() {

  const { open, order } = useSelector(orderStates)

  return (
    <RSDialog
      open={open} 
      onClose={closeOrderDialog}
      maxWidth="xl"
      fullWidth
      title={order?order.order_id:''} 
    >
      { !order && <LinearProgress /> }
      { order && 
      <Grid container>
        <Grid item xs={12} md={12} lg={6}>
          <OrderItemsCard />
        </Grid>

        <Grid item xs={12} md={6} lg={3}>
          <OrderShippingCard />
        </Grid>

        <Grid item xs={12} md={6} lg={3}>
          <Stack spacing={2}>
            <CustomerCard customer={order.customer} />
            <OrderStatusCard />
            <OrderPaymentCard />
            <OrderNoticeCard />
          </Stack>
        </Grid>
      </Grid>
      }
    </RSDialog>
  )
}

export default OrderDialog