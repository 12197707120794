import { createSlice } from '@reduxjs/toolkit'
import store from './store'
import { getTags } from 'api/tag'

/* 框架状态 */
export const slice = createSlice({
  name: 'tag',
  initialState: {
    category: null, // 产品类别，二级栏目
    brand: null, // 品牌
    event: null, // 活动 系列 节日
    from: null, // 产地
  },
  reducers: {
    setCategory:(state, action) => {
      state.category = action.payload
    },
    setBrand:(state, action) => {
      state.brand = action.payload
    },
    setEvent:(state, action) => {
      state.event = action.payload
    },
    setFrom:(state, action) => {
      state.from = action.payload
    },
  },
})

export const { 
  setCategory,
  setBrand,
  setEvent,
  setFrom
} = slice.actions

export const tagStates = state => state.tag
export default slice.reducer

export const getTagsByType = async (typeID) => {
  let tags = null

  if(typeID === '3') {
    if(store.getState().tag.category) tags = store.getState().tag.category
  } else if(typeID === '0') {
    if(store.getState().tag.brand) tags = store.getState().tag.brand
  } else if(typeID === '1') {
    if(store.getState().tag.event) tags = store.getState().tag.event
  } else if(typeID === '9') {
    if(store.getState().tag.from) tags = store.getState().tag.from
  } 

  if(!tags) {
    const data = await getTags(typeID)
    if(data) {
      if(typeID === '3') {
        store.dispatch(setCategory(data))
      } else if(typeID === '0') {
        store.dispatch(setBrand(data))
      } else if(typeID === '1') {
        store.dispatch(setEvent(data))
      } else if(typeID === '9') {
        store.dispatch(setFrom(data))
      } 
      return data
    }
  } else return tags

}