
import { editOrderInNewOrders } from "redux/newOrdersSlice"
import RSTextEditor from "rsui/RSTextEditor"

const columns = [
  {
    title: '#',
    render: (order) => <RSTextEditor
                          item={order}
                          entity="sort"
                          text={order.sort}
                          width="4rem"
                          callback={editOrderInNewOrders}
                       />
  },
  {
    title: 'Name',
    dataIndex: 'name'
  },
  {
    title: 'Mobile',
    dataIndex: 'phone'
  },
  {
    title: 'Address',
    render: (order) => (
    <>
      {order.address_1}<br/>
      {order.address_2 && <>{order.address_2}<br/></>}
      {order.address_3 && <>{order.address_3}<br/></>}
      {order.city} {order.county}
    </>
    )
  },
  {
    title: 'Postcode',
    dataIndex: 'postcode'
  },
  {
    title: 'Box',
    dataIndex: 'box'
  }
]

export default columns