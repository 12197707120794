import { styled } from '@mui/material/styles';

import AuthPage from "pages/AuthPage"

import { useSelector } from "react-redux"
import { AppStore } from "redux/AppSlice.js"
import { login, logout } from 'utils/auth'

import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { useEffect } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import { Box } from "@mui/material"

// Layout UI
import SiderMenu from "./sider/SiderMenu"
import Header from "./header/Header"
import { hasPermission, startLoading, stopLoading } from 'utils/layout';

// Global UI
import RSSnackbar from 'components/snack/RSSnackbar';
import OrderDialog from 'components/order/dialog/OrderDialog';
import LayoutLoader from './LayoutLoader';
import OrderSearchDrawer from '../components/order/OrderSearchDrawer';
import ProductSearchDrawer from 'components/product/search/ProductSearchDrawer';
import ProductEditDialog from 'components/product/dialog/ProductEditDialog';

const drawerWidth = 200;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);


export default function Layout() {

  const auth = getAuth()
  const { signed, siderOpen } = useSelector(AppStore)
  const navigate = useNavigate()
  
  const handleAuthStateChanged = async (user) => {
    if(user) {
      console.log('已登录')
      await login()
      stopLoading()

      const currentPath = window.location.pathname;
      if(currentPath === '/') {
        if(hasPermission('staff')) navigate('/dashboard')
        else if(hasPermission('driver')) navigate('/london-delivery')
      }

    } else {
      console.log('未登录')
      logout()
      stopLoading()
    }
  }

  useEffect(() => {
    console.log('开始监听登录状态')
    startLoading()
    const unsubscribe = onAuthStateChanged(auth, handleAuthStateChanged);
    return () => unsubscribe();
  }, [])

  return (
    <>
    {!signed && <AuthPage />}
    {signed &&
      <Box display='flex'>
        <SiderMenu/>
        <Main open={siderOpen}>
          <Header />
          <Box sx={{background: '#fff', minHeight: '93vh'}}>
            <Box p={2}>
              <Outlet />
            </Box>
          </Box>
        </Main>

        {/* 全局组件 */}
        <OrderSearchDrawer />
        <ProductSearchDrawer />
        <OrderDialog />
        <ProductEditDialog />
      </Box>
    }
    <LayoutLoader />
    
    <RSSnackbar />
    </>
  )
}