import { Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react';
import call from 'utils/call' 

// 产品补图片
export default function ProductImg() 
{

  const [num, setNum] = useState(0)

  const getImgsNum = async () => {
    const data = await call('test/getProductsWithoutImg');
    setNum(data)
  }

  const setImg = async () => {
    await call('test/setProductImg');
  }

  useEffect(() => {
    getImgsNum()
    const intervalID = setInterval(()=>{
      getImgsNum()
    }, 60000)
    return () => clearInterval(intervalID)
  }, [])

  useEffect(() => {
    setImg()
    const intervalID = setInterval(()=>{
      setImg()
    }, 1000)
    return () => clearInterval(intervalID)
  }, [])

  return (
  <Stack>
    <Typography>新数据结构下 需要给 Product 表 添加 Img 默认图片字段内容</Typography>
    <Typography>没有图片的产品 {num}</Typography>
  </Stack>
  )
}