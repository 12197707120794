import { Chip, Stack } from "@mui/material"

export default function ProductChips({product}) {

  return (
    <Stack direction="row" spacing={1}>
      {product.frozen === '1' && <FrozenChip />}
      {product.chilled === '1' && <ChilledChip />}
      {product.adult === '1' && <AdultChip />}
    </Stack>
  )
}

export function ProductTemperatureChip({product}) {
  return (
    <>
      {product.frozen === '1' && <FrozenChip />}
      {product.chilled === '1' && <ChilledChip />}
    </>
  )
}

export function FrozenChip() {
  return <Chip label="冷冻" color="primary" variant="outlined" />
}

export function ChilledChip() {
  return <Chip label="冷藏" color="info"  variant="outlined" />
}

export function AdultChip() {
  return <Chip label="成人" color="warning" variant="outlined" />
}

export function PromotionChip({label}) {
  return <Chip label={label} color="error" />
}

// 利润率
export function MarginChip({rate}) {
  let color = 'default'
  if(parseInt(rate) < 40) color = 'warning'
  if(parseInt(rate) < 30) color = 'error'

  return <Chip label={rate+'%'} color={color} />
}
