import { createSlice } from '@reduxjs/toolkit'
import { getNewOrders, updateOrder } from 'api/order'
import store from 'redux/store'

// 新订单列表属性
export const slice = createSlice({
  name: 'newOrders',
  initialState: {
    newOrders: [], // 新订单列表
    newOrdersNum: 0, // 新订单数量
    newOrderID: 0, // 最新订单ID 用于刷新列表

    openAddress: false,
  },
  reducers: {
    // 新订单
    setNewOrders: (state, action) => {
      state.newOrders = action.payload
    },
    setNewOrdersNum: (state, action) => {
      state.newOrdersNum = action.payload
    },
    setNewOrderID: (state, action) => {
      state.newOrderID = action.payload
    },
    setOpenAddress: (state, action) => {
      state.openAddress = action.payload
    },
  },
})

export const { 
  setNewOrders,
  setNewOrdersNum,
  setNewOrderID,
  setOpenAddress
} = slice.actions

export const newOrdersStates = state => state.newOrders

export default slice.reducer

/**
 * 筛选出某物流方式的订单
 * @param {int} logisticID - 物流方式 ID
 * @param {string} sort - 筛选方式 
 * @param {boolean} includeOnHold - 是否包含 holding 订单
 * @returns [orders]
 */
export function filterNewOrderList(logisticsID, sort = 'id', includeOnHold = true) {
  
  const orders = store.getState().newOrders.newOrders
  const tempOrders = []
  orders.forEach(order => {
    if(logisticsID === Number(order.logistics_id) && order.status === '0') { // 筛选物流方式
      if(includeOnHold) {
        tempOrders.push(order) // 包含 hold 单
      }
      else if(!Number(order.hold)){
        tempOrders.push(order) // 不包含 hold 单
      }
    }
  });

  // 排序
  tempOrders.sort((a, b)=>{
    if(sort === 'frozen')
      return (a.frozen + a.chilled).localeCompare(b.frozen + b.chilled)
    else if(sort === 'id')
      return Number(b[sort]) - Number(a[sort])
    else if(sort === 'sort')
      return Number(a[sort]) - Number(b[sort])
    else{
      return ((a[sort]).trim()).localeCompare((b[sort]).trim())
    }
      
  })

  return tempOrders
}

/***************************************************************/
// 一个订单数据变化时 更新本地订单列表数据
export const editOrderInNewOrders = (data) => {
  const newOrders = store.getState().newOrders.newOrders
  const newData = newOrders.map(order => {
    if(order.id === data.id) {
      return {
        ...order,
        ...data
      }
    } else return order
  })
  store.dispatch(setNewOrders(newData))
  updateOrder(data)
}

/***************************************************************/
// 读取新订单
export const loadNewOrders = async () => {
  const newOrders = await getNewOrders()
  if(newOrders) store.dispatch(setNewOrders(newOrders))
}