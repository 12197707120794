import { Chip, Stack } from "@mui/material"

export default function FeatureChips({product}) {

  return (
    <Stack direction="row" spacing={1}>
      {product.frozen === '1' && <FrozenChip />}
      {product.chilled === '1' && <ChilledChip />}
      {product.adult === '1' && <AdultChip />}
    </Stack>
  )
}

export function FrozenChip() {
  return <Chip label="冷冻" color="primary" variant="outlined" />
}

export function ChilledChip() {
  return <Chip label="冷藏" color="info"  variant="outlined" />
}

export function AdultChip() {
  return <Chip label="成人" color="warning" variant="outlined" />
}