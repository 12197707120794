import RSChipsGroup from 'rsui/RSChipsGroup'
import { Outlet } from "react-router-dom"

/* 销售数据 */
export default function AnalyticSales() {

  return (
    <>
      <RSChipsGroup 
        data={[
          {label:"日销售", to:"/analytics/sales/day"},
          {label:"月销售", to:"/analytics/sales/month"},
          {label:"年销售", to:"/analytics/sales/annual"},
          {label:"Daily Takings", to:"/analytics/sales/dailyTakings"}
        ]}
      />

      <Outlet />
    </>
  )
}
