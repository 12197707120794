/* 充值码 接口 */

import call from 'utils/call';

/***************************************************************/
export async function generateCode(amount) {
  console.log("生成充值码")
  return await call('wallet/generateCode', {amount: amount})
}

/***************************************************************/
export async function getWalletCode(page) {
  console.log("充值码列表")
  return await call('wallet/listCode', {page: page})
}