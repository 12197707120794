/* 客户信息接口 */

import call from 'utils/call' 

/***************************************************************/
export async function getAddressList(customer_id) {
  console.log("获得地址列表")
  return await call('customer/getAddressList/'+customer_id)
}

/***************************************************************/
export async function updateAddress(data) {
  console.log("更新地址")
  return await call('customer/updateAddress', data)
}

/***************************************************************/
export async function copyOrderAddress(data) {
  console.log("订单地址覆盖用户地址")
  return await call('customer/copyOrderAddress', data)
}

/***************************************************************/
export async function updateCustomer(data) {
  console.log("更新用户信息")
  return await call('customer/update', data)
}

/***************************************************************/
export async function getCustomerOrderList(customer_id) {
  console.log("获取用户订单列表")
  return await call('customer/getOrderList/'+customer_id)
}