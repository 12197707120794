import { Box, Chip, Divider, Stack, Typography } from "@mui/material"
import { openOrderDialog } from "components/order/dialog/OrderDialog"
import { name, address, postcode } from "utils/str"
import { editOrderInNewOrders } from "redux/newOrdersSlice"
import PrintChip from "components/order/status/PrintChip"
import HoldChip from "components/order/status/HoldChip"
import DriverChip from "components/order/status/DriverChip"
import FeatureChips from "components/order/status/FeatureChip"

export default function OrderListItem({ order }) {

  const handleClick = () => {
    openOrderDialog(order.id)
  }

  return (
    <>
    <Stack 
      direction='row' 
      spacing={1}
    >

      <Stack spacing={0} onClick={handleClick} style={{cursor: 'pointer'}} >
        <Typography>{name(order.name)} -  {address(order.city)} - {postcode(order.postcode)}</Typography>
        <Typography>{order.order_id}</Typography>
        <Typography>{order.datetime}</Typography>
        <Typography color="error">{order.note}</Typography>
      </Stack>

      <Box flexGrow={1}></Box>

      <Stack>
        <Stack direction='row-reverse' spacing={1}>
          <Chip label={'£'+order.amount} />
          <FeatureChips order={order} />
        </Stack>

        <Stack direction='row-reverse' spacing={1}>
          {order.logistics_id === '4' && <DriverChip order={order} callback={editOrderInNewOrders} />}
          {Number(order.printed) === 1 && <PrintChip order={order} callback={editOrderInNewOrders} />}
          <HoldChip order={order} callback={editOrderInNewOrders} />
        </Stack>
      </Stack>

    </Stack>
    <Divider />
    </>
  )
}