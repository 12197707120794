import { useState } from "react";
import { Alert, Button, Chip, ListItem, Stack, TextField } from "@mui/material";
import RSDialog from "rsui/RSDialog";
import { startLoading, stopLoading } from "utils/layout";
import { batchEmail, getEmailTemplates } from "api/email";
import { showSuccess } from "utils/RSUtils";

// 新建订单
export default function BatchNoticeChip() {

  const [openTemplate, setOpenTemplate] = useState(false)
  const [openEmail, setOpenEmail] = useState(false)
  const [templateList, setTemplateList] = useState([])
  const [email, setEmail] = useState()

  const openTemplateDialog = async () => {
    setOpenTemplate(true)
    const data = await getEmailTemplates()
    if(data) setTemplateList(data)
  }
  const closeTemplateDialog = () => setOpenTemplate(false)

  const select = (template) => {
    setEmail(template)
    closeTemplateDialog()
    openEmailDialog()
  }

  const openEmailDialog = () => setOpenEmail(true)
  const closeEmailDialog = () => setOpenEmail(false)

  const changeEmail = (e) => {
    setEmail({
      ...email,
      [`${e.target.key}`]: e.target.value
    })
  }

  const handleSendEmail = async () => {
    closeEmailDialog()
    startLoading()
    await batchEmail(email)
    stopLoading()
    showSuccess('群发完毕')
  }

  return (
    <>
    <Chip label="通知" onClick={openTemplateDialog} />

    <RSDialog
      title="邮件模板"
      open={openTemplate}
      onClose={closeTemplateDialog}
      maxWidth="sm"
      fullWidth
    >
      <Alert severity="warning">群发给已经有配送序号的订单</Alert>

      {templateList.map(template => 
        <ListItem key={template.id} sx={{ cursor: 'pointer'}} onClick={()=>select(template)}>
          {template.subject_cn} <br/>
          {template.subject_en}
        </ListItem>
      )}
    </RSDialog>

    <RSDialog
      title="群发通知"
      open={openEmail}
      onClose={closeEmailDialog}
      maxWidth="sm"
      fullWidth
      actions={<Button variant='contained' onClick={handleSendEmail}>群发</Button>}
    >
      {email && 
      <Stack>
        <TextField label="标题" value={email.subject_cn} key="subject_cn" onChange={changeEmail} />
        <TextField label="正文" multiline value={email.body_cn} key="body_cn" onChange={changeEmail} />
        <TextField label="Subject" value={email.subject_en} key="subject_en" onChange={changeEmail} />
        <TextField label="Body" multiline value={email.body_en} key="body_en" onChange={changeEmail} />
      </Stack>
      }
    </RSDialog>

    </>
  )
}