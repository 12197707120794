import MenuItems from "./MenuItems";
import { AllInboxOutlined } from '@mui/icons-material';

const menu = {
  "text": "库存管理",
  "icon": AllInboxOutlined,
  "permission": "staff",
  "sub": [
    {
      "text": "库存记录",
      "path": "/stock/records",
    },  
    {
      "text": "采购入库",
      "path": "/stock/purchase",
    },
    {
      "text": "保质期检查",
      "path": "/stock/expiry",
    },
    {
      "text": "供货商",
      "path": "/stock/supplier",
    }
  ]
}

export default function MenuItemStock() {
  return <MenuItems menu={menu} />
}