import { Stack } from '@mui/material'
import Postcode from '../components/Postcode'

export default function LocalArea() {

  return (
    <Stack spacing={2}>
      <Postcode title="红松鼠自送区" area={1} placeholder="Postcode 前半段" />
    </Stack>
  )
}