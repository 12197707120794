// 新订单列表

import { Grid, Stack } from "@mui/material"
import DPDOrderListCard from "pages/new-orders/components/DPD/DPDOrderListCard"
import UPSOrderListCard from "pages/new-orders/components/UPS/UPSOrderListCard"
import RSOrderListCard from "pages/new-orders/components/RS/RSOrderListCard"
import PickUpListCard from "pages/new-orders/components/Pick/PickUpOrderListCard"
import FedexOrderListCard from "pages/new-orders/components/Fedex/FedexOrderListCard"
import { useEffect } from "react"
import store from "redux/store"
import { setDrivers } from "redux/logisticsSlice"
import { getDrivers } from "api/logistics"
import { hasPermission } from "utils/layout"


export default function NewOrdersPage() {

  const fetchData = async () => {
    const data = await getDrivers()
    store.dispatch(setDrivers(data))
  }

  useEffect(() => {
    if(hasPermission('staff')) fetchData()
  }, [])
  
  return(
    <Grid container sx={{displayPrint: 'none'}}>
      <Grid item lg={6}>
        <Stack>
          <DPDOrderListCard />
          <FedexOrderListCard />
          <UPSOrderListCard />
        </Stack>
      </Grid>
      <Grid item lg={6}>
        <Stack>
          <RSOrderListCard />
          <PickUpListCard />
        </Stack>
      </Grid>
    </Grid>
  )
}