/* 产品接口 */
import call, { upload } from 'utils/call' 

/***************************************************************/
export async function addNewProduct() {
  await call('product/add')
}

/***************************************************************/
/**
 * 搜索产品
 * @param { object } data {page: int, include_hide: bool, keywords: string}
 */
export async function searchProduct(data) {
  return await call('product/search', data)
}

/***************************************************************/
/**
 * 筛选产品
 * @param { object } data {page: int, feature: string, shelf: string}
 */
export async function filterProduct(data) {
  return await call('product/filter', data)
}

/***************************************************************/
export async function updateProduct(data) {
  console.log('更新产品')
  await call('product/update', data)
}

/***************************************************************/
export async function recentSales(productID) {
  console.log('近期销售')
  return await call('product/sales/'+productID)
}

/***************************************************************/
export async function findProduct(productID) {
  console.log('某个产品信息')
  return await call('product/find/'+productID)
}

/***************************************************************/
export async function getProductTags(type, productID) {
  console.log('某个产品的标签')
  return await call('product/tags', {type: type, product_id: productID})
}

/***************************************************************/
export async function updateProductTags(data) {
  console.log('更新产品标签')
  return await call('product/updateTags', data)
}

/***************************************************************/
/* 产品图片
/***************************************************************/

export async function getProductImgs(data) {
  console.log('获得产品图片')
  return await call('product/imgs', data)
}

export async function uploadProductImg(data) {
  console.log('上传产品图片')
  return await upload('product/uploadImg', data)
}

export async function sortProductImgs(data) {
  console.log('上传产品图片')
  return await call('product/sortImgs', data)
}

export async function deleteProductImg(imgID) {
  console.log('删除产品图片')
  return await call('product/delImg/'+imgID)
}