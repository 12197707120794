import { useRef, useState } from "react";
import { Chip, Grid, IconButton, Table, TableBody, Typography } from "@mui/material";
import RSDialog from "rsui/RSDialog";
import RSCard from "rsui/RSCard";
import RSEditableTableRow from "rsui/RSEditableTableRow";
import { delAItem, updateAItem, updateFees } from "api/order";
import RSTable from "rsui/RSTable";
import RSTextEditor from "rsui/RSTextEditor";
import { Close } from "@mui/icons-material";
import RSLoader from "rsui/RSLoader";
import { showFail } from "components/snack/snack";
import { loadOrder } from "redux/orderSlice";
import { SearchStack } from "components/product/search/ProductSearchDrawer";
import { loadNewOrders } from "redux/newOrdersSlice";

// 编辑订单
export default function EditOrderChip({ order }) {

  const [open, setOpen] = useState(false)
  const [editOrder, setEditOrder] = useState()
  const loaderRef = useRef()
  
  const openDialog = () => {
    if(order.so_transaction_type === 'Cash') {
      setEditOrder(order)
      setOpen(true)
    } else showFail('此订单禁止编辑明细')
  }

  const closeDialog = () => {
    loadOrder(order.id)
    loadNewOrders()
    setOpen(false)
  }

  const add = async (product) => {
    const item = {
      so_id: order.id,
      qty: 1,
      name_cn: product.name_cn,
      product_id: product.id,
      price: product.price, // 单价
      weight: product.weight, // KG 单重
      vat: product.vat // 1 = 0%, 3 = 20%
    }
    loaderRef.current.startLoading()
    const data = await updateAItem(item)
    if(data) setEditOrder(data)
    loaderRef.current.stopLoading()
  }

  
 

  return (
    <>
      <Chip label="编辑" onClick={openDialog} />
      <RSDialog 
        open={open} 
        onClose={closeDialog}
        title="编辑订单"
        maxWidth="lg"
        fullWidth
      >
        { order && 
        <Grid container>
          <Grid item xs={6}>
            <ItemEditorCard order={editOrder} setEditOrder={setEditOrder}  />
          </Grid>
          <Grid item xs={3}>
            <SearchStack onClick={add} />
          </Grid>
          <Grid item xs={3}>
            <AmountEditorCard order={editOrder} setOrder={setEditOrder} />
          </Grid>
        </Grid>
        }
      </RSDialog>
      <RSLoader ref={loaderRef} />
    </>
  )
}

/*******************************************************************************/
// 产品编辑
function ItemEditorCard({order, setEditOrder}) {

  const update = async (data) => {
    await updateAItem(data)
  }

  const del = async (item) => {
    if(window.confirm("确定删除？")) {
      const newOrder = await delAItem(item) 
      if(newOrder) setEditOrder(newOrder)
    }
  }

  const columns = [
    {
      title: '图片',
      render: (item) => <img src={item.img} alt="" width="50" />,
    },
    {
      title: '产品',
      render: (item) => <Typography>#{item.product_id} {item.name_cn}</Typography>
    },
    {
      title: '单价',
      render: (item) => <RSTextEditor 
                          item={item}
                          entity="price"
                          callback={update}
                          prefix="£"
                        />
    },
    {
      title: '数量',
      width: '50px',
      render: (item) => <RSTextEditor 
                          item={item}
                          entity="qty"
                          callback={update}
                        /> 
    },
    {
      title: '',
      render: (item) => <IconButton onClick={()=>del(item)}><Close /></IconButton>
    }
  ];

  return (
    <>
    {order.items && <RSTable data={order.items} columns={columns} />}
    </>
  )
}

/*******************************************************************************/
// 金额编辑
function AmountEditorCard({order, setOrder}) {

  const update = (data) => {
    const updatedOrder = {
      ...order,
      ...data
    }
    setOrder(updatedOrder)
    updateFees(data)
  }

  return (
    <RSCard title="订单金额">
      <Table>
        <TableBody>
          <RSEditableTableRow 
            title="包装费"
            item={order}
            entity='pack_fee'
            callback={update}
            prefix="£"
          />
          <RSEditableTableRow 
            title="物流费"
            item={order}
            entity='shipping_fee'
            callback={update}
            prefix="£"
          />
          <RSEditableTableRow 
            title="折扣"
            item={order}
            entity='vip_discount'
            callback={update}
            prefix="-£"
          />
        </TableBody>
      </Table>
    </RSCard>
  )
}