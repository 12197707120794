import { today } from "utils/datetime";

const date = new Date()
const columns = [
  {
    title: 'delivery_country_code',
    render: () => ("GB"),
  },
  {
    title: 'delivery_post_code',
    dataIndex: 'postcode',
  },
  {
    title: 'delivery_organisation',
    dataIndex: 'name',
  },
  {
    title: 'delivery_addressline1',
    dataIndex: 'address_1',
  },
  {
    title: 'delivery_addressline2',
    render: (record) => ((record.address_2?record.address_2:'')+(record.address_3?', '+record.address_3:'')),
  },
  {
    title: 'delivery_addressline3',
    dataIndex: 'city',
  },
  {
    title: 'delivery_addressline4',
    dataIndex: 'county',
  },
  {
    title: 'delivery_instructions',
    render: () => ("Please leave parcel at door or reception many thx"),
  },
  {
    title: 'delivery_contact_name',
    dataIndex: 'name',
  },
  {
    title: 'delivery_contact_number',
    dataIndex: 'phone',
  },
  {
    title: 'notification_email',
    dataIndex: 'email',
  },
  {
    title: 'notification_sms',
    dataIndex: 'phone',
  },
  {
    title: 'number_of_parcels',
    dataIndex: 'box',
  },
  {
    title: 'total_weight',
    dataIndex: 'weight',
  },
  {
    title: 'network_code',
    render: () => (12),
  },
  {
    title: 'shipment_date',
    render: () => today('UK')
  },
  {
    title: 'customer_ref1',
    dataIndex: 'order_id',
  },
];

export default columns
