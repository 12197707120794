import { createSlice } from '@reduxjs/toolkit'
import { RSGet } from 'utils/RSRequest'

// 登录状态
export const slice = createSlice({
  name: 'orderRefund',
  initialState: {
    open: false,
    records: [], // 退款记录
  },
  reducers: {
    openOrderRefundDialog: (state) => {
      state.open = true
    },
    closeOrderRefundDialog: (state) => {
      state.open = false
    },
    setOrderRefundRecords: (state, action) => {
      state.records = action.payload
    }
  },
})

export const { 
  openOrderRefundDialog, 
  closeOrderRefundDialog, 
  setOrderRefundRecords,
} = slice.actions
export const orderRefundContent = state => state.orderRefund
export default slice.reducer

export const loadOrderRefunds = orderID => dispatch => {
  RSGet('refund/records/'+orderID, data => {
    dispatch(setOrderRefundRecords(data))
  })
}

