import { Typography, Stack } from '@mui/material'

// 收据 底部信息
export default function OrderReceiptFooter({ company }) {
  
  return (

    <Stack spacing={1} sx={{paddingX: '20px'}}>
      <Typography><b>Thank you for shopping with us.</b></Typography>
      <Typography variant='caption'>
        {company.name_en}<br/>
        VAT Reg NO. {company.vat}<br/>
        Tel: {company.tel}<br/>
        Email: {company.email}<br/>
        Address: {company.address}
      </Typography>

    </Stack>
  )
}