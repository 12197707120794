/* 客户订单 */
import { Chip, LinearProgress, Stack } from '@mui/material'
import RSDialog from "rsui/RSDialog"
import { useState } from "react"
import { getCustomerOrderList } from 'api/customer'
import OrderList from 'components/order/OrderList'


export default function CustomerOrders({ customer }) { 

  const [orders, setOrders] = useState([])
  const [open, setOpen] = useState(false)

  const openDialog = async () => {
    setOpen(true)
    const data = await getCustomerOrderList(customer.id)
    if(data) setOrders(data)
  }

  const closeDialog = () => {
    setOpen(false)
  }

  return (
    <>
    <Chip label="订单" onClick={openDialog} />
    <RSDialog 
      title="客户订单" 
      maxWidth="sm"
      open={open}
      onClose={closeDialog}
      fullWidth
    >
      <Stack>
        {!orders && <LinearProgress />}
        {orders && <OrderList orders={orders} onClick={closeDialog} />}
      </Stack>
    </RSDialog>
    </>
  )
}