import { updateProduct } from 'api/product'
import { useSelector } from 'react-redux'
import { closeProductEditDialog, productEditStates } from 'redux/productEditSlice'
import RSCard from 'rsui/RSCard'
import RSForm from 'rsui/RSForm'

/* 产品 信息 设置 */
export default function ProductEditInfo() {

  const { product, productEditCallback } = useSelector(productEditStates)

  let form = [
    {
      contents: [
        {
          type: 'input',
          name: 'price',
          label: '价格 £'
        },
        {
          type: 'picker',
          name: 'vat',
          label: 'VAT',
          options: [ // 和数据库同步 基本不会变化
            {name: '0%', value: 1},
            {name: '20%', value: 3},
          ]
        },
        {
          type: 'input',
          name: 'weight',
          label: '重量 KG'
        }
      ]
    },
    {
      contents: [{
        type: 'checkbox',
        name: 'frozen',
        label: '冷冻'
      },{
        type: 'checkbox',
        name: 'chilled',
        label: '冷藏'
      },{
        type: 'checkbox',
        name: 'adult',
        label: '成人'
      }]
    },
    {
      type: 'input',
      name: 'name_cn',
      label: '中文名称'
    },
    {
      type: 'input',
      name: 'name_en',
      label: '英文名称'
    },
    {
      type: 'note',
      name: 'details_cn',
      label: '中文简介'
    },
    {
      type: 'note',
      name: 'details_en',
      label: '英文简介'
    },
  ]

  const handleSave = async (data) => {
    await updateProduct({
      id: data.id,
      frozen: data.frozen,
      chilled: data.chilled,
      adult: data.adult,
      name_cn: data.name_cn,
      name_en: data.name_en,
      details_cn: data.details_cn,
      details_en: data.details_en,
      weight: data.weight,
      price: data.price,
      vat: data.vat
    })
    if(productEditCallback) productEditCallback()
    closeProductEditDialog()
  }

  return (
    <RSCard title={"产品信息  #"+product.id}>
      <RSForm 
        data={product} 
        fields={form}  
        onSave={handleSave}
      />
    </RSCard>
  )
}