import { Grid } from "@mui/material";
import WalletCodeList from "pages/promotion/wallet/components/WalletCodeList";

export default function Wallet() {

  return (
    <Grid 
      container
      justifyContent="center"
    >
      <Grid item xs={4}>
        <WalletCodeList />
      </Grid>
    </Grid>
  )
}