/* 系统用户菜单 */
import { useState } from 'react'
import { Avatar, Divider, IconButton, Menu, MenuItem } from '@mui/material'
import { auth } from 'utils/RSFirebase'
import { logout } from 'utils/auth';

function HeaderUserMenu() {

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>

    <IconButton  onClick={handleClick} size="small">
      <Avatar alt="admin user" src='/logo/avatar_108x108.png' />
    </IconButton>

    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
    >
      <MenuItem dense>{auth.currentUser.displayName}</MenuItem>
      <Divider />
      <MenuItem onClick={logout} dense>登出系统</MenuItem>
    </Menu>
    
    </>
  )
}

export default HeaderUserMenu