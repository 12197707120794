/* 产品搜索按钮 */
/* Button + Drawer */
import { useState, useEffect } from 'react'
import { Drawer, Pagination, Stack, TextField } from '@mui/material'
import { SearchOutlined } from "@mui/icons-material"

// Redux
import { useSelector } from 'react-redux'
import OrderList from 'components/order/OrderList'
import { appStates } from 'redux/AppSlice'
import { closeOrderSearchDrawer } from 'utils/layout'
import { searchOrder } from 'api/order'

export function ProductSearchIconButton() {return(<></>)} // 要删
export function openProductSearchDrawer () {} // 过渡 要删

/**
 * 全局 订单搜索
 * @returns 
 */
export default function OrderSearchDrawer() {

  const { openOrderSearch } = useSelector(appStates)
  
  return (
    <Drawer 
      anchor={'right'}
      open={openOrderSearch}
      onClose={closeOrderSearchDrawer}
    >
      <Stack sx={{width: 300}} pb={2} m={1}>
        <SearchStack />
      </Stack>
    </Drawer>
  )
}

function SearchStack() {

  const label = "订单搜索"
  const [keywords, setKeywords] = useState('')
  const [result, setResult] = useState([])
  const [pages, setPages] = useState(1)
  const [page, setPage] = useState(1)

  const changeKeywords = (event) => {
    setKeywords(event.target.value)
  }

  const handlePressEnter = (event) => {
    if(event.key === 'Enter'){
      if(keywords && keywords.length >= 1) {
        setPage(1)
        search()
      }
    }
  }

  const search = async () => {
    if(keywords === '') return;
    const data =await searchOrder({keywords: keywords.trim(), page: page, pagesize: 10})
    if(data) {
      setResult(data.result)
      setPages(data.pages)
    }
  }

  const changePage = (e, p) => {
    setPage(p)
  }

  useEffect(() => {
    search()
  }, [page])

  return (
    <Stack spacing={1}>
        
      <TextField
        label={label}
        fullWidth
        margin="dense"
        value={keywords}
        onChange={changeKeywords}
        onKeyDown={handlePressEnter}
        InputProps={{
          endAdornment: <SearchOutlined color="action" />
        }}
      />

      {result && <OrderList orders={result} />}

      {pages > 1 && <Pagination count={pages} onChange={changePage} page={page} />}

    </Stack>
  )
}