import RSCard from 'rsui/RSCard'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

// **************************************************************************
// 销售表 柱状图

export default function SalesChart({ title, data }) {

  return (
    <RSCard title={title}>
      <Bar data={data} />
    </RSCard>
  )
}