import { Button, Chip, Stack, Typography } from '@mui/material'
import RSTable from 'rsui/RSTable';
import RSDialog from 'rsui/RSDialog';
import { useEffect, useState } from 'react';
import { newOrdersStates, setNewOrders, setOpenAddress } from 'redux/newOrdersSlice';
import { useSelector } from 'react-redux';
import store from 'redux/store';
import RSTextEditor from "rsui/RSTextEditor"
import { updateOrder } from 'api/order';

export default function RSAddressChip({orders, driver}) {

  const { newOrders } = useSelector(newOrdersStates)
  const { openAddress } = useSelector(newOrdersStates)
  const [ noHoldingOrders, setNoHoldingOrders ] = useState([])

  const columns = [
    {
      title: '#',
      render: (order) => <RSTextEditor
                            item={order}
                            entity="sort"
                            text={order.sort}
                            width="4rem"
                            callback={updateSort}
                         />
    },
    {
      title: 'Name',
      dataIndex: 'name'
    },
    {
      title: 'Mobile',
      dataIndex: 'phone'
    },
    {
      title: 'Address',
      render: (order) => (
      <>
        {order.address_1}<br/>
        {order.address_2 && <>{order.address_2}<br/></>}
        {order.address_3 && <>{order.address_3}<br/></>}
        {order.city} {order.county}
      </>
      )
    },
    {
      title: 'Postcode',
      dataIndex: 'postcode'
    },
    {
      title: 'Box',
      dataIndex: 'box'
    }
  ]
  
  // 更新序号
  const updateSort = (updatedItem) => {
    updateOrder(updatedItem)
    const newData = noHoldingOrders.map(order => {
      if(order.id === updatedItem.id) {
        return {
          ...order,
          ...updatedItem
        }
      } else return order
    })
    newData.sort((a,b) => Number(a.sort) - Number(b.sort))
    setNoHoldingOrders(newData)
  }
  

  const openDialog = () => {
    store.dispatch(setOpenAddress(true))
  }

  const closeDialog = () => {
    store.dispatch(setOpenAddress(false))

    // 更新列表数据
    const lookup = {};
    noHoldingOrders.forEach(item => {
      lookup[item.id] = item.sort;
    });

    const newData = newOrders.map(order => ({
        ...order,
        sort: lookup[order.id] || order.sort
      })
    )
    store.dispatch(setNewOrders(newData))
  }

  useEffect(() => {
    const tempOrders = []
    orders.forEach(order => {
      if(order.hold === '0') {
        tempOrders.push(order) 
      }
    });
    tempOrders.sort((a,b) => Number(a.sort) - Number(b.sort))
    setNoHoldingOrders(tempOrders)
  }, [orders])

  const print = () => window.print()

  const Actions = () => (
    <Stack direction="row" spacing={1}>
      <Button variant='contained' onClick={print}>打印</Button>
    </Stack>
  )

  return (
    <>
      {columns && <Chip label="地址" onClick={openDialog} />}
      <RSDialog
        title={"红松鼠自送地址列表(暂停的不显示) "+(driver?driver.label:"")}
        open={openAddress}
        onClose={closeDialog}
        fullScreen
        actions={<Actions />}
      >
        {driver && <Typography variant='h5'>{driver.label} - 路线 {driver.route}</Typography>}
        {noHoldingOrders && columns && <RSTable data={noHoldingOrders} columns={columns} />}
      </RSDialog>
    </>
  )
}