/* 物流接口 物流设置 */

import call from 'utils/call' 

/***************************************************************/
export async function getDrivers() {
  console.log("获取司机列表")
  return await call('logistics/getDrivers')
}

/***************************************************************/
export async function listPostcode(area_id) {
  console.log("邮编列表")
  return await call('logistics/listPostcode/'+area_id,)
}

/***************************************************************/
export async function addPostcode(data) {
  console.log("添加邮编")
  await call('logistics/addPostcode', data)
}

/***************************************************************/
export async function delPostcode(data) {
  console.log("删除邮编")
  await call('logistics/delPostcode', data)
}