import { Chip, Tooltip } from '@mui/material'

export default function CustomerChips({ customer }) {

  return (
    <>
    <LevelChip customer={customer} />
    <VIPChip customer={customer} />
    </>
  )
}


// 客户等级
const LevelChip = ({ customer }) => <Tooltip title={'加入时间 '+customer.create_date}>
  <Chip color="info" label={'LV ' + customer.lvl} />
</Tooltip>

// VIP等级
const VIPChip = ({ customer }) => <>
  {parseInt(customer.vip) > 0 && 
  <Tooltip title={'到期时间 '+customer.vip_expiry_date}>
    <Chip color="error" label={'VIP ' + customer.vip} />
  </Tooltip>
  }
</>
