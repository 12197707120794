import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({
  name: 'agentChatBox',
  initialState: {
    conversations: null,
    conversation: null
    
  },
  reducers: {
    setConversations:(state, action) => {
      state.conversations = action.payload
    },
    setConversation:(state, action) => {
      state.conversation = action.payload
    },

  },
})

export const { 
  setConversations,
  setSelectedCustomer
} = slice.actions

export const agentChatBoxStates = state => state.agentChatBox

export default slice.reducer