import { faGoogle, faWeixin } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function CustomerTypeIcon({ type }) {
  switch(type) {
    case 'Wechat':
      return <FontAwesomeIcon icon={faWeixin} />
    
    case 'Gmail':
      return <FontAwesomeIcon icon={faGoogle} />

    default:
      return ''
  }
}