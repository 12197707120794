import { useState, useEffect } from 'react'
import { getTasks, updateLatLng } from 'api/delivery'
import { Grid, Stack } from '@mui/material'
import TaskCard from './components/TaskCard'
import { hasPermission, showFail } from 'utils/layout'

// 配送任务列表
export default function LondonDeliveryPage() {

  const [tasks, setTasks] = useState()

  const fetchData = async () => {
    const data = await getTasks()
    if(data) setTasks(data)
  }

  const getLocation = () => {
    if( hasPermission('driver') ) {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(async function(position) {
          const lat = position.coords.latitude
          const lng = position.coords.longitude
          await updateLatLng({lat:lat, lng:lng})
        }, function(error) {
          showFail("获取位置信息时出错:", error);
        });
      } else {
        showFail("您的浏览器不支持Geolocation");
      }
    }
  };

  useEffect(() => {
    fetchData()

    // 初始获取位置
    getLocation();

    // 设置每分钟获取一次位置
    const intervalId = setInterval(getLocation, 60000);

    // 在组件卸载时清除定时器
    return () => clearInterval(intervalId);
  }, [])



  

  return ( 
    <Grid container justifyContent="center">
      <Grid item xs={12} lg={4}>
        <Stack>
          {tasks && tasks.map((item, index) => (
            <TaskCard task={item} key={index} reloadList={fetchData}/>
          ))}
        </Stack>
      </Grid>
    </Grid>

  )
}