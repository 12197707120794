
import { Box, Chip, Stack, Typography } from '@mui/material'
import RSCard from 'rsui/RSCard';

export default function VersionController({ app, version, update }) {
 
  return (
    <RSCard title={app}>
       <Stack direction="row" alignItems='center'>
        <Typography variant='body2'>{version}</Typography>
        <Box flexGrow={1} />
        <Chip label="更新" onClick={update} />
      </Stack>
    </RSCard>
   
  )
}