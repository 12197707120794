import { useEffect, useRef, useState } from "react"
import OrderReceipt from "pages/print/order-receipt/components/OrderReceipt"
import { getReceipt } from "api/order"
import { useParams } from "react-router-dom"
import RSLoader from "rsui/RSLoader"
import { getAuth, onAuthStateChanged } from "firebase/auth"

export default function ReceiptPage() {
  const auth = getAuth()
  const { order_id } = useParams()
  const [receipt, setReceipt] = useState()
  const ref = useRef()

  const fetchData = async () => {
    const res = await getReceipt(order_id)
    if(res) {
      setReceipt(res)
      setTimeout(() => {
        window.print()
        window.close()
      }, 500)
    }
    ref.current.stopLoading()
  }

  const handleAuthStateChanged = async (user) => {
    if(user) {
      fetchData()
    } 
  }

  useEffect(() => {
    console.log('开始监听登录状态')
    ref.current.startLoading()
    const unsubscribe = onAuthStateChanged(auth, handleAuthStateChanged);
    return () => unsubscribe();
  }, [])

  return (
    <>
    {receipt && <OrderReceipt receipt={receipt} />}
    <RSLoader ref={ref} />
    </>
  )
}