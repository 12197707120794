import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { lightBlue } from '@mui/material/colors'
import { Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText, useMediaQuery} from '@mui/material';

import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { appStates, setSiderItemSelected, setSiderMenuSelected, setSiderOpen } from 'redux/AppSlice';
import { useTheme } from '@emotion/react';


const iconStyle = { 
  color:'gray', 
  fontSize: 19 
}

const textStyle = {
  color: 'gray',
  fontSize: 14,
  fontWeight: 'medium',
  letterSpacing: 0,
}

export function MenuItem({menu, secondaryAction}) {
  const { siderItemSelected } = useSelector(appStates)
  const [selected, setSelected] = useState(false)
  const dispatch = useDispatch()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    setSelected(menu.text === siderItemSelected)
  }, [siderItemSelected])

  const handleClick = () => {
    if(matches) {
      dispatch(setSiderOpen(false))
    }
    dispatch(setSiderItemSelected(menu.text))
  }

  const myTextStyle = {
    ...textStyle,
    color: selected?lightBlue[500]:'gray',
  }
  
  const myIconStyle = {
    ...iconStyle,
    color: selected?lightBlue[500]:'gray',
  }

  return (
    <ListItem disablePadding secondaryAction={secondaryAction}>
      <ListItemButton 
        selected={selected} 
        to={menu.path} 
        onClick={handleClick} 
        component={Link}
      >
        <ListItemIcon sx={{minWidth: 35}}>
          {!menu.icon && <ChevronRightOutlinedIcon sx={myIconStyle}/>}
          {menu.icon && <menu.icon sx={myIconStyle} />}
        </ListItemIcon>
        <ListItemText primary={menu.text} primaryTypographyProps={myTextStyle} />
      </ListItemButton>
    </ListItem>
  )
}

// MenuItem with submenu
export default function MenuItems({menu}) {
  
  const { siderMenuSelected } = useSelector(appStates)
  const dispatch = useDispatch()

  const handleClick = () => {
    if(siderMenuSelected === menu.text) dispatch(setSiderMenuSelected(null))
    else dispatch(setSiderMenuSelected(menu.text))
  }

  return (
    <>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon sx={{minWidth: 35}}>
          <menu.icon sx={iconStyle} />
        </ListItemIcon>
        <ListItemText primary={menu.text} primaryTypographyProps={textStyle} />
        {siderMenuSelected === menu.text ? <ExpandLess sx={iconStyle} /> : <ExpandMore sx={iconStyle} />}
      </ListItemButton>
    
      <Collapse in={siderMenuSelected === menu.text}>
        <List disablePadding>
          { menu.sub.map((m, index) => <MenuItem menu={m} key={"sub-"+index} />)}
        </List>
      </Collapse>
    </>
  )
}
