import { useState, useEffect } from 'react'
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined'
import RSDialog from 'rsui/RSDialog'
import DashboardPanel from '../DashboardPanel'
import { getAbnormalProducts } from './api'
import ProductList from 'pages/product/management/components/ProductList'

/* 异常订单数量 */
export default function AbnormalProducts() {
  const [ open, setOpen ] = useState( false )
  const [ products, setProducts] = useState([])

  const fetchData = async () => {
    const data = await getAbnormalProducts()
    if(data) setProducts(data)
  }

  useEffect(() => {
   fetchData()
  }, [])

  const openDialog = () => {
    setOpen(true)
  }

  const closeDialog = () => {
    setOpen(false)
  }

  return (
    <>
      {products.length > 0 &&
      <DashboardPanel 
        icon={ <LiveHelpOutlinedIcon /> }
        label="异常库存" 
        num={products.length}
        color="error" 
        onClick={openDialog}
      />
      }

      <RSDialog
        title="异常库存" 
        open={open}
        onClose={closeDialog}
        noContentPadding
        fullWidth
        maxWidth="xl"
      >
        {products && <ProductList products={products} />}
      </RSDialog>
    </>
  )
}


