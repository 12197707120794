import { Divider, Typography } from "@mui/material"
import { deepCopyArray } from "utils/common"
import { address, name, postcode } from "utils/str"

export default function RSLabel({ order, driver }) {

  let item = []
  let obj = deepCopyArray(order)
  for(let i = 1; i <= obj.box; i++) {
    obj.box_index = i+'/'+obj.box
    item.push(obj)
  }

  return (
    <>
    {
      item.map((order, index) => 
      <div>
      <div key={order.id+"-"+index} style={{width:'4in', height:'4in', border: '1px solid #000', padding: '10px'}}>

        <div style={{float:'right'}}>
          <Typography variant='h3' align='right'>{postcode(order.postcode)}</Typography>
          <Typography variant='subtitle1' align='right'>{order.order_id}</Typography>
          <Typography variant='h6' align='right'>{order.box_index}</Typography>
        </div>

        <Typography variant='h3'>{driver.route}-{order.sort}</Typography>

        <Typography variant="subtitle1" mt={1}>
          <b>
          {name(order.name)}<br/>
          {order.phone}<br/><br/>
          {address(order.address_1)}<br/>
          {order.address_2 && <>{address(order.address_2)}<br/></>}
          {order.address_3 && <>{address(order.address_3)}<br/></>}
          {address(order.city)} {address(order.county)}
          </b>
        </Typography>

        <Divider style={{marginTop:10, marginBottom:10}} />

        <Typography variant="subtitle2">
          <b>
            Responsible Delivery<br/>
            Red Squirrel Shop<br/>
            02081917948<br/>
            customer.service@redsquirrel.shop
          </b>
        </Typography>
       
      </div>
      {(index < (item.length - 1)) && <div style={{pageBreakAfter: 'always'}}></div> }
      </div>
      )
    }
    </>
  )
}