import { Typography, Stack } from '@mui/material'
import RSCard from 'rsui/RSCard'
import { sumVouchers } from '../api'
import { useEffect, useState } from 'react'

// 代金券 列表
export default function VoucherSum() {
  
  const [count, setCount] = useState(0)
  const [sum, setSum] = useState(0)

  const fetchData = async () => {
    const data = await sumVouchers()
    if(data) {
      setCount(data.count)
      setSum(data.sum)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
  <RSCard title="代金券统计" >
    <Stack>
      <Typography>未使用总数：{count}</Typography>
      <Typography>未使用总金额：£{sum}</Typography>
    </Stack>
  </RSCard>
  )
}