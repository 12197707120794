import axios from 'axios'
import Qs from 'qs'
import fileDownload from 'js-file-download'
import { auth } from './RSFirebase'

import store from 'redux/store'
import { showMessage } from 'components/snack/snackSlice'
import { Req } from './RSRequest'
import Store from 'redux/store'
import { setProduct, setDialog as setProductEditDialog } from 'redux/ProductSlice'

const api = "https://api.redsquirrel.shop/admin/"

export function Get(uri, callback) {
  const url = api+uri
  axios.get(url)
    .then(res => {
      const data = res.data
      RSLog('Get: '+url)
      RSLog(data)
      callback(data)
    })
    .catch(function (error) {
      RSLogError(error)
      callback(false)
    })
}

export function Post(uri, data, callback) {
  const url = api+uri
  const headers = {'Content-Type': 'application/json'}

  axios.post(url, Qs.stringify(data), headers)
  .then(res => {
    const data = res.data
    RSLog('Post: '+url)
    RSLog(data)
    callback(data)
  })
  .catch(function (error) {
    RSLogError(error)
    callback(false)
  })
}

// Request Get
export function RSGet(uri, callback) {
  RSPost(uri, {}, callback)
}

// Request Post
export function RSPost(uri, postData, callback) {
  auth.currentUser.getIdToken().then((idToken) =>{
    const data = { 
      idToken: idToken, 
      ...postData
    }
    Post(uri, data, callback)
  })
}

export function RSUpload(uri, data, callback) {
  auth.currentUser.getIdToken().then((idToken) =>{
    const url = api+uri
    data.append("idToken", idToken)
    const headers = { 
      "Content-Type": "multipart/form-data",
    }
    console.log('Upload: '+url)
    axios.post(url, data, headers).then(callback)
  })
}

export function RSDownload(uri, data, filename) {
  auth.currentUser.getIdToken().then((idToken) =>{
    const url = api+uri
    const postdata = { ...data, idToken: idToken }
    RSLog('Download '+url)
    RSLog(Qs.stringify(postdata))
    axios.post(url, Qs.stringify(postdata), {responseType: 'blob'})
    .then(res => {
      fileDownload(res.data, filename)
    })
  })
}

function RSLogError(error) {
  RSLog('出错')
  if(error.response) {
    RSLog(error.response.data)
    //RSLog(error.response.status)
    //RSLog(error.response.headers)
  } else if (error.request) {
    RSLog(error.request);
  } else {
    RSLog(error.message);
  }
  //RSLog(error.config);
}

// 覆盖console.log 生产模式下不log
export function RSLog(msg) {
  if (process.env.NODE_ENV === 'production') return;
  console.log(msg);
}

// 空格 变 - 变小写 用于URL显示
export function RSURIEncode(uri) {
  if(uri) {
    uri = uri.replace(/ /g, "-")
    uri = uri.toLowerCase()
    return uri
  }
}

// 返回原有样式
export function RSURIDecode(uri) {
  uri = uri.replace(/-/g, " ")
  uri = uri.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
  return uri
}

export function toast(status, message) {
  store.dispatch(showMessage(status, message))
}


// 地址 字符串 格式化
export function strFormat(str, type) {
  if(type === 'address' || type === 'name') {
    str = str.toLowerCase()
    const arr = str.split(" ");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    return arr.join(" ");
  } else if (type === 'postcode') {
    return str.toUpperCase()
  } else return str
  
}

/**************************************************************************/
// 是否有权限
export function hasPermission(permission) {
  const permissions = store.getState().app.permissions
  if(permissions && permissions.includes(permission)){
    return true
  } else {
    return false
  }
}


/**************************************************************************/
// 右下角提醒
export function showSuccess(msg) {
  store.dispatch(showMessage('success', msg))
}

export function showFail(msg) {
  store.dispatch(showMessage('error', msg))
}

/**************************************************************************/
// 全局方法 编辑产品信息
export function editProduct(productID) {
  console.log('Edit product '+productID)
  Req('product/edit/'+productID)
    .then(data => {
      Store.dispatch(setProduct(data))
      Store.dispatch(setProductEditDialog(true))
    })
}