
import {  Button, LinearProgress, Stack, Typography } from '@mui/material'
import { useState } from 'react';
import call from 'utils/call' 


export default function ProductData() 
{
  const [res, setRes] = useState('')
  const [loading, setLoading] = useState(false)

  const getDiscountData = async () => {
    setLoading(true)
    const data = await call('test/getProductsDiscount')
    if(data) setRes(data)
    setLoading(false)
  }

  return (
  <Stack>
    <Stack direction="row">
      <Stack spacing={0}>
        <Typography>旧版折扣数据手动同步给新版</Typography>
        <Typography variant='caption'>旧版 discount, tag 表里拿数据给 新版 product 补数</Typography>
      </Stack>
      <Button onClick={getDiscountData} variant='contained' disabled={loading}>开始</Button>
    </Stack>
    {loading && <LinearProgress />}
    <Typography>{res}</Typography>
  </Stack>
  )
}