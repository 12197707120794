import { CheckRounded } from "@mui/icons-material"
import { Chip } from "@mui/material"
import { useEffect, useState } from "react"

/**
 * Chip 勾选项
 * @param { boolean } checked 默认是否已经勾选
 * @param { string } label 标签
 * @param { function } onClick 点击方法
 * @returns 
 */
export default function RSCheckChip({ checked, label, onClick })
{

  const [ ticked, setTicked ] = useState(false)

  useEffect(() => {
    setTicked(checked)
  }, [])

  const handleClick = () => {
    const newTicked = !ticked
    setTicked(newTicked)
    onClick(newTicked)
  }

  return (
    <>
    { 
    ticked ? 
    <Chip label={label} onClick={handleClick} icon={<CheckRounded />} />
    :
    <Chip label={label} onClick={handleClick} variant="outlined" />
    }
    </>
  )
}