import { Chip } from "@mui/material";
import { useSelector } from "react-redux";
import { orderStates } from "redux/orderSlice";
import { adminSite } from "utils/common";

export default function ReceiptChip() {

  const { order } = useSelector(orderStates)

  const print = () => {
    window.open(adminSite()+'/print/order-receipt/'+order.id);
  }

  return (<Chip label="收据" onClick={print} />)
}