import { configureStore } from '@reduxjs/toolkit'
import CustomerOrdersSlice from './CustomerOrdersSlice'
import OrderRefundSlice from './OrderRefundSlice'
import OrderSlice from './orderSlice'
import OrdersSlice from './OrdersSlice'
import PermissionSlice from './PermissionSlice'
import TrackingSlice from './TrackingSlice'
import BannderSlice from './BannerSlice'
import ProductSlice from './ProductSlice'
import SnackSlice from 'components/snack/snackSlice'
import ProductSearchSlice from './ProductSearchSlice'
import AppSlice from './AppSlice'
import SearchSlice from './SearchSlice'
import AgentChatBoxSlice from 'components/chatbox/agent/AgentChatBoxSlice'
import ChatBoxSlice from 'components/chatbox/common/ChatBoxSlice'
import newOrdersSlice from 'redux/newOrdersSlice'
import logisticsSlice from './logisticsSlice'
import productEditSlice from './productEditSlice'
import tagSlice from './tagSlice'

// Promotion
import voucherSlice from 'pages/promotion/voucher/slice'
import couponSlice from 'pages/promotion/coupon/slice'

export default configureStore({
  reducer: {
    app: AppSlice,

    productEdit: productEditSlice, // 产品编辑
    tag: tagSlice,
    // layout
    snack: SnackSlice,

    newOrders: newOrdersSlice, // 新订单列表


    // Promotion
    voucher: voucherSlice,
    coupon: couponSlice,

    search: SearchSlice,
    orders: OrdersSlice,
    order: OrderSlice,
    orderRefund: OrderRefundSlice,
    CustomerOrders: CustomerOrdersSlice,
    Tracking: TrackingSlice,
    Permission: PermissionSlice,

    banner: BannderSlice,
    product: ProductSlice,
    productSearch: ProductSearchSlice,
    
    agentChatBox: AgentChatBoxSlice,
    chatBox: ChatBoxSlice,

    logistics: logisticsSlice, // 物流数据
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
})