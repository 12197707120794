import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import MenuItems from "./MenuItems";

const menu = {
  "text": "产品信息",
  "icon": CategoryOutlinedIcon,
  "sub": [
    {
      "text": "产品管理",
      "path": "/product/management"
    },  
    {  
      "text": "产品标签",
      "path": "/product/tag"
    }
  ]
}

export default function MenuItemProduct() {

  return <MenuItems menu={menu} />
}