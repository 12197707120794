import RSSelector from "rsui/RSSelector"

// 产品筛选 - 产品特殊类别
export default function FeatureFilter ({ defaultKey, callback }) {

  const options = [
    {key: 0, value: '全部'},
    {key: 1, value: '仅冷冻'},
    {key: 2, value: '仅冷藏'},
    {key: 3, value: '仅成人'},
    {key: 4, value: '仅打折'}
  ]

  return <RSSelector 
            name="筛选A"
            selectedKey={defaultKey} 
            options={options}
            onSelect={callback} 
         />
}