/* 代金券 页面 */

import { Grid, Stack } from '@mui/material'
import VoucherTemplate from './components/VoucherTemplate'
import VoucherList from './components/VoucherList'
import VoucherSum from './components/VoucherSum'
import VoucherNote from './components/VoucherNote'

export default function Voucher() {

  return(
  <Grid container spacing={2}>
    
    <Grid item lg={4}>
      <VoucherTemplate />
    </Grid>

    <Grid item lg={6}>
      <VoucherList /> 
    </Grid>

    <Grid item lg={2}>
      <Stack>
        <VoucherSum />
        <VoucherNote />
      </Stack>
    </Grid>

  </Grid>
  )
}