import { useState, useEffect } from "react"
import { Grid, Stack } from "@mui/material"
import SalesTable from "./SalesTable"
import SalesChart from "./SalesChart"
import { MonthMenu, YearMenu } from "rsui/RSMenu"
import { salesChart, salesTable } from "api/analytics"

// 日销售
export default function DailySales () {
  const date = new Date()
  const [year, setYear] = useState(date.getFullYear())
  const [month, setMonth] = useState(date.getMonth() + 1)
  const [data, setData] = useState([])
  const [chartData, setChartData] = useState(null)

  useEffect(() => {
    loadData()
    // eslint-disable-next-line
  }, [year, month])

  const loadData = async () => {
    if(year && month) {
      const res = await salesTable({type: 3, year: year, month: month})
      if(res) setData(res)
      const res2 = await salesChart({type: 3, year: year, month: month})
      if(res2) setChartData(res2)
    }
  }

  const yearCallback = year => setYear(year)
  const monthCallback = month => setMonth(month)

  const Actions = () => 
  <Stack spacing={1} direction="row">
    <YearMenu year={year} callback={yearCallback} />
    <MonthMenu month={month} callback={monthCallback} />
  </Stack>
  

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <SalesTable title="日销售" data={data} action={<Actions />} />
      </Grid>
      <Grid item xs={6}>
        {chartData && <SalesChart title="日销售" data={chartData} />}
      </Grid>
    </Grid>
  )
}