import dayjs from "dayjs";

export function today(country = '') {
  if(country === 'UK') return dayjs().format('DD/MM/YYYY')
  else return dayjs().format('YYYY-MM-DD')
}

export function date(string = '', country = '') {
  let date = null

  if(string) date = dayjs(string)
  else date = dayjs()

  if(country === 'UK') date = date.format('DD/MM/YYYY')
  else date = date.format('YYYY-MM-DD')

  return date
}

