import { useEffect } from 'react'
import { Chip, Stack, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { getAllCoupons } from '../api';
import { couponStates, openCouponEditor, setCoupons } from '../slice';
import RSCard from 'rsui/RSCard';
import RSTable from 'rsui/RSTable';
import CouponEditor from './CouponEditor';

export default function CouponList() {

  const { coupons } = useSelector(couponStates)
  const dispatch = useDispatch()

  const fetchData = async () => {
    const data = await getAllCoupons()
    if(data) dispatch(setCoupons(data))
  }

  useEffect(()=>{
    fetchData()
  }, [])

  const CouponCode = ({coupon}) => 
  <Stack spacing={0}>
    <Typography>{coupon.code}</Typography>
    <Typography variant='caption'>{'满 £'+Number(coupon.condition).toFixed(0)+' 减 £'+Number(coupon.deduction).toFixed(0)}</Typography>
  </Stack>

  const EditChip = ({ coupon }) => <Chip label="编辑" onClick={()=>openCouponEditor(coupon)} />

  const AddNewChip = () => <Chip label="新建" onClick={()=>openCouponEditor({})} />

  const columns = [
    {
      title: '代金券',
      render: (coupon) => <CouponCode coupon={coupon} />,
    },
    {
      title: '有效期',
      dataIndex: 'expiry_date',
    },
    {
      title: '备注',
      dataIndex: 'note',
    },
    {
      title: '',
      render: (coupon) => <EditChip coupon={coupon} />,
    },
  ]

  return (
    <RSCard
      title="优惠码"
      action={<AddNewChip />}
    >
      <RSTable data={coupons} columns={columns} />
      <CouponEditor />
    </RSCard>
  )
}