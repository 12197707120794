import { Chip } from '@mui/material'
import { adminSite } from 'utils/common'


export default function RSLabelChip({ driverID }) {


  const print = () => {
    window.open(adminSite()+'/print/order-label/'+driverID)
  }


  return <Chip label="贴条" onClick={print} />
}

