import { useNavigate } from 'react-router-dom'
import { AllInboxOutlined } from "@mui/icons-material"
import DashboardPanel from './DashboardPanel'

/* Dashboard 产品库存调整 Button */
export default function StockRecordsButton() {

  const navigate = useNavigate()

  const handleClick = () => navigate("/stock/records")

  return (
    <DashboardPanel 
      label="产品库存调整" 
      icon={<AllInboxOutlined />}
      onClick={handleClick}
      num={null}
    />
  )
}