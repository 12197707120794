import { useEffect, useRef, useState } from "react"
import { viewOrder } from "api/order"
import { useParams } from "react-router-dom"
import RSLoader from "rsui/RSLoader"
import OrderPicker from "pages/print/order-picker/components/OrderPicker"
import { getAuth, onAuthStateChanged } from "firebase/auth"

// 抓单打印页
export default function PickerPage() {
  const auth = getAuth()
  const { order_id } = useParams()
  const [order, setOrder] = useState()
  const ref = useRef()

  const fetchData = async () => {
    const res = await viewOrder(order_id)
    if(res) {
      setOrder(res)
      setTimeout(() => {
        window.print()
        window.close()
      }, 500)
    }
    ref.current.stopLoading()
  }

  const handleAuthStateChanged = async (user) => {
    if(user) {
      fetchData()
    } 
  }

  useEffect(() => {
    console.log('开始监听登录状态')
    ref.current.startLoading()
    const unsubscribe = onAuthStateChanged(auth, handleAuthStateChanged);
    return () => unsubscribe();
  }, [])

  return (
    <>
    {order && <OrderPicker order={order} />}
    <RSLoader ref={ref} />
    </>
  )
}