import { TableRow, TableCell } from '@mui/material'
import RSTextEditor from './RSTextEditor'

// 可编辑的表单行, 有两列
export default function RSEditableTableRow(props) {

  const { title, ...rest } = props

  return (
    <TableRow>
      <TableCell><b>{title}:</b> </TableCell>
      <TableCell>
        <RSTextEditor 
          {...rest} 
        />
      </TableCell>
    </TableRow>
  )
}