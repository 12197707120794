import { Checkbox, FormControlLabel, Typography } from "@mui/material"
import { useEffect, useState } from "react"

const RSCheckbox = (props) => {

  const { label, defaultChecked, onChange } = props
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    //console.log(defaultChecked) 
    if(defaultChecked) {
      setChecked(true)
    }
    // eslint-disable-next-line
  }, [])

  const handleChange = () => {
    const v = !checked
    setChecked(v)
    onChange(v)
  }

  return (
    <FormControlLabel 
      control={
        <Checkbox 
          checked={checked} 
          onChange={handleChange} 
          size="small"
        />
      } 
      label={<Typography variant="body2">{label}</Typography>}
    />
  )
}

export default RSCheckbox