import { Stack } from "@mui/material";
import { AdultChip, ChilledChip, FrozenChip } from "components/product/ProductChips";


export default function FeatureChips({ order }) {

  return (
    <Stack direction='row-reverse' spacing={1}>
      {Number(order.adult) === 1 && <AdultChip />}
      {Number(order.frozen) === 1 && <FrozenChip />}
      {Number(order.chilled) === 1 && <ChilledChip />}
    </Stack>
  )
}