import { Button, Chip, Stack, TextField } from "@mui/material";
import { updateEmailTemplate } from "api/email";
import { useEffect, useState } from "react";
import RSCard from "rsui/RSCard";
import { language } from "utils/str";

export default function TemplateEdit({ template, callback }) {

  const [lang, setLang] = useState('cn')
  const [email, setEmail] = useState()
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    if(template) setEmail(template)
  }, [template])

  const handleChange = (e) => {
    const entity = e.target.name
    const value = e.target.value

    setEmail({
      ...email,
      [`${entity}`] : value
    })
  }

  const save = async () => {
    setSaving(true)
    await updateEmailTemplate(email)
    await callback()
    setSaving(false)
  }

  const Actions = () => 
  <Stack direction="row" spacing={1}>
    <Chip label={language('cn')} color={lang==='cn'?'primary':'default'} onClick={()=>setLang('cn')} />
    <Chip label={language('en')} color={lang==='en'?'primary':'default'} onClick={()=>setLang('en')} />
  </Stack>

  return (
    <RSCard title="邮件模板" action={<Actions />}>
      {email && 
      <Stack>
        {lang === 'cn' && 
        <Stack>
          <TextField 
            label="标题" 
            value={email.subject_cn} 
            name="subject_cn" 
            onChange={handleChange} 
          />
          <TextField 
            label="正文" 
            multiline rows={20} 
            value={email.body_cn} 
            name="body_cn" 
            onChange={handleChange}
          />
        </Stack>
        }

        {lang === 'en' && 
        <Stack>
          <TextField 
            label="Subject" 
            value={email.subject_en}
            name="subject_en" 
            onChange={handleChange} 
          />
          <TextField 
            label="Body" 
            multiline rows={20} 
            value={email.body_en} 
            name="body_en" 
            onChange={handleChange}
          />
        </Stack>
        }

        <Stack direction="row-reverse">
          <Button variant="contained" onClick={save} disabled={saving}>保存</Button>
        </Stack>
      </Stack>
      }
    </RSCard>
  )
}