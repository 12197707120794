import { createSlice } from '@reduxjs/toolkit'
import { RSPost } from 'utils/RSRequest'

// 产品
export const slice = createSlice({
  name: 'productSearch',
  initialState: {
    keywords: '',
    products: [],
    page: 1,
    total: 0,
    all: true, // 是否包含下架
  },
  reducers: {
    setKeywords: (state, action) => {
      state.keywords = action.payload
    },
    setProducts: (state, action) => {
      state.products = action.payload
    },
    setTotal: (state, action) => {
      state.total = action.payload
    },
    setPage: (state, action) => {
      state.page = action.payload
    },
    setAll: (state, action) => {
      state.all = action.payload
    },
  },
})

export const { 
  setKeywords, 
  setProducts,
  setTotal,
  setPage,
  setAll,
} = slice.actions

export const productSearchStore = state => state.productSearch
export default slice.reducer

export const search = () => (dispatch, getState) => {
  const store = getState()
  const { keywords, page, all } = store.productSearch
  const searchAll = all?1:0
  if(keywords !== '') {
    RSPost('product/search/'+keywords+'/'+page, {all: searchAll}, data => {
      dispatch(setProducts(data.products))
      dispatch(setTotal(data.total))
    })
  } else {
    dispatch(setProducts([]))
    dispatch(setTotal(0))
  }
}