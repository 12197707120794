import {  Stack, Typography } from "@mui/material"
import RSTable from "rsui/RSTable"
import OnShelfChip from "components/product/chips/OnShelfChip"
import ProductName from "../../../../components/product/components/ProductName"
import RSTextEditor from "rsui/RSTextEditor"
import { updateProduct } from "api/product"
import MarginChip from "components/product/chips/MarginChip"
import DiscountTagChip from "components/promotion/discount/DiscountTagChip"
import { setProductDiscountExpiry, setProductDiscountNote } from "api/discount"
import ProductRecentSalesChip from "pages/product/management/components/ProductRecentSalesChip"
import ProductEditChip from "components/product/chips/ProductEditChip"


// 产品列表
export default function ProductList({ products, reload }) {

  const columns = [
    {
      title: 'SKU',
      render: (product) => ('#'+product.id),
    },
    {
      title: '产品',
      render: (product) => <ProductName product={product} />
    },
    {
      title: '保质期',
      render: (product) => <RSTextEditor 
        item={product}
        entity='expiry_date'
        width="150px"
        callback={updateProduct}
      />
    },
    {
      title: '库存',
      render: (product) => product.stock_qty
    },
    {
      title: '销售价',
      render: (product) => <RSTextEditor 
        item={product}
        entity='price'
        prefix='£'
        width="100px"
        callback={updateProduct}
      />
    },
    {
      title: '折扣',
      render: (product) =>  <DiscountTagChip 
                              product={product} 
                              callback={reload}
                            />
    },
    {
      title: '折扣价',
      render: (product) => <Typography>{product.discount_price?'£'+product.discount_price:'-'}</Typography>
    },
    {
      title: '折扣备注',
      render: (product) =>  <RSTextEditor 
                              color="error"
                              item={product}
                              entity='discount_note'
                              callback={updateNote}
                              onlyIcon
                            />
    },
    {
      title: '折扣有效期',
      render: (product) =>  <RSTextEditor 
                              item={product}
                              entity='discount_expiry_date'
                              callback={updateDiscountExpiry}
                            />
    },
    {
      title: '成本价',
      render: (product) => <RSTextEditor 
        item={product}
        entity='cost_price'
        prefix='£'
        width="100px"
        callback={updateProduct}
      />
    },
    {
      title: '毛利率',
      render: (product) => <MarginChip product={product} />
    },
    {
      title: '',
      render: (product) => 
      <Stack direction='row'>
        <OnShelfChip product={product} />
        <ProductRecentSalesChip product={product} />
        <ProductEditChip product={product} callback={reload} />
      </Stack>
      
    }
  ]

  const updateNote = async (data) => {
    await setProductDiscountNote(data)
    reload()
  }

  const updateDiscountExpiry = async (data) => {
    await setProductDiscountExpiry(data)
  }

  return <RSTable data={products} columns={columns} />
}