import { Box, Chip, Stack, Typography } from '@mui/material'
import { noticeDone, noticeNext } from 'api/delivery'
import { startLoading, stopLoading } from 'utils/layout'
import { address, name, postcode } from 'utils/str'
import RSCard from 'rsui/RSCard'

export default function TaskCard({ task, reloadList }) {

  const nextNotice = async () => {
    if(window.confirm(`通知客户 ${task.postcode} 是下一站?`)) {
      startLoading()
      await noticeNext(task)
      window.alert('已发邮件通知')
      reloadList()
      stopLoading()
    } 
  }

  const deliveredNotice = async () => {
    if(window.confirm(`${task.postcode} 已送达?`)) {
      startLoading()
      await noticeDone(task)
      window.alert('已发邮件通知')
      reloadList()
      stopLoading()
    } 
  } 

  const Action = () => 
  <Stack direction="row" spacing={1}>
    {task.logistics_next !== '1' && <Chip label="下一站" onClick={nextNotice} />}
    <Chip label="送达" onClick={deliveredNotice} />
  </Stack>
  

  return (
  <RSCard 
    title={`${task.route}-${task.sort}`}
    action={<Action />}
  >
    <Stack direction="row">
      <Box>
        <Typography>{address(task.address_1)}</Typography>
        {task.address_2 && <Typography>{address(task.address_2)}</Typography>}
        {task.address_3 && <Typography>{address(task.address_3)}</Typography>}
        <Typography>{address(task.city)}</Typography>
        <Typography>{postcode(task.postcode)}</Typography>
      </Box>

      <Box flexGrow={1} />

      <Box>
        <Typography>{name(task.name)}</Typography>
        <Typography>{task.phone}</Typography>
      </Box>
    </Stack>
    
  </RSCard>
  )
}