/* 数据分析接口 */

import call from 'utils/call' 

/***************************************************************/
export async function salesTable(data) {
  console.log("销售表数据")
  return await call('analytics/salesTable', data)
}

/***************************************************************/
export async function salesChart(data) {
  console.log("销售图表数据")
  return await call('analytics/salesChart', data)
}

/***************************************************************/
export async function dailyTakings(data) {
  console.log("Daily taking")
  return await call('analytics/dailyTakings', data)
}

/***************************************************************/
export async function ordersMap(data) {
  console.log("订单地图")
  return await call('analytics/ordersMap', data)
}


