
import { Grid, Stack } from '@mui/material'
import WebVersion from './components/WebVersion';
import AdminVersion from './components/AdminVersion';

export default function Versions() {

  return (
    <Grid 
      container
      justifyContent="center"
    >
      <Grid item xs={4}>
        <Stack>
          <WebVersion />
          <AdminVersion />
        </Stack>
      </Grid>
    </Grid>
  )
}