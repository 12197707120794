/* 代金券 页面 */

import { Grid } from '@mui/material'
import CouponList from './components/CouponList'
import CouponAnalytics from './components/CouponAnalytics'
import CouponNote from './components/CouponNote'

export default function Coupon() {

  return(
  <Grid container spacing={2}>
    
    <Grid item lg={6}>
      <CouponList />
    </Grid>

    <Grid item lg={4}>
      <CouponAnalytics />
    </Grid>

    <Grid item lg={2}>
      <CouponNote />
    </Grid>

  </Grid>
  )
}