import { Box, Stack } from '@mui/material'
import RSCard from 'rsui/RSCard'

// Redux
import { useSelector } from 'react-redux'
import { editOrder, orderStates} from 'redux/orderSlice'
import HoldChip from 'components/order/status/HoldChip'
import PrintChip from 'components/order/status/PrintChip'
import StatusChip from 'components/order/status/StatusChip'
import LogisticsChip from 'components/order/status/LogisticsChip'
import DriverChip from 'components/order/status/DriverChip'
import FeatureChips from 'components/order/status/FeatureChip'


export default function OrderStatusCard() {

  const { order } = useSelector(orderStates)

  const Actions = () => 
  <Stack direction="row" spacing={1}>
    <HoldChip order={order} callback={editOrder} />
    <PrintChip order={order} callback={editOrder} />
  </Stack>

  return (
    <RSCard title="订单状态" action={<Actions />}>

      <Stack direction="row" spacing={1} alignItems="center">
        <StatusChip order={order} callback={editOrder} />
        <LogisticsChip order={order} callback={editOrder} />
        {order.logistics_id === '4' && <DriverChip order={order} callback={editOrder} />}
        <Box flexGrow={1} />
        <FeatureChips order={order} />
      </Stack>

    </RSCard>
  )
}