import { useState, useEffect } from 'react'
import { Stack } from '@mui/material'
import { GoogleMap, LoadScript, Marker, MarkerClusterer } from '@react-google-maps/api'
import RSCard from 'rsui/RSCard'
import { MonthMenu, YearMenu } from "rsui/RSMenu"
import { ordersMap } from 'api/analytics'

export default function OrdersMap() {

  const date = new Date()
  const [year, setYear] = useState(date.getFullYear())
  const [month, setMonth] = useState(date.getMonth() + 1)
  const [orders, setOrders] = useState([])

  const apiKey = 'AIzaSyCkdCjnrxTiCPfxSNmW7o8X6Lrd6nMDaN8'

  const containerStyle = {
    width: '100%',
    height: '750px'
  };

  // London
  const center = {
    lat: 51.4508609,
    lng: 0.1701028
  };

  const Actions = () => 
  <Stack spacing={1} direction="row">
    <YearMenu year={year} callback={yearCallback} />
    <MonthMenu month={month} callback={monthCallback} />
  </Stack>

  useEffect(() => {
    loadData()
  }, [year, month])

  const yearCallback = year => setYear(year)
  const monthCallback = month => setMonth(month)

  const loadData = async () => {
    if(year && month) {
      setOrders([])
      const data = await ordersMap({ year: year, month: month})
      if(data) setOrders(data)
    }
  }

  return (
    <RSCard title="订单方位统计" action={<Actions />} cardContentProps={{sx: {padding:0}}}>
      <LoadScript googleMapsApiKey={apiKey}>

        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={11}
        >
        {orders && orders.length > 0 && 
          <MarkerClusterer>
            {(clusterer) =>
              orders.map((location, index) => (
                <Marker 
                  key={location.lat + '-'+ location.lng + '-' + index} 
                  title={location.postcode} 
                  position={{lat: Number(location.lat), lng: Number(location.lng)}} 
                  clusterer={clusterer}
                />
              ))
            }
          </MarkerClusterer>
        }
        </GoogleMap>
      </LoadScript>
    </RSCard>
  )
}