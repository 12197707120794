import { useEffect, useState } from 'react'
import { Typography, Menu, MenuItem, Box, Chip } from '@mui/material'

// *******************************************************************************************************
// 按钮 切换订单状态

export default function StatusChip({ order, callback }) {

  const states = ["配送中", "已签收", "已取消"]
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [anchorEl, setAnchorEl] = useState(null)

  useEffect(() => {
    setSelectedIndex(order.status)
  }, [])

  const onClickStateMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const onChangeState = (index) => {
    handleClose()
    if(index !== selectedIndex) {
      setSelectedIndex(index)
      callback({
        id: order.id,
        status: index.toString()
      })
    }
  };

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
  <>
    <Box onClick={onClickStateMenu}>
      <OrderStatus status={selectedIndex} />
    </Box>

    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      {states.map((state, index)=>(
      <MenuItem onClick={()=>onChangeState(index)} key={index}>
        <Typography>{state}</Typography>
      </MenuItem>
      ))}
    </Menu>
  </>
  )
}

const Processing = () => <Chip label="配送中" variant='outlined' />

const Delivered = () => <Chip label="已签收" color="success" />

const Cancelled = () => <Chip label="已取消" variant='outlined' color="error" />

export const OrderStatus = ({status}) => {
  return (
    <>
      {parseInt(status) === 0 && <Processing />}
      {parseInt(status) === 1 && <Delivered />}
      {parseInt(status) === 2 && <Cancelled />}
    </>
  )
}