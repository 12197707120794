// 订单详情 订单产品列表

import { Typography } from "@mui/material";

const columns = [
  {
    title: '图片',
    render: (item) => <img src={item.img} alt="" width="50" />,
  },
  {
    title: '产品',
    render: (item) => <Typography>#{item.product_id} {item.name_cn} <br/> {item.name_en}</Typography>
  },
  {
    title: '单价',
    render: (item) => `£${item.price}`,
  },
  {
    title: '数量',
    dataIndex: 'qty',
    width: '30px'
  },
  {
    title: '总价',
    render: (item) => `£${item.amount}`,
  }
];

export default columns