/* 设置 接口 */

import call from 'utils/call' 

/***************************************************************/
export async function getWebVersion() {
  console.log('获得前台版本')
  return await call('settings/version/7')
}

/***************************************************************/
export async function updateWebVersion() {
  console.log('更新前台版本')
  return await call('settings/versionUpdate/7')
}

/***************************************************************/
export async function getAdminVersion() {
  console.log('获得后台版本')
  return await call('settings/version/8')
}

/***************************************************************/
export async function updateAdminVersion() {
  console.log('更新后台版本')
  return await call('settings/versionUpdate/8')
}