import { useEffect, useState } from "react"
import RSCard from "rsui/RSCard"
import RSTable from "rsui/RSTable"
import WalletCodeStatus from "./WalletCodeStatus"
import WalletCodeGenerateButton from "./WalletCodeGenerateButton"
import { getWalletCode } from "../api"
import { Pagination, Stack } from "@mui/material"


export default function WalletCodeList() {

  const [codes , setCodes] = useState(null)
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(1)

  const columns = [
    {
      title: '充值码',
      dataIndex: 'code',
    },
    {
      title: '金额',
      render: (item) => '£'+item.amount
    },
    {
      title: '状态',
      render: (item) => <WalletCodeStatus status={item.status} />
    },
  ]

  const fetchData = async () => {
    const res = await getWalletCode(page)
    if(res) {
      setCodes(res.data)
      setPages(res.pages)
    } else {
      setCodes([])
      setPages(1)
    }
  }

  const changePage = (event, value) => {
    setPage(value);
  };

  useEffect(()=>{
    fetchData()
  }, [page])

  return (
    <RSCard
      title="充值码"
      action={<WalletCodeGenerateButton reload={fetchData} />}
    >
      <Stack spacing={2}>
      {codes && 
        <RSTable data={codes} columns={columns} />
      }
      {pages > 1 && 
        <Pagination count={pages} page={page} onChange={changePage} />
      }
      </Stack>
    </RSCard>
  )
}