import { Chip, Stack, Typography } from '@mui/material'
import { forwardRef,  useEffect,  useImperativeHandle, useRef, useState } from "react"
import RSDialog from "rsui/RSDialog"
import { stockRecordType } from "utils/configs"
import RSForm from "rsui/RSForm"
import { deleteRecord, getProductQty, updateRecord } from 'api/stock'
import { startLoading, stopLoading } from 'utils/layout'
import ProductSearchDrawer, { ProductSearchChip } from 'components/product/search/ProductSearchDrawer'

const form = [
  {
    contents:[
      {
        type: 'input',
        name: 'qty',
        label: '调整数量'
      },
      {
        type: 'picker',
        name: 'type_id',
        required: true,
        label: '备注',
        options: stockRecordType
      }
    ]
  }
]

export function StockRecordAddChip({ callback }) {

  const ref = useRef()
  const [record, setRecord] = useState()

  const selectProduct = (product) => {
    setRecord({
      product: product.name_cn,
      product_id: product.id,
      stock_qty: product.stock_qty,
      qty: 0,
      type_id: 0,
      hide: product.hide
    })
  }

  useEffect(() => {
    if(record)  ref.current.openDialog()
  }, [record])

  return (
    <>
    <ProductSearchChip onSelect={selectProduct} label="新建" />
    <StockRecordDailog ref={ref} callback={callback} record={record} />
    </>
  )
}



export default function StockRecordEditChip({ record, callback }) {
  
  const ref = useRef()

  return (
    <>
    <Chip label="调整" onClick={()=>ref.current.openDialog()} />
    <StockRecordDailog ref={ref} callback={callback} record={record} />
    </>
  )
}

const StockRecordDailog = forwardRef((props, ref) => {
  
  const { record, callback } = props
  const [open, setOpen] = useState(false)
  const [ formData, setFormData ] = useState()

  useImperativeHandle(ref, () => ({
    openDialog() {
      setOpen(true)
      loadQty()
    }
  }))

  const loadQty = async () => {
    const data = await getProductQty(record.product_id)
      if(data) {
        setFormData({
          ...record,
          ...data
        })
      }
  }

  const closeDialog = () => {
    setOpen(false)
  }

  const handleSave = async (data) => {
    startLoading()
    await updateRecord(data)
    stopLoading()
    callback()
    closeDialog()
  }

  const handleDelete = async () => {
    startLoading()
    await deleteRecord(record)
    stopLoading()
    callback()
    closeDialog()
  }

  return (
    <RSDialog
      title="库存调整"
      open={open}
      onClose={closeDialog}
      maxWidth="xs"
      fullWidth
    >
      {formData && 
      <Stack>
        <Typography>
          库存 {formData.stock_qty} 
          {formData.sales_qty && Number(formData.sales_qty) > 0 && 
            " + 售出 "+formData.sales_qty+" = "+(Number(formData.stock_qty) + Number(formData.sales_qty))
          }
        </Typography>
        <RSForm
          data={formData} 
          fields={form} 
          onSave={handleSave} 
          onDelete={handleDelete} 
        />
      </Stack>
      }
    </RSDialog>
  )
})