/* 代金券 数据 */

import { createSlice } from '@reduxjs/toolkit'
import store from 'redux/store'

export const slice = createSlice({
  name: 'voucher',
  initialState: {
    template: {},
    templates: [],
    templateDialog: false,
    voucher: {},
    voucherDialog: false,
    vouchers: [],
    vouchersPage: 1,
    vouchersPages: 1
  },
  reducers: {
    setTemplate: (state, action) => {
      state.template = action.payload
    },
    setTemplates: (state, action) => {
      state.templates = action.payload
    },
    setTemplateDialog: (state, action) => {
      state.templateDialog = action.payload
    },
    setVoucher: (state, action) => {
      state.voucher = action.payload
    },
    setVoucherDialog: (state, action) => {
      state.voucherDialog = action.payload
    },
    setVouchers: (state, action) => {
      state.vouchers = action.payload
    },
    setVouchersPage: (state, action) => {
      state.vouchersPage = action.payload
    },
    setVouchersPages: (state, action) => {
      state.vouchersPages = action.payload
    },
  },
})

export const { 
  setTemplate,
  setTemplates,
  setTemplateDialog,
  setVoucher,
  setVoucherDialog,
  setVouchers,
  setVouchersPage,
  setVouchersPages
} = slice.actions

export const voucherStates = state => state.voucher

export default slice.reducer

export function openTemplateEditor(template) {
  store.dispatch(setTemplate(template))
  store.dispatch(setTemplateDialog(true))
}

export function closeTemplateEditor() {
  store.dispatch(setTemplateDialog(false))
  store.dispatch(setTemplate(null))
}

export function openVoucherEditor(voucher) {
  store.dispatch(setVoucher(voucher))
  store.dispatch(setVoucherDialog(true))
}

export function closeVoucherEditor() {
  store.dispatch(setVoucherDialog(false))
  store.dispatch(setVoucher(null))
}