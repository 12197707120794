import { Box, Stack, Typography } from '@mui/material'
import HeaderUserMenu from './HeaderUserMenu';
import HeaderOrderSearch from './HeaderOrderSearch';
import { hasPermission } from 'utils/auth';
import SiderToggleButton from './SiderToggleButton';
import { Link } from 'react-router-dom';

export default function Header() {

  return (
    <Box sx={{ height: '7vh !important' }}>
      <Stack 
        direction="row"
        spacing={1}
        sx={{ 
          padding: 1,
          bgcolor: '#eee', 
          displayPrint: 'none'
        }}
        alignItems="center"
      >
        <Box>
          <SiderToggleButton />
        </Box>

        <Link to="/">
          <Typography variant='h6' sx={{lineHeight: '1.5rem', color:'#000'}}>Red Squirrel Shop</Typography>
        </Link>

        <Box flexGrow={1}></Box>
        {hasPermission('staff') && <HeaderOrderSearch />}
        <HeaderUserMenu />
      </Stack>
    </Box>
  )
}

