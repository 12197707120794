import { Chip } from '@mui/material'

export default function HoldChip({ order, callback }) {

  const toggle = (event) => {
    event.stopPropagation()
    callback({
      id: order.id,
      hold: Number(order.hold) === 0 ? '1' : '0'
    })
  }

  return (
    <>
    { 
      Number(order.hold) === 0 
      ? 
        <Chip label="暂停" variant='outlined' sx={{color: '#ddd'}} onClick={toggle}  /> 
      : 
        <Chip label="暂停" color="warning"  onClick={toggle} />
    }
    </>
  )
}

 
