/* 多买多送 接口 */

import call from 'utils/call' 

/***************************************************************/
export async function getAllBuyMore() {
  console.log("获得多买多送列表")
  return await call('buyMoreGetMore/getList')
}

/***************************************************************/
export async function addBuyMore() {
  console.log("新建多买多送活动")
  await call('buyMoreGetMore/add')
}

/***************************************************************/
export async function updateBuyMore(data) {
  console.log("更新多买多送活动")
  await call('buyMoreGetMore/update', data)
}