import RSCard from 'rsui/RSCard'
import { Stack } from '@mui/material'
import CustomerWallet from 'components/customer/components/CustomerWallet'
import CustomerOrders from 'components/customer/components/CustomerOrders'
import CustomerAddress from 'components/customer/components/CustomerAddress'
import CustomerInfo from 'components/customer/components/CustomerInfo'

// *******************************************************************************************************
// Card 客户信息

export default function CustomerCard({ customer }) {
  
  return (
    <RSCard 
      title="客户信息" 
      action={
        <Stack direction='row' spacing={1}> 
          <CustomerWallet customer={customer} />
          <CustomerAddress customer={customer} />
          <CustomerOrders customer={customer} />
        </Stack>
      } 
    >
      <CustomerInfo customer={customer} />
    </RSCard>
  )
}