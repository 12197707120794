import { Chip } from "@mui/material"

export default function WalletCodeStatus({ status }) {

  const s = Number(status)

  return (
    <>
    {s === 0 && <Chip label="未使用" />}
    {s === 1 && <Chip label="已使用" color="success" />}
    {s === 2 && <Chip label="已失效" color="error" />}
    </>
  )
}