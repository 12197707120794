import { useRef } from "react";
import { Chip } from "@mui/material";
import { uploadTrackingNumbers } from "api/order";
import { startLoading, stopLoading } from "utils/layout";
import { loadNewOrders } from "redux/newOrdersSlice";

// 按钮 上传订单追踪码
export default function TrackingChip({logisticsName}) {

  const inputFileRef = useRef()

  const handleUpload = async (e) => {
    startLoading()
    const files = e.target.files;
    const formData = new FormData()
    formData.append('type', logisticsName)
    formData.append('uploadedfile', files[0])

    await uploadTrackingNumbers(formData)
    await loadNewOrders()
    stopLoading()
  }

  const handleClick = (e) => {
    inputFileRef.current.click();
  }

  return (
  <>
    <Chip label="追踪" onClick={handleClick} />
    <input
      accept=".csv"
      type="file"
      ref={inputFileRef}
      onChange={handleUpload}
      style = {{display:'none'}}
    />
  </>
  )
}
