import { setProductDiscount } from "api/discount";
import RSSelector from "rsui/RSSelector";

export default function DiscountTagChip(props) {

  const { product, callback } = props
  const options = discountOptions()
  const color = product.discount_tag?'error':'default'

  const setDiscount = async (option) => {
    await setProductDiscount({id: product.id, price: product.price, discount_tag: option.value})
    callback()
  }

  return (
    <RSSelector
      options={options}
      selectedValue={product.discount_tag}
      color={color}
      onSelect={setDiscount}
    />
  )
}

function discountOptions () {
  const step = 5;
  const options = [{
    key:0,
    value: null
  }]

  for (let i = 1; i < 19; i ++) {
    const option = {
      key: i,
      value: `${i*step}% OFF`,
    };
    options.push(option);
  }

  return options;
}