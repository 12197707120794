import { Avatar, Stack, Typography } from "@mui/material"
import FeatureChips from "components/product/chips/FeatureChips"

export default function ProductName({product}) {

  return (
    <Stack direction="row" alignItems='center' spacing={1} >
      <Avatar 
        src={product.img+'?x-oss-process=style/100x100'} 
        alt="" 
        variant="rounded" 
      />
      <Stack spacing={0}>
        <Typography sx={product.hide === '1'?{textDecoration: 'line-through'}:{}}>{product.name_cn}</Typography>
        <Typography color="error" variant="caption">{product.discount_note}</Typography>
      </Stack>
      <FeatureChips product={product} />
    </Stack>
  )
}