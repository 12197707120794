import { createSlice } from '@reduxjs/toolkit'
import { RSGet } from 'utils/RSRequest'

// 登录状态
export const slice = createSlice({
  name: 'banner',
  initialState: {
    banners: [],
    banner: null,
    position: 1,
    dialog: false,
  },
  reducers: {
    setPosition:(state, action) => {
      state.position = action.payload
    },
    setBanners:(state, action) => {
      state.banners = action.payload
    },
    setBanner: (state, action) => {
      state.banner = action.payload
    },
    setDialog: (state, action) => {
      state.dialog = action.payload
    },
  },
})

export const { 
  setPosition,
  setBanners,
  setBanner,
  setDialog
} = slice.actions
export const BannerStore = state => state.banner
export default slice.reducer


export const loadBanners = () => (dispatch, getState) => {
  const store = getState()
  const { position } = store.banner
  RSGet("banner/banners/"+position, data => {
    dispatch(setBanners(data))
  })
}

export const addBanner = () => (dispatch, getState) => {
  const store = getState()
  const { position } = store.banner
  RSGet("banner/add_banner/"+position, data => {
    dispatch(loadBanners())
  })
}
