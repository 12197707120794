import { useDispatch, useSelector } from 'react-redux'
import { closeCouponEditor, couponStates, setCoupons } from '../slice'
import RSDialog from 'rsui/RSDialog'
import RSForm from 'rsui/RSForm'
import { startLoading, stopLoading } from 'utils/layout'
import { deleteCoupon, getAllCoupons, saveCoupon } from '../api'

// *****************************************************************************
// 优惠码编辑窗口

export default function CouponEditor() {

  const dispatch = useDispatch()
  const { coupon, couponDialog } = useSelector(couponStates)

  const form = [
    {
      contents: [
        {
          type: 'input',
          name: 'condition',
          label: '最低消费'
        },
        {
          type: 'input',
          name: 'deduction',
          label: '抵扣金额'
        }
      ]
    },
    {
      contents: [
        {
          type: 'input',
          name: 'code',
          label: '优惠码'
        },
        {
          type: 'date',
          name: 'expiry_date',
          label: '有效期至'
        }
      ]
    },
    {
      type: 'input',
      name: 'note',
      label: '备注'
    }
  ]

  const handleSave = async (data) => {
    startLoading()
    await saveCoupon(data)
    await fetchListData()
  }

  const handleDelete = async () => {
    startLoading()
    if(coupon) await deleteCoupon(coupon.id)
    await fetchListData()
  }

  const fetchListData = async () => {
    const data = await getAllCoupons()
    if(data) dispatch(setCoupons(data))
    closeCouponEditor()
    stopLoading()
  }

  return (
    <RSDialog
      title="代金券模板编辑"
      open={couponDialog}
      onClose={closeCouponEditor}
    >
      {coupon && 
      <RSForm 
        data={coupon} 
        fields={form}
        onSave={handleSave}
        onDelete={handleDelete}
      />
      }
    </RSDialog>
  )
}