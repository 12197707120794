import { useState, useEffect } from "react"
import { Grid, Stack } from "@mui/material"
import { MonthMenu, YearMenu } from "rsui/RSMenu"
import RSTable from "rsui/RSTable"
import RSCard from "rsui/RSCard"
import { dailyTakings } from "api/analytics"

export default function DailyTakings() {
  const date = new Date()
  const [year, setYear] = useState(date.getFullYear())
  const [month, setMonth] = useState(date.getMonth() + 1)
  const [sales, setSales] = useState([])

  const loadData = async () => {
    const data = await dailyTakings({month: month, year: year})
    if(data) setSales(data)
  }

  useEffect(()=>{
    loadData()
  }, [year, month])

  const yearCallback = year => setYear(year)
  const monthCallback = month => setMonth(month)

  const Actions = () => 
  <Stack spacing={1} direction="row">
    <YearMenu year={year} callback={yearCallback} />
    <MonthMenu month={month} callback={monthCallback} />
  </Stack>

  const columns = [
    {
      title: 'Date',
      render: (order, index) => (NumFormat(index+1)+'/'+NumFormat(month)+'/'+year)
    },
    {
      title: 'Zero Rated',
      dataIndex: 'zeroRate'
    },
    {
      title: 'Standard Rated',
      dataIndex: 'standardRate'
    },
    {
      title: 'Total',
      dataIndex: 'total'
    }
  ]
  
  return (
  <Grid container spacing={2}>
    <Grid item xs={6}>
      <RSCard title="Daily Takings" action={<Actions/>}>
        <RSTable data={sales} columns={columns} />
      </RSCard>
    </Grid>
  
    <Grid item xs={6}>
      <RSCard title="算法逻辑">
        <div>Zero Rated = 0% VAT 产品销售 - 代金券 - VIP折扣 - 退款 - 钱包</div><br/>
        <div>Standard Rated = 20% VAT 产品销售 + 包装费 + 物流费</div><br/>
      </RSCard>
    </Grid>
  </Grid>
  )
}

function NumFormat(num) {
  return (num < 10) ? '0' + num.toString() : num.toString();
}