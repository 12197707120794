import { Chip, ListItem } from "@mui/material";
import RSCard from "rsui/RSCard";


export default function TemplateList({ templates, setTemplate }) {

  const add = () => {
    setTemplate({
      subject_cn: '',
      subject_en: '',
      body_cn: '',
      body_en: ''
    })
  }

  const select = (item) => {
    setTemplate(item)
  }

  return (
    <RSCard title="邮件通知模板" action={<Chip label="新建" onClick={add} />}>
      {templates.map(template => 
        <ListItem key={template.id} sx={{ cursor: 'pointer'}} onClick={()=>select(template)}>
          {template.subject_cn} <br/>
          {template.subject_en}
        </ListItem>
      )}
    </RSCard>
  )
}