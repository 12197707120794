import { useState, useEffect } from "react"
import { Grid } from "@mui/material"
import SalesTable from "./SalesTable"
import SalesChart from "./SalesChart"
import {YearMenu } from "rsui/RSMenu"
import { salesChart, salesTable } from "api/analytics"

// 月销售
export default function MonthSales () {
  const date = new Date()
  const [year, setYear] = useState(date.getFullYear())

  const [data, setData] = useState([])
  const [chartData, setChartData] = useState(null)

  useEffect(() => {
    loadData()
    // eslint-disable-next-line
  }, [year])

  const loadData = async () => {
    if(year) {
      const res = await salesTable({type: 2, year: year})
      if(res) setData(res)
      const res2 = await salesChart({type: 2, year: year})
      if(res2) setChartData(res2)
    }
  }

  const yearCallback = (year) => {
    setYear(year)
  }

  const Actions = () => 
  <>
  <YearMenu year={year} callback={yearCallback} />
  </>
  

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <SalesTable title="月销售(K)" data={data} action={<Actions />} />
      </Grid>
      <Grid item xs={6}>
        {chartData && <SalesChart title="月销售(K)" data={chartData} />}
      </Grid>
    </Grid>
  )
}