import { MenuItem } from "./MenuItems"
import { useEffect } from "react"
import { ReceiptLongOutlined } from "@mui/icons-material"
import { Chip } from "@mui/material"
import { getNewOrdersNum } from "api/order"
import { useSelector } from "react-redux"
import { loadNewOrders, newOrdersStates, setNewOrderID, setNewOrdersNum } from "redux/newOrdersSlice"
import store from "redux/store"

// 新订单
export default function MenuItemNewOrder() {
 
  const menu = {
    text: '新订单',
    path: '/new-orders',
    permission: 'staff',
    icon: ReceiptLongOutlined
  }

  const { newOrderID, newOrdersNum } = useSelector(newOrdersStates)

  const sec = (process.env.NODE_ENV === "production")? 60 : 600

  useEffect(()=>{
    if(newOrderID) {
      console.log('有新订单')
      // 有新订单 播放提示音
      const audio = new Audio("/dingdong.mp3")
      
      // 如果浏览器支持自动播放，则尝试播放音频
      const playPromise = audio.play();
    
      // 处理播放失败的情况
      if (playPromise !== undefined) {
        playPromise.then(_ => {
          // 成功播放
        }).catch(error => {
          console.error("自动播放失败:", error);
          // 可以在这里处理自动播放失败的情况，例如等待用户互动后再尝试播放
        });
      }
      loadNewOrders() // 刷新新订单列表
    }
  }, [newOrderID])

  const count = async () => {
    const res = await getNewOrdersNum()
    console.log(res)
    if(res) {
      console.log(res.number)
      store.dispatch(setNewOrdersNum(res.number))
      store.dispatch(setNewOrderID(res.newest_id))
    }
  }

  useEffect(() => {
    count()
    const intervalId = setInterval(() => {
      count()
    }, 1000 * sec) // in milliseconds
    return () => clearInterval(intervalId)
  }, [])

  return (
    <MenuItem 
      menu={menu} 
      secondaryAction={<Chip label={newOrdersNum} color="error" />}
    />
  )
}