import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import App from 'App';

// Redux
import store from 'redux/store'
import { Provider as ReduxProvider } from 'react-redux'

// Mui
import { ThemeProvider } from '@mui/material';
import theme from 'utils/theme';

const root = ReactDOM.createRoot(document.getElementById('root'));

if (process.env.NODE_ENV === "production") console.log = function no_console() {};

root.render(
  <ReduxProvider store={store}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </ReduxProvider>
);