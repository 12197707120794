import { createSlice } from '@reduxjs/toolkit'
import { RSPost } from 'utils/RSRequest'

// 登录状态
export const slice = createSlice({
  name: 'orders',
  initialState: {
    title: '全部',
    orders: [],
    status: 0, // 默认新订单列表
    type: 0,
    sort: 0, // 默认按时间排序
    logistics: 2, // 默认DPD
    newOrdersNum: 0
  },
  reducers: {
    setTitle:(state, action) => {
      state.title = action.payload
    },
    setOrders: (state, action) => {
      state.orders = action.payload
    },
    setType: (state, action) => {
      state.type = action.payload
    },
    setStatus: (state, action) => {
      state.status = action.payload
    },
    setLogistics: (state, action) => {
      state.logistics = action.payload
    },
    setSort: (state, action) => {
      state.sort = action.payload
    },
    setNewOrdersNum: (state, action) => {
      state.newOrdersNum = action.payload
    },
  },
})

export const { 
  setTitle,
  setApi,
  setOrders, 
  setStatus,
  setLogistics,
  setType,
  setSort,
  setNewOrdersNum
} = slice.actions
export const OrdersStore = state => state.orders
export default slice.reducer


export const loadOrders = () => (dispatch, getState) => {
  const store = getState()
  const { status, sort, type, logistics } = store.orders
  RSPost("orders", {sort:sort, status: status, type: type, logistics: logistics}, data => {
    dispatch(setOrders(data))
  })
}
