import { createSlice } from '@reduxjs/toolkit'

// 权限
/*
  scope [
    order // 订单管理权限
  ]
*/
export const slice = createSlice({
  name: 'Permission',
  initialState: {
    scope: [],
  },
  reducers: {
    setScope: (state, action) => {
      state.scope = action.payload
    }
  },
})

export const { setScope } = slice.actions
export const Permissions = state => state.Permission.scope
export default slice.reducer

export const checkPermission = (permission) => (dispatch, getState) => {
  const store = getState()
  const permissions = store.Permission.scope
  return permissions.includes(permission)
}
