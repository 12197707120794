import { useState, useEffect, useRef } from "react"
import { Req } from "utils/RSRequest"
import RSCard from "rsui/RSCard"
import { Button, Chip, Stack, Typography } from "@mui/material"
import RSDialog from "layout/components/RSDialog"
import RSForm from "layout/components/RSForm"

export default function ProductTags() {
  return <TagsCard />
}

function TagsCard() {
  const ref = useRef()
  const [type, setType] = useState(0)
  const [tags, setTags] = useState([])
  const [tag, setTag] = useState(null)
  const [linked, setLinked] = useState(0)

  const chipStyle = {
    mr: 1,
    mb: 1
  }

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type])

  const loadData = () => {
    setTags([])
    Req('tag/tags/'+type)
      .then(data => setTags(data))
  }

  const add = () => {
    setLinked(0)
    setTag({
      type: type
    })
    ref.current.openDialog()
  }

  const open = (t) => {
    setLinked(0)
    setTag(t)
    Req('tag/productLinkedNumber/'+t.id)
      .then(data => setLinked(data.num))
    ref.current.openDialog()
  }

  const changeType = (type) => {
    setType(type)
  }

  const form = [{
    contents: [
      {
        type: 'input',
        name: 'name_cn',
        label: '标签-中文'
      },
      {
        type: 'input',
        name: 'name_en',
        label: 'Tag-English'
      }
    ]
  }]

  const formCallback = () => {
    loadData()
    ref.current.closeDialog()
  }

  const Actions = () => <>
    <Button onClick={()=>changeType(0)} size="small">品牌</Button>
    <Button onClick={()=>changeType(1)} size="small">系列</Button>
  </>

  return (
    <RSCard title="产品标签" action={<Actions />}>
      <Chip onClick={add} label="+" sx={chipStyle} />
      {
        tags.map(t => <Chip key={t.id} label={t.name_cn} sx={chipStyle} onClick={()=>open(t)} />)
      }

      <RSDialog ref={ref} title="产品标签">
        <Stack spacing={2}>
          <Typography>关联产品 {parseInt(linked)?linked:''}</Typography>
          {tag && <RSForm data={tag} fields={form} api="tag/update" callback={formCallback} />}
        </Stack>
      </RSDialog>

    </RSCard>
  )
}