import { Chip } from "@mui/material"
import { updatePurchase } from "api/stock"
import { getSupplierOptions } from "api/supplier"
import { useState, useEffect } from "react"
import RSDialog from "rsui/RSDialog"
import RSForm from "rsui/RSForm"

// 编辑进货单的 进货商和时间 信息
export default function PurchaseInfoEditor(props) {

  const { purchase, callback } = props
  const [ open, setOpen ] = useState(false)
  const [ suppliers, setSuppliers ] = useState([])

  const openDialog = () => setOpen(true)
  const closeDialog = () => setOpen(false)

  useEffect(() => {
    getAllSuppliers()
  }, [])

  const getAllSuppliers = async () => {
    const data = await getSupplierOptions()
    if(data) setSuppliers(data)
  }

  const handleSave = async (data) => {
    await updatePurchase(data)
    closeDialog()
    callback()
  }

  const form = [
    {
      contents: [
        {
          type: 'picker',
          name: 'supplier_id',
          label: '供应商',
          options: suppliers
        },
        {
          type: 'date',
          name: 'date',
          label: '日期'
        }
      ]
    }
  ]

  return (
  <>
  <Chip label="供货商" onClick={openDialog} />
  <RSDialog  
    title="进货单信息"
    onClose={closeDialog}
    open={open}
  >
    <RSForm 
      data={purchase} 
      fields={form} 
      onSave={handleSave}
    />
  </RSDialog>
  </>
  )
}