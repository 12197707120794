/* 系统用户菜单 */
import { useState } from 'react'
import { Chip, Menu, MenuItem, Stack, Typography } from '@mui/material'
import { useEffect } from 'react';

export default function RSMenu({label, items, callback, name}) {

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const openMenu = (event) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const closeMenu = () => {
    setAnchorEl(null)
  }

  const selectItem = (event, item) => {
    event.stopPropagation()
    if(callback) callback(item)
    closeMenu()
  }

  return (
    <>

    <Chip label={label?label:items[0].label} onClick={openMenu} />

    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={closeMenu}
    >
      {name && 
      <Stack alignItems="center" sx={{borderBottom: '1px solid #ccc', paddingBottom: '0.5rem', marginBottom: '0.5rem'}}>
        <Typography><b>{name}</b></Typography>
      </Stack>
      }
      {items.map((item,index) => <MenuItem key={index} onClick={(e)=>selectItem(e, item)} dense>{item.label}</MenuItem>)}
    </Menu>
    
    </>
  )
}

export function YearMenu({year, callback}) {

  const [options, setOptions] = useState([])
  const date = new Date()
  const thisYear = date.getFullYear()
  useEffect(() => {
    let years = []
    for(let year = 2020; year <= thisYear; year++) {
      years.push({label: year})
    }
    setOptions(years)
    // eslint-disable-next-line
  }, [])

  const handleCallback = (item) => {
    callback(item.label) // year
  }

  return <RSMenu label={year} items={options} callback={handleCallback} />
}

export function MonthMenu({month, callback}) {
  const [options, setOptions] = useState([])

  useEffect(() => {
    let months = []
    for(let month = 1; month <= 12; month++) {
      months.push({label: month})
    }
    setOptions(months)
    // eslint-disable-next-line
  }, [])

  const handleCallback = (item) => {
    callback(item.label) // year
  }

  return <RSMenu label={month+'月'} items={options} callback={handleCallback} />
}

export function DayMenu({day, callback}) {
  const [options, setOptions] = useState([])

  useEffect(() => {
    let days = []
    for(let day = 1; day <= 31; day++) {
      days.push({label: day})
    }
    setOptions(days)
    // eslint-disable-next-line
  }, [])

  const handleCallback = (item) => {
    callback(item.label) // year
  }

  return <RSMenu label={day+'日'} items={options} callback={handleCallback} />
}