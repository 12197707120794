import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Typography } from "@mui/material"
import { Close } from '@mui/icons-material'

/**
 * 基础组件 窗口组件
 * @param { string } title 窗口开关
 * @param { boolean } open 窗口开关
 * @param { function } onClose 关闭窗口时候的动作
 * @param { string } maxWidth sm | md | lg 窗口最大宽度
 * @returns 
 */
export default function RSDialog(props) {

  return (
    <Dialog
      open={props.open} 
      onClose={props.onClose}
      maxWidth={props.maxWidth}
      fullWidth = {props.fullWidth}
      fullScreen={props.fullScreen} 
      hideBackdrop={props.fullScreen}
    >
      { !props.noTitle && 
      <DialogTitle 
        sx={{ 
          background: '#eee', 
          paddingRight: '1rem', 
          paddingY: '0.5rem',
          "@media print": {
            display: 'none'
          }
        }}
      >
        <Stack direction="row" alignItems="center">
          <Typography variant="h6">{props.title}</Typography>
          <Box flexGrow={1} />
          <IconButton onClick={props.onClose}>
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      }

      <DialogContent dividers sx={{padding: props.noContentPadding?0:'16px 24px'}}>
        {props.children}
      </DialogContent>

      {props.actions && 
      <DialogActions
        sx={{
          "@media print": {
            display: 'none'
          }
        }}
      >
        {props.actions}
      </DialogActions>
      }
      
    </Dialog>
  )
}