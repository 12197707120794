import { useState } from "react"
import { Collapse, IconButton, Stack } from "@mui/material"
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import RSCard from "rsui/RSCard"
import OrderListItem from "./OrderListItem"

/***************************************************************************************/
// 订单列表
export default function OrderListCard({title, actions, orders}) {

  const [open, setOpen] = useState(true)

  const toggle = () => {
    setOpen(!open)
  }

  const Actions = () => 
  <Stack direction='row' spacing={1} alignItems="center">
    {actions}
    <IconButton onClick={toggle} size="small">
      {open?<KeyboardArrowUp />:<KeyboardArrowDown />}
    </IconButton>
  </Stack>

  return (
    <RSCard 
      title={title} 
      action={<Actions />}
    >
      <Collapse in={open}>
        <Stack>
          {orders && orders.map(order=> <OrderListItem order={order} key={order.id} />)} 
        </Stack>
      </Collapse>
    </RSCard>
  )
}