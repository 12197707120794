import { useState, useEffect } from "react"
import { Chip, Stack } from "@mui/material"
import { ProductSearchChip } from "components/product/search/ProductSearchDrawer"

/**
 * 产品选择器
 * @param { string } products json string [{id:int, name:string}, ...]
 * @param { function } callback 返回 products json string
 * @returns 
 */
export default function ProductSelector({products, callback})
{

  const [productsArray, setProductsArray] = useState([])
  const [product, setProduct] = useState(null)

  useEffect(() => {
    // json string 变成 数组
    if(!products || products === '') setProductsArray([])
    else setProductsArray(JSON.parse(products))
  }, [])

  useEffect(() => {
    if(product) {
      const newProduct = {id:product.id, name:product.name_cn}
      const itemExists = productsArray.some(item => item.id === newProduct.id);
  
      if (!itemExists) {
        const updatedProducts = [...productsArray, newProduct]
        setProductsArray(updatedProducts)
        updateProducts(updatedProducts)
      } else {
        console.log('Item already exists in the array!');
      }
    }
    
  }, [product])

  const addProduct = (product) => {
    setProduct(product)
  }

  const delProduct = (productID) => {
    const updatedProducts = productsArray.filter((product) => product.id !== productID);
    setProductsArray(updatedProducts);
    updateProducts(updatedProducts)
  }

  const updateProducts = (newProductsArray) => {
    let string = JSON.stringify(newProductsArray);
    if(string === '[]') string = null
    callback(string)
  }

  return (
    <Stack spacing={1} flexWrap="wrap">
      <Stack direction="row"><ProductSearchChip label="选择产品" onSelect={addProduct} /></Stack>
      {
        productsArray.map(product =>  <Stack direction="row" key={product.id} >
                                        <Chip label={product.name} onDelete={()=>delProduct(product.id)} />
                                      </Stack>)
      }
    </Stack>
  )
}