import { Chip } from "@mui/material"

export default function PaymentChip({order}) {

  const handleClickPaymentChannelChip = () => {
    if(order.so_transaction_type === 'Stripe')
      window.open("https://dashboard.stripe.com/payments/"+order.so_transaction_id)
    else if(order.so_transaction_type === 'Swiftpass')
      window.open("https://mymoney.wepayez.com/")
  }

  return <Chip label={order.so_transaction_type} onClick={handleClickPaymentChannelChip} />
}
