/* 产品 标签 设置 */
import { useState, useEffect } from 'react'
import { Req } from 'utils/RSRequest'
import { Grid, TextField, Autocomplete } from '@mui/material'
import RSCard from 'rsui/RSCard';
import { getTagsByType } from 'redux/tagSlice';
import { updateProductTags } from 'api/product';

export default function ProductEditTags({product}) {

  return (
    <RSCard title="产品标签">
      <Grid container spacing={2}>
        <Grid item lg={6}>
          <TagSelector label="栏目" typeID="3" product={product} />
        </Grid>
        <Grid item lg={6}>
          <TagSelector label="品牌" typeID="0" product={product} />
        </Grid>
        <Grid item lg={6}>
          <TagSelector label="系列" typeID="1" product={product} />
        </Grid>
        <Grid item lg={6}>
          <TagSelector label="产地" typeID="9" product={product} />
        </Grid>
      </Grid>
    </RSCard>
  )
}

function TagSelector(props) {
  const { product, typeID, label } = props
  const [tags, setTags] = useState([])
  const [productTags, setProductTags] = useState([])

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    const data = await getTagsByType(typeID)
    console.log(data)
    if(data) setTags(data)
    const filteredData = product.tags.filter(item => item.type === typeID);
    setProductTags(filteredData)
  }

  const updateTags = async (newValue) => {
    setProductTags(newValue)
    await updateProductTags({type: typeID, product_id: product.id, tags:newValue})
  }

  return (
  <Autocomplete
    multiple
    clearIcon={null}
    options={tags}
    getOptionLabel={(option) => option.name_cn}
    value={productTags}
    onChange={(event, newValue) => {
      updateTags(newValue);
    }}
    filterSelectedOptions
    renderInput={(params) => (
      <TextField
        {...params}
        label={label}
      />
    )}
  />
  )
}