/* 客户地址编辑 */

import { useState } from 'react'
import { address as address_str, postcode, name } from 'utils/str'
import { Button, Chip, Table, TableRow, TableBody, TableCell, Stack, IconButton, Box, Typography } from '@mui/material'
import RSDialog from 'rsui/RSDialog'
import { Done, ReplayRounded } from '@mui/icons-material'
import { copyOrderAddress, getAddressLatLng, getAddressList, setAddressToOrder, updateAddress } from 'api/customer'
import RSEditableTableRow from 'rsui/RSEditableTableRow'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsis } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'
import { orderStates } from 'redux/orderSlice'

export default function CustomerAddress({ customer, orderID }) {
  
  const [address, setAddress] = useState(null)
  const [addressList, setAddressList] = useState([])

  const [openAddressList, setOpenAddressList] = useState(false)
  const [openAddress, setOpenAddress] = useState(false)

  const { order } = useSelector(orderStates)

  const openListDialog = () => {
    setOpenAddressList(true)
    fetchData()
  }

  const closeListDialog = () => setOpenAddressList(false)

  const openAddressDialog = (address) => {
    setAddress(address)
    setOpenAddress(true)
  }

  const closeAddressDialog = async () => {
    setOpenAddress(false)
    await fetchData()
  }
  

  const fetchData = async() => {
    const data = await getAddressList(customer.id)
    if(data) setAddressList(data)
  }



  const copy = async () => {
    if(window.confirm('确定用订单覆盖此地址?')) {
      await copyOrderAddress({address_id:address.id, order_id:order.id})
      closeAddressDialog()
    }
  }

  return (
    <>
    <Chip label="地址" onClick={openListDialog} />

    <RSDialog 
      title="客户地址列表" 
      maxWidth="xs" 
      open={openAddressList}
      onClose={closeListDialog}
    >
      {addressList.map(address => 
        <Chip 
          key={address.id} 
          label={address_str(address.address_1) +', '+ postcode(address.postcode)} 
          onClick={()=>openAddressDialog(address)} 
          icon={address.id === customer.address_id ? <Done /> : <></>}
        />)
      }
    </RSDialog>

    <RSDialog 
      title="客户地址编辑" 
      maxWidth="xs" 
      open={openAddress}
      onClose={closeAddressDialog}
    >
      <Stack>
        {address && 
        <Table>
          <AddressEditor address={address} setAddress={setAddress} />
        </Table>
        }
        { order && <Button variant='contained' onClick={copy}>用订单地址覆盖此地址</Button>}
      </Stack>
    </RSDialog>
    </>
  )
  
}


//
function AddressEditor({ address, setAddress }) {

  const update = (data) => {
    updateAddress(data)
    setAddress({
      ...address,
      ...data
    })
  }

  const entityProps = [
    {
      title: "Forename",
      entity: "forename",
      format: name
    },
    {
      title: "Surname",
      entity: "surname",
      format: name
    },
    {
      title: "Email",
      entity: "email",
      format: name
    },
    {
      title: "Mobile",
      entity: "tel"
    },
    {
      title: "Address L1",
      entity: "address_1",
      format: address_str,
      max: 34
    },
    {
      title: "Address L2",
      entity: "address_2",
      format: address_str,
      max: 34
    },
    {
      title: "Address L3",
      entity: "address_3",
      format: address_str,
      max: 34
    },
    {
      title: "City",
      entity: "city",
      format: address_str,
      max: 34
    },
    {
      title: "County",
      entity: "county",
      format: address_str,
      max: 34
    },
    {
      title: "Country",
      entity: "country",
      format: address_str,
    },
    {
      title: "Postcode",
      entity: "postcode",
      format: postcode
    },
    {
      title: "Instructions",
      entity: "instructions",
    },
  ]

  const commonProps = {
    item: address,
    callback: update
  }

  return (
  <TableBody>
    { entityProps.map( (props, index) => 
      <RSEditableTableRow
        key={index}
        {...props}
        {...commonProps}
      />
    
    )}
  </TableBody>
  )
}