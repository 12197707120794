import { Dashboard } from '@mui/icons-material';
import { MenuItem } from './MenuItems';

export default function MenuItemDashboard() {

  const menu = {
    text: '后台首页',
    path: '/dashboard',
    icon: Dashboard
  }

  return <MenuItem menu={menu} />
}
