import { deletePurchaseItem, savePurchaseItem } from "api/stock"
import { useState, forwardRef, useImperativeHandle } from "react"
import RSDialog from "rsui/RSDialog"
import RSForm from "rsui/RSForm"


const PurchaseItemEditor = forwardRef((props, ref) => {

  const { callback } = props
  const [ open, setOpen ] = useState(false)
  const [ item, setItem ] = useState()

  const closeDialog = () => setOpen(false)

  useImperativeHandle(ref, ()=>({
    openDialog(item) {
      setItem(item)
      setOpen(true)
    }
  }))

  const handleSave = async (data) => {
    await savePurchaseItem(data)
    closeDialog()
    callback()
  }

  const handleDelele = async () => {
    await deletePurchaseItem(item.id)
    closeDialog()
    callback()
  }

  const form = [
    {
      contents: [
        {
          type: 'input',
          name: 'box',
          label: '箱数',
          required: true
        },
        {
          type: 'input',
          name: 'qty',
          label: '件数',
          required: true
        }
      ]
    },
    {
      contents: [
        {
          type: 'input',
          name: 'price',
          label: '总价',
          required: true
        },
        {
          type: 'date',
          name: 'expire_date',
          label: '保质期'
        },
      ]
    }
  ]

  return (
  <>
  {item &&
  <RSDialog 
    title={item.name_cn}
    open={open}
    onClose={closeDialog}
  >
    <RSForm 
      data={item} 
      fields={form} 
      onSave={handleSave}
      onDelete={handleDelele}
    />
  </RSDialog>
  }
  </>
  )

})

export default PurchaseItemEditor