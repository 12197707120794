/* 产品图片管理 */
import { useState, useEffect, useRef } from 'react'
import { Grid, IconButton, Stack } from '@mui/material'

import RSCard from 'rsui/RSCard';

import DragHandleOutlinedIcon from '@mui/icons-material/DragHandleOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

import {
  DndContext, 
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  useSortable,
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';


import {CSS} from '@dnd-kit/utilities';
import { deleteProductImg, getProductImgs, sortProductImgs, uploadProductImg } from 'api/product';

export default function ProductEditImgs({product}) {

  return (
    <>
      <ProductPhotosCard type={0} product={product} />
      <ProductPhotosCard type={2} product={product} />
    </>
  )
}

function ProductPhotosCard({type, product}) {
  const [imgs, setImgs] = useState()
  const inputFileRef = useRef()

  const loadImgs = async () => {
    const data = await getProductImgs({type:type, product_id:product.id})
    if(data) setImgs(data)
  }

  useEffect(() => {
    loadImgs() // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product])


  const TitleStr = () => {
    if(type === 0) return "图片";
    else if(type === 2) return "海报";
  }

  // 上传按钮
  const UploadBtn = () => 
    <>
      <input
        accept="image/*"
        id={"files_"+type}
        type="file"
        multiple
        ref={inputFileRef}
        onChange={handleUpload}
        style = {{display:'none'}}
      />
      <label htmlFor={"files_"+type}>
        <IconButton size="small" icon={<FileUploadOutlinedIcon />} onClick={handleClick}><FileUploadOutlinedIcon fontSize='small' /></IconButton>
      </label>
    </>
  
  const handleClick = async (e) => {
    inputFileRef.current.click();
  }

  const handleUpload = async (e) => {
    const files = e.target.files;
    const formData = new FormData()
    formData.append('id', product.id)
    formData.append('type', type)

    for(let i=0; i<files.length; i++) {
      formData.append('files[]', files[i])
    }

    await uploadProductImg(formData)
    loadImgs()
  }

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <RSCard 
      title={<TitleStr />} 
      action={<UploadBtn />}
    >
      {imgs && 
      <DndContext 
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        
        <SortableContext 
          items={imgs}
          strategy={verticalListSortingStrategy}
        >
          <Grid container>
        {imgs.map((img, index) => (
          <SortableItem key={index} img={img}  loadImgs={ loadImgs} />
        ))}
        </Grid>
        </SortableContext>
        
      </DndContext>
    }
    </RSCard>
  )

  function handleDragEnd(event) {
    
    const {active, over} = event;
   
    if (active.id !== over.id) {

      const newIndex = over.data.current.sortable.index;
      const oldIndex = active.data.current.sortable.index;
     
      const newImgs = arrayMove(imgs, oldIndex, newIndex)
      setImgs(newImgs);
      sortProductImgs({imgs: newImgs, product_id: product.id, type: type})
    }
  }
}


export function SortableItem(props) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: props.img.id});
  
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  // 删除图片
  const handleDel = async () => {
    if(window.confirm("确定删除?")) {
      await deleteProductImg(props.img.id)
      props.loadImgs()
    }
  }

  const DelBtn = () => <IconButton size="small" onClick={handleDel}><DeleteForeverOutlinedIcon /></IconButton>


  return (
      <Grid item xs={3} ref={setNodeRef}  {...attributes} >
        <RSCard action={
          <Stack direction="row">
            <DragHandleOutlinedIcon fontSize='small' {...listeners} />
            <DelBtn />
          </Stack>
        } >
        
          <img src={props.img.url} width="100%" alt="" />
        </RSCard>
      </Grid>
  );
}