/* 优惠券 数据 */

import { createSlice } from '@reduxjs/toolkit'
import store from 'redux/store'

export const slice = createSlice({
  name: 'coupon',
  initialState: {
    couponDialog: false,
    coupon: null,
    coupons: []
  },
  reducers: {
    setCouponDialog: (state, action) => {
      state.couponDialog = action.payload
    },
    setCoupon: (state, action) => {
      state.coupon = action.payload
    },
    setCoupons: (state, action) => {
      state.coupons = action.payload
    }
  },
})

export const { 
  setCouponDialog, 
  setCoupon, 
  setCoupons } = slice.actions

export const couponStates = state => state.coupon

export default slice.reducer

export function openCouponEditor(coupon) {
  store.dispatch(setCoupon(coupon))
  store.dispatch(setCouponDialog(true))
}

export function closeCouponEditor() {
  store.dispatch(setCouponDialog(false))
  store.dispatch(setCoupon(null))
}

