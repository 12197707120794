import RSCard from 'rsui/RSCard'
import RSTable from 'rsui/RSTable'

// **************************************************************************
// 销售表
export default function SalesTable ({ title, data, action }) {

  const columns = [
    {
      title: 'YMD',
      render: (order) => (order.year + (order.month?'-'+order.month:'') + (order.day?'-'+order.day:'') )
    },
    {
      title: 'Sales',
      render: (order) => ('£'+order.sales)
    },
    {
      title: 'Refunds',
      render: (order) => ('£'+order.refunds)
    },
    {
      title: 'Gross',
      render: (order) => ('£'+order.gross)
    },
    {
      title: 'Orders',
      dataIndex: 'orders',
    }
  ]

  return (
    <RSCard title={title} action={action}>
      {data && <RSTable data={data} columns={columns} />}
    </RSCard>
  )
}