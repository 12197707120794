import { Card, Typography, Chip, Stack, Box } from '@mui/material'

/**
 * 数据面板
 * @param { component } icon 图标
 * @param { string } label 面板名称
 * @param { string } num 显示的数据
 * @param { string } color Chip颜色
 * @param { string } to 跳转的路径
 * @returns 
 */
export default function DashboardPanel({ 
  icon,
  label,
  num, 
  color = 'default',
  onClick = ()=>{}
}) {

  return (
    <Card sx={{bgcolor: '#eee', padding: 2}} onClick={onClick}>
      <Stack direction='row' spacing={1} alignItems='center'>
        {icon}
        <Typography variant='body2'>{label}</Typography>
        <Box flex={1}></Box>
        {num !== null && <Chip color={color} size="small" label={num} />}
      </Stack>
    </Card>
  )
}