import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"

/*
 const columns = [
    {
      title: 'delivery_country_code',
      render: () => ("GB"),
    },
    {
      title: 'delivery_post_code',
      dataIndex: 'postcode',
    },
  ]
*/
export default function RSTable({data, columns, rows}) {

  return (
    <Table>
      <TableHead>
        <TableRow>
          {columns.map(col=> <TableCell key={col.title} width={col.width}>{col.title}</TableCell>)}
        </TableRow>
      </TableHead>
      {data && 
      <TableBody>
        {data.map((d, index) => 
        <TableRow key={d.id+'-'+index}>
          {columns.map(col=> 
            <TableCell 
              key={col.title+'-'+index}
            >
              {d[col.dataIndex]}
              {col.render && col.render(d, index)}
            </TableCell>)}
        </TableRow>
        )}
      </TableBody>
      }
      {rows}
    </Table>
  )
}