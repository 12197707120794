/* 客户基本信息卡片 */
import { Avatar, Typography, Box, Grid, Stack } from '@mui/material'
import RSTextEditor from 'rsui/RSTextEditor'
import { useEffect, useState } from 'react'
import CustomerChips from './CustomerChips'
import CustomerTypeIcon from './CustomerTypeIcon'
import { updateCustomer } from 'api/customer'

export default function CustomerInfo ({ customer }) {

  const [note, setNote] = useState('')

  useEffect(() => {
    if(customer) setNote(customer.note)
  }, [customer])

  const callback = (data) => {
    setNote(data.note)
    updateCustomer(data)
  }

  return (
    <>
    { customer && 
    <Grid key={customer.id} container spacing={1}>
      <Grid item xs={2} align="center">
        <CustomerAvatar id={customer.id} picture={customer.picture} />
      </Grid>

      <Grid item lg={10}>
        <Stack spacing={1}>
          <Stack direction="row" spacing={1} alignItems="center">
            <CustomerTypeIcon type={customer.tp_type} />
            <Typography>{customer.name}</Typography>
            <Box flexGrow={1}></Box>
            <CustomerChips customer={customer}/>
          </Stack>
          <RSTextEditor 
            item={customer} 
            label="客户信息备忘"
            entity='note'
            text={note}
            callback={callback}
            multiline
          />
        </Stack>
      </Grid>
    </Grid>
    }
    </>
  )
}

export const CustomerAvatar = (props) => {
  const { id, picture } = props
  return (
    <Stack spacing={1} alignItems='center'>
      <Avatar src={picture} />
      { id && <Typography variant='caption'>{id.padStart(5, '0')}</Typography>}
    </Stack>
  )
}