// Router
import router from "router"
import { RouterProvider } from 'react-router-dom'

/****************************************************************************************/
/* Firebase v9 */
import { initializeApp } from 'firebase/app'
import firebaseConfig from 'utils/firebaseConfig'

initializeApp(firebaseConfig) // 应用启动时初始化一次

export default function App() {
  return <RouterProvider router={router} />
}