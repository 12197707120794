import { Button, Chip, Stack } from '@mui/material'
import RSTable from 'rsui/RSTable';
import RSDialog from 'rsui/RSDialog';
import { useEffect, useState } from 'react';
import { downloadAddress } from 'api/order';

export default function AddressChip({logisticsName, orders, columns, filename}) {
  
  const [open, setOpen] = useState(false)
  const [ noHoldingOrders, setNoHoldingOrders ] = useState([])

  const openDialog = () => {
    setOpen(true)
  }

  const closeDialog = () => {
    setOpen(false)
  }

  useEffect(() => {
    const tempOrders = []
    orders.forEach(order => {
      if(order.hold === '0') {
        tempOrders.push(order) 
      }
    });
    setNoHoldingOrders(tempOrders)
  }, [orders])

  const downloadCSV = async () => {
    await downloadAddress({orders: noHoldingOrders, type: logisticsName}, (filename?filename:logisticsName)+'.csv')
    closeDialog()
  }

  const print = () => window.print()

  const Actions = () => (
    <Stack direction="row" spacing={1}>
      <Button variant='contained' onClick={print}>打印</Button>
      <Button variant='contained' onClick={downloadCSV}>下载</Button>
    </Stack>
  )

  return (
    <>
      {columns && <Chip label="地址" onClick={openDialog} />}
      <RSDialog
        title={logisticsName+"地址列表(暂停的不显示) "}
        open={open}
        onClose={closeDialog}
        fullScreen
        actions={<Actions />}
      >
        {noHoldingOrders && columns && <RSTable data={noHoldingOrders} columns={columns} />}
      </RSDialog>
    </>
  )
}