/* 供货商 接口 */

import call from 'utils/call' 

/***************************************************************/
export async function getSupplierList() {
  console.log('供货商列表')
  return await call('supplier/list')
}

/***************************************************************/
export async function updateSupplier(data) {
  console.log('更新供货商信息')
  await call('supplier/update', data)
}

/***************************************************************/
export async function getSupplierProducts(supplierID) {
  console.log('供货商产品列表')
  return await call('supplier/products/'+supplierID)
}

/***************************************************************/
export async function getSupplierOptions() {
  console.log('获取 供货商选项')
  return await call('supplier/options')
}

/***************************************************************/
export async function unlinkSupplierProduct(data) {
  console.log('删除 供货商的一个产品')
  return await call('supplier/unlinkProduct', data)
}