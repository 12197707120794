import { useEffect, useState } from "react";
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import { getProductSKUCount } from "./api";
import DashboardPanel from "../DashboardPanel";
import { useNavigate } from "react-router-dom";

/**
 * 上架产品SKU总数
 * @returns 
 */
export default function ProductSKUCount() {
 
  const [num, setNum] = useState(null)
  const navigate = useNavigate()

  const fetchData = async () => {
    const data = await getProductSKUCount()
    if(data) setNum(data)
  }

  useEffect(() => {
    fetchData()
  }, []) 

  const handleClick = () => {
    navigate('/product/management')
  }


  return (
    <DashboardPanel 
      icon={ <ProductionQuantityLimitsIcon /> }
      label="上架产品SKU"
      fetchData={fetchData}
      num={num}
      onClick={handleClick}
    />
  )
}