import { useEffect, useState } from "react";
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import { getSoldOutPercentage } from "./api";
import DashboardPanel from "../DashboardPanel";
import { useNavigate } from "react-router-dom";

/**
 * 产品售空比例
 * @returns 
 */
export default function SoldoutPercentage() {
  const [color, setColor] = useState('default')
  const [num, setNum] = useState(null)
  const navigate = useNavigate()

  const fetchData = async () => {
    const data = await getSoldOutPercentage()
    if(data) {
      setNum(data+'%')
      const rate = Number(data)
      if(rate>10) setColor('error')
      else if(rate>5) setColor('warning')
      else setColor('default')
    }
  }

  const handleClick = () => {
    navigate('/product/management')
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <DashboardPanel 
      icon={ <ProductionQuantityLimitsIcon /> }
      label="售空产品比例"
      num={num}
      color={color}
      onClick={handleClick}
    /> 
  )
}