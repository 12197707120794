
import { useState, useEffect } from "react"
import { Typography, TextField, InputAdornment, IconButton } from "@mui/material"
import { EditOutlined } from "@mui/icons-material"

// 
/**
 * 便捷文字编辑器，文字和编辑框自动切换
 * @param { object } item 编辑的对象
 * @param { string } entity 需要编辑的字段
 * @param { function } callback 文件编辑后的方法
 * @param { string } prefix optional 前缀
 * @returns 
 */
export default function RSTextEditor(props) {

  const {
    item,
    entity,
    callback,
    format,
    color,

    prefix,
    suffix,
    multiline,
    label,
    max,
    width, // input 框长度 如 '10px'
    
    onlyIcon
  } = props

  const [editing, setEditing] = useState(false)
  const [value, setValue] = useState('')

  useEffect(() => {
    setValue( item[`${entity}`]?(item[`${entity}`]):'' )
  }, [item])

  const click = () => {
    setEditing(true)
  }

  const change = (e) => {
    setValue(e.target.value)
  }

  const press = (e) => {
    if (e.key === 'Enter' && e.ctrlKey) {
      setValue(value+'\n')
      //e.preventDefault(); // 阻止默认的 Enter 操作（比如提交表单）
      // 在这里插入换行逻辑，可以是添加换行符 '\n' 到文本框内容
    } else if (e.key === 'Enter' && !e.ctrlKey) {
      // 在这里插入执行 update 函数的逻辑
      update();
    }
  }

  const update = async () => {
    setEditing(false)
    let v = ''
    if(format) v = format(value)
    else v = value
    if(callback) {
      callback({
        ...item,
        [`${entity}`]: v
      })
    }
  }

  const blur = () => {
    update()
  }

  return (
    <>
    {!editing && !onlyIcon &&
      <Typography 
        onClick={click} 
        sx={{
          cursor: 'pointer', 
          whiteSpace: 'pre-line'
        }}
        color={color?color:'default'}
      >
        {prefix}{(!value || (value && value.trim()===""))?'-':value}{suffix}
      </Typography>
    }

    {
      !editing && onlyIcon && <IconButton onClick={click}><EditOutlined /></IconButton>
    }

    {editing && 
      <TextField 
        label={label} 
        value={value} 
        onChange={change} 
        onKeyDown={press} 
        autoFocus={true} 
        onBlur={blur} 
        size="small" 
        multiline={multiline}
        sx={{width: width?width:'100%'}}
        InputProps={{
          startAdornment: prefix?(
            <InputAdornment position="start">
              {prefix}
            </InputAdornment>
          ):''
        }}
      />
    }
    { max && value.length > max && <Typography color="error">字符过长</Typography>}
    </>
  )
}