import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import { Chip } from '@mui/material'
import RSDialog from 'rsui/RSDialog'
import { useEffect, useState } from 'react'
import { google_map_api_key } from 'utils/configs'

// *******************************************************************************************************
// 按钮 订单地图

export default function MapChip({logisticsName, orders, driver}) {

  const [open, setOpen] = useState(false)
  const [ noHoldingOrders, setNoHoldingOrders ] = useState([])

  const containerStyle = {
    width: '100%',
    height: '750px'
  };

  // London
  const center = {
    lat: 51.4508609,
    lng: 0.1701028
  };

  const openDialog = () => {
    setOpen(true)
  }

  const closeDialog = () => {
    setOpen(false)
  }

  useEffect(() => {
    const tempOrders = []
    orders.forEach(order => {
      if(order.hold === '0') {
        tempOrders.push(order) 
      }
    });
    setNoHoldingOrders(tempOrders)
  }, [orders])


  return (
    <>
      <Chip label="地图" onClick={openDialog} />

      <RSDialog 
        open={open}
        title={logisticsName+"订单地图 "+(driver?driver.label:"")}
        maxWidth='lg' 
        fullWidth
        onClose={closeDialog}
      >
        { noHoldingOrders.length > 0 && 
          <LoadScript googleMapsApiKey={google_map_api_key}>
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={11}
            >
            
              {
                noHoldingOrders.map((location, index) => (
                  <Marker 
                    key={index} 
                    title={location.postcode} 
                    position={{lat: Number(location.lat), lng: Number(location.lng)}} 
                  />
                ))
              }
            </GoogleMap>
          </LoadScript>
        }
      </RSDialog>
    </>
  )
}