import { Chip } from "@mui/material";
import { addNewProduct } from "api/product";
import { startLoading, stopLoading } from "utils/layout";

export default function AddNewProductChip({callback}) {

  const handleClick = async () => {
    startLoading()
    await addNewProduct()
    stopLoading()
    if(callback) callback()
  }
  return <Chip label="新建" onClick={handleClick} />
}