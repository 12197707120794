import { useRef } from "react"
import { Chip } from "@mui/material"
import RSTable from "rsui/RSTable"
import MarginChip from "components/product/chips/MarginChip"
import VATChip from "components/product/chips/VATChip"
import ProductName from "components/product/components/ProductName"
import PurchaseItemEditor from "./PurchaseItemEditor"
import ExpiryDateChip from "components/product/chips/ExpiryDateChip"


export default function PurchaseDetails({ purchase, callback }) {

  const ref = useRef()

  // *************************************************************
  // 编辑产品按钮
  const EditChip = ({item}) => {
  
    const handleClick = () => {
      const purchaseItem = {
        ...item,
        price: item.total_cost_price
      }
      ref.current.openDialog(purchaseItem)
    }

    return <Chip label="编辑" onClick={handleClick} />
  }

  const cols = [
    {
      title: '#',
      render: (item, index) => (index+1)
    },
    {
      title: '产品',
      render: (item) => <ProductName
                          product={item}
                        />
    },
    {
      title: 'VAT',
      render: (item) => <VATChip rateID={item.vat} />
    },
    {
      title: '保质期',
      render: (item) => <ExpiryDateChip product={item} />
    },
    {
      title: '箱',
      dataIndex: 'box',
    },
    {
      title: '进价',
      render: (item) => ('£'+item.total_cost_price)
    },
    {
      title: '件',
      dataIndex: 'qty',
    },
    {
      title: '进价/件',
      render: (item) => ('£'+item.cost_price)
    },
    {
      title: '销价/件',
      render: (item) => ('£'+item.price)
    },
    {
      title: '利润率',
      render: (item) => <MarginChip product={item} />
    },
    {
      title: '',
      render: (item) => <EditChip item={item} />
    },
  ]

  return <>
    <RSTable data={purchase.items} columns={cols} />
    <PurchaseItemEditor ref={ref} callback={callback} />
  </>
}