import { useEffect, useState, useRef } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Stack, Grid, Chip, Typography } from "@mui/material"
import RSCard from "rsui/RSCard"
import { today } from "utils/datetime"
import { getPurchaseDetails } from "api/stock"
import PurchaseDetails from "./components/PurchaseDetails"
import { startLoading, stopLoading } from "utils/layout"
import { ProductSearchChip } from "components/product/search/ProductSearchDrawer"
import PurchaseItemEditor from "./components/PurchaseItemEditor"
import PurchaseInfoEditor from "./components/PurchaseInfoEditor"


export default function PurchaseDetailsPage() {

  const { purchase_id } = useParams()
 
  const navigate = useNavigate()
  const [purchase, setPurchase] = useState()
  const ref = useRef()

  const loadData = async () => {
    startLoading()
    const data = await getPurchaseDetails(purchase_id)
    stopLoading()
    if(data) setPurchase(data)
  }

  useEffect(() => {
    loadData()
  }, [purchase_id])


  // *************************************************************
  // 返回按钮
  const BackChip = () => { 
    const back = () => navigate('/stock/purchase')
    return <Chip label="返回" onClick={back} />
  }

  // *************************************************************
  // 新建产品按钮
  const ProductChip = () => {
  
    const handleClick = (product) => {
      const item = {
        po_id: purchase.id,
        name_cn: product.name_cn,
        product_id: product.id,
        box: 1,
        expire_date: today()
      }
      ref.current.openDialog(item)
    }

    return (
      <>
      <ProductSearchChip onSelect={handleClick} />
      <PurchaseItemEditor ref={ref} callback={loadData} />
      </>
    )
  }

  const Action = () => 
  <Stack direction="row">
    <BackChip />
    <PurchaseInfoEditor purchase={purchase} callback={loadData} />
    <ProductChip />
  </Stack>

  return (
  <Grid container>
    <Grid item xs={10}>
      {purchase && 
      <RSCard
        title={ purchase.name + ' 采购清单 '+purchase.date }
        action={<Action />}
      >
        <PurchaseDetails purchase={purchase} callback={loadData} />
      </RSCard>
      }
    </Grid>

    <Grid item xs={2}>
      <RSCard title="提醒">
        <Stack>
          <Typography>进货记录会影响产品的数据，包括单件成本价，保质期，库存。</Typography>
          <Typography>表格里的“销价/件”是产品折扣后的实际售价，利润率是按照实际售价和此处的成本价计算获得。</Typography>
          <Typography>采购清单里的产品会放到进货商的总产品列表里。</Typography>
        </Stack>
      </RSCard>
    </Grid>
  </Grid>
  )
}