import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import MenuItems from "./MenuItems";

const menu = {
  "text": "物流管理",
  "icon": LocalShippingOutlinedIcon,
  "permission": "staff",
  "sub": [
    {
      "text": "自送区",
      "path": "/delivery/local-area",
    },
    {
      "text": "偏远地区",
      "path": "/delivery/special-area",
    }
  ]
}

export default function MenuItemShipping() {
  return <MenuItems menu={menu} />
}
