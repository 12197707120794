import { createBrowserRouter } from "react-router-dom";
import Layout from "layout/Layout";

// 特殊单页
import NewOrdersPage from "pages/new-orders/NewOrdersPage";
import LondonDeliveryPage from "pages/london-delivery/LondonDeliveryPage";


// Promotion
import BuyMore from "pages/promotion/buy-more/page";
import Voucher from "pages/promotion/voucher/page";
import Coupon from "pages/promotion/coupon/page";
import Wallet from "pages/promotion/wallet/page";


// Analytics
import AnalyticSales from "pages/analytics/sales/AnalyticSales";

// Print Page
import PickerPage from "pages/print/order-picker/PickerPage";
import ReceiptPage from "pages/print/order-receipt/ReceiptPage";
//import ProductEdit from "pages/product/ProductEdit";
import ProductTags from "pages/product/ProductTags";



// Stock
import PurchasePage from "pages/stock/purchase/PurchaseListPage";
import PurchaseDetailsPage from "pages/stock/purchase/PurchaseDetailsPage";
import StockExpiryPage from "pages/stock/expiry/StockExpiry";
import StockRecordsPage from "pages/stock/records/StockRecordsPage";

// Setting
import EmailTemplate from "pages/settings/email-template/EmailTemplate";

// Print
import RSOrderLabelsPrintPage from "pages/print/order-label/RSOrderLabelsPrintPage";
import Dashboard from "pages/dashboard/Dashboard";
import DailySales from "pages/analytics/sales/components/DailySales";
import MonthSales from "pages/analytics/sales/components/MonthSales";
import DailyTakings from "pages/analytics/sales/components/DailyTakings";
import AnnualSales from "pages/analytics/sales/components/AnnualSales";
import OrdersMap from "pages/analytics/orders-map/OrdersMap";

// 物流管理
import LocalArea from "pages/delivery/local-area/LocalArea";
import SpecialArea from "pages/delivery/special-area/SpecialArea";
import SupplierPage from "pages/stock/supplier/SupplierPage";
import ProductImg from "pages/test/product-img/ProductImg";
import ProductManagement from "pages/product/management/ProductManagement";
import ProductCost from "pages/test/product-cost/ProductCost";
import ProductData from "pages/test/product-data/ProductData";
import ExpiryCheckPrintPage from "pages/print/expiry-check/ExpiryCheckPrintPage";
import Banner from "pages/website/banner/Banner";
import Versions from "pages/settings/version/page";


const router = createBrowserRouter([

  {
    path: "/",
    element: <Layout />,
    children: [
      // Dashboard
      {
        path: '/dashboard',
        element: <Dashboard />
      },
      // Order
      {
        path: '/new-orders',
        element: <NewOrdersPage />
      },
      {
        path: '/london-delivery',
        element: <LondonDeliveryPage />
      },
      // Chat
      {
        path: '/chat',
        element: <NewOrdersPage />
      },
      // Product
      {
        // 产品管理
        path: '/product/management',
        element: <ProductManagement />
      },
      {
        path: '/product/tag',
        element: <ProductTags />
      },
      // Promotion
      {
        path: '/promotion/buy-more',
        element: <BuyMore />
      },
      {
        path: '/promotion/voucher',
        element: <Voucher />
      },
      {
        path: '/promotion/coupon',
        element: <Coupon />
      },
      {
        path: '/promotion/wallet',
        element: <Wallet />
      },
      // Web
      {
        path: '/website/banner',
        element: <Banner />
      },
      // Stock
      {
        path: "/stock/records",
        element: <StockRecordsPage />
      },
      {
        path: "/stock/purchase",
        element: <PurchasePage />
      },
      {
        path: "/stock/purchase-details/:purchase_id",
        element: <PurchaseDetailsPage />
      },
      {
        path: "/stock/expiry",
        element: <StockExpiryPage />
      },
      {
        path: "/stock/supplier",
        element: <SupplierPage />
      },
      // Shipping
      {
        path: '/delivery/local-area',
        element: <LocalArea />
      },
      {
        path: '/delivery/special-area',
        element: <SpecialArea />
      },
      // Analytics
      {
        path: '/analytics/sales',
        element: <AnalyticSales />,
        children: [{
          path: "/analytics/sales/day",
          element: <DailySales />
        },{
          path: "/analytics/sales/month",
          element: <MonthSales />
        },{
          path: "/analytics/sales/annual",
          element: <AnnualSales />
        },{
          path: "/analytics/sales/dailyTakings",
          element: <DailyTakings />
        }]
      },
      {
        path: 'analytics/orders-map',
        element: <OrdersMap />
      },
      // Setting
      {
        path: '/setting/email-template',
        element: <EmailTemplate />
      },
      {
        path: '/setting/versions',
        element: <Versions />
      },
      // Test 测试内容 临时
      {
        path: '/test/product-img',
        element: <ProductImg />
      },
      {
        path: '/test/product-cost',
        element: <ProductCost />
      },
      {
        path: '/test/product-data',
        element: <ProductData />
      }
    ]
  },
  {
    path: '/print/order-picker/:order_id',
    element: <PickerPage />
  },
  {
    path: '/print/order-receipt/:order_id',
    element: <ReceiptPage />
  },
  {
    path: '/print/order-label/:driver_id',
    element: <RSOrderLabelsPrintPage />
  },
  {
    path: '/print/expiry-check',
    element: <ExpiryCheckPrintPage />
  }
    
]);

export default router;

