import { createSlice } from '@reduxjs/toolkit'
import { viewOrder, updateOrder } from 'api/order'
import store from 'redux/store'
import { editOrderInNewOrders } from './newOrdersSlice'

export const slice = createSlice({
  name: 'order',
  initialState: {
    open: false,
    order: null
  },
  reducers: {
    setOpen: (state, action) => {
      state.open = action.payload
    },
    setOrder: (state, action) => {
      state.order = action.payload
    },
  },
})

export const { 
  setOpen,
  setOrder,
} = slice.actions

export const orderStates = state => state.order

export default slice.reducer

/***************************************************************/
// 读取订单
export async function loadOrder(order_id) {
  const order = await viewOrder(order_id)
  if(order) store.dispatch(setOrder(order))
}

/***************************************************************/
// 更新订单
export async function editOrder(data) {
  const order = store.getState().order.order
  store.dispatch(setOrder({
    ...order,
    ...data
  }))
  editOrderInNewOrders(data)
  await updateOrder(data)
}