import RSTable from "rsui/RSTable"
import { useState, useEffect } from "react"
import { getSupplierProducts, unlinkSupplierProduct } from "api/supplier"
import { startLoading, stopLoading } from "utils/layout"
import MarginChip from "components/product/chips/MarginChip"
import ProductName from "components/product/components/ProductName"
import { Chip, Typography } from "@mui/material"
import { date } from "utils/datetime"
import ExpiryDateChip from "components/product/chips/ExpiryDateChip"

export default function SupplierProducts({ supplierID }) {

  const [products, setProducts] = useState([])

  const cols = [
    {
      title: "SKU",
      dataIndex: "product_id"
    },
    {
      title: "产品",
      render: (product) => <ProductName product={product} />
    },
    {
      title: "保质期",
      render: (product) => <ExpiryDateChip product={product} />
    },
    {
      title: "库存",
      dataIndex: "stock_qty"
    },
    {
      title: "进价",
      render: (product) => ('£'+product.cost_price)
    },
    {
      title: "售价",
      render: (product) => ('£'+product.price)
    },
    {
      title: "折扣价",
      render: (product) => ('£'+(product.discount_price?product.discount_price:'-'))
    },
    {
      title: "利润率",
      render: (product) => <MarginChip product={product} />
    },
    {
      title: "最近上架",
      render: (product) => <Typography>{date(product.datetime)}</Typography>
    },
    {
      title: '',
      render: (product) => <>{product.hide === '1' && <Chip label="清除" onClick={()=>unlink(product)} />}</>
    }
  ]

  const unlink = async (item) => {
    startLoading()
    await unlinkSupplierProduct({supplier_id: supplierID, product_id: item.product_id})
    loadProducts()
  }

  const loadProducts = async () => {
    startLoading()
    const data = await getSupplierProducts(supplierID)
    stopLoading()
    if(data) setProducts(data)
  }

  useEffect(() => {
    loadProducts()
  }, [])


  return (
    <>
    {products && <RSTable data={products} columns={cols} />}
    </>
  )
}