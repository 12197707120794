import TerminalOutlinedIcon from '@mui/icons-material/TerminalOutlined';
import MenuItems from "./MenuItems";

const menu = {
  "text": "测试功能",
  "icon": TerminalOutlinedIcon,
  "sub": [
    {
      "text": "旧版数据兼容",
      "path": "/test/product-data"
    }, 
    {
      "text": "产品补图",
      "path": "/test/product-img"
    },  
    {
      "text": "产品补成本价",
      "path": "/test/product-cost"
    },
  ]
}

export default function MenuItemTest() {

  return <MenuItems menu={menu} />
}