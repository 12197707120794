import { useState, useEffect } from 'react'
import RSCard from 'rsui/RSCard'
import { getExpiryCheckList } from 'api/stock'
import ProductListExpiryCheck from './components/ProductListExpiryCheck'
import { Chip } from '@mui/material'
import { useNavigate } from 'react-router-dom'

// 临期产品列表 检查库存
export default function StockExpiry() {
  const days = 60
  const [purchaseItems, setPurchaseItems] = useState([])
  const navigate = useNavigate()

  const loadData = async () => {
    const data = await getExpiryCheckList(days)
    if(data) setPurchaseItems(data)
  }

  useEffect(() => {
    loadData()
  }, [])

  const Action = () => <Chip label="打印" onClick={print} />

  const print = () => {
    navigate('/print/expiry-check')
  }

  return (
  <RSCard title={"过期提醒 "+days+"天内到期"} action={<Action />}>
    <ProductListExpiryCheck products={purchaseItems} reload={loadData} />
  </RSCard>
  )
}