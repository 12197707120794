import { useEffect, useState } from "react";
import { Chip, Grid, Stack } from "@mui/material";
import RSCard from "rsui/RSCard";
import RSDialog from "rsui/RSDialog";
import RSTable from "rsui/RSTable";
import { getSupplierList } from "api/supplier";
import SupplierProducts from "./components/SupplierProducts";
import SupplierEditor from "./components/SupplierEditor";


export default function SupplierPage() {

  const [supplier, setSupplier] = useState(null)
  const [suppliers, setSuppliers] = useState([])

  const [ productsDialog, setProductsDialog ] = useState(false)


  const openProductsDialog = (supplier) => {
    setSupplier(supplier)
    setProductsDialog(true)
  }
  const closeProductsDialog = () => setProductsDialog(false)

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: '名称',
      dataIndex: 'name_cn',
    },
    {
      title: 'Name',
      dataIndex: 'name_en',
    },
    {
      title: "",
      render: (supplier) => (
      <Stack direction='row'>
        <SupplierEditor supplier={supplier} callback={loadData} />
        <Chip label="产品" onClick={()=>openProductsDialog(supplier)} />
      </Stack>
      )
    }
  ]

 
  const loadData = async () => {
    const data = await getSupplierList()
    if(data) setSuppliers(data)
  }

  useEffect(() => {
    loadData()
  }, [])


  const Actions = () => <SupplierEditor supplier={{}} callback={loadData} />

  return (
  <Grid container justifyContent="center" alignItems="center">
    <Grid item lg={6}>
      <RSCard title="供应商" action={<Actions />}>
        <RSTable data={suppliers} columns={columns} />
        <RSDialog
          title={supplier?(supplier.name_cn+'产品'):''}
          open={productsDialog}
          onClose={closeProductsDialog}
          maxWidth="xl"
        >
          {supplier && productsDialog && <SupplierProducts supplierID={supplier.id} />}
        </RSDialog>
      </RSCard>
    </Grid>
  </Grid>

  )
}