import { useEffect, useState } from 'react'

// UI 组件
import { 
  Autocomplete, 
  Button,  
  Stack,
  TextField,  
  InputAdornment, 
  Chip,
  Grid,
  IconButton,
  Box
} from '@mui/material'

// 自定义 组件
import RSDialog from 'rsui/RSDialog'
import RSCheckbox from 'rsui/RSCheckbox'
import { AddOutlined, RemoveOutlined } from '@mui/icons-material'
import { handleRefund } from 'api/order'
import { RefundReasonSelector } from './RefundReason'


// *******************************************************************************************************
// 退款功能

export default function RefundChip({ order, callback, setLoading }) {
  
  const [refund, setRefund] = useState({})
  const [refundItems, setRefundItems] = useState([])
  const [open, setOpen] = useState(false)

  const openDialog = () => {
    setOpen(true)
    setRefund({
      so_id: order.id, 
      amount: 0, // 退款总额
      refundShippingFee: false, // 运费退款（已经被包含在运费总额里了）
      reason: 0
    })
  }

  const closeDialog = () => {
    setOpen(false)
  }

 
  // 退款操作
  const refundHandle = async () => {
    if(refund.amount > 0 && window.confirm("确定退款"+refund.amount)) {
      setLoading(true)
      const res = await handleRefund(refund)
      setLoading(false)
      if(res) callback()
    }
  }

  const handleInput = (e) => {
    setRefund({
      ...refund,
      [`${e.target.name}`]: e.target.value
    })
  }


  const SearchInput = (props) => 
  <TextField 
    {...props} 
    label="搜索产品" 
    variant="outlined" 
    onKeyUp={(e)=>{
      if(e.key==="Enter") {
        const option = e.target.value.split(' ');
        const productID = option[0]
        addRefundItem(productID)
      }
    }} 
  />

  // 增加退款项目
  const addRefundItem = (productID) => {
    let exist = false
    // 已经有此项目了 略过
    refundItems.forEach((item) => {
      if(item.product_id === productID) {
        exist = true
      }
    })
    if(exist) return
    order.items.forEach((item)=>{
      if(item.product_id === productID) {
        let newItem = {...item}
        setRefundItems([...refundItems, newItem])
      }
    })
  }

  // 设置退款产品数量
  const setRefundItem = (refundItem, num) => {
    let tempRefundItems = refundItems
    for(let i=0; i<tempRefundItems.length; i++) {
      if(tempRefundItems[i].id === refundItem.id) {
        let qty = parseInt(tempRefundItems[i].qty) + num

        if(qty <= 0) {
          tempRefundItems.splice(i, 1) // 去掉元素
        } else {
          let maxQty = 0
          order.items.forEach((item)=>{
            if(item.id === refundItem.id) {
              maxQty = parseInt(item.qty)
            }
          })
          
          if(qty > maxQty) {
            qty = maxQty
          }
  
          tempRefundItems[i].qty = qty
  
        }
      }
    }
    setRefundItems([...tempRefundItems])
  }


  // 退款产品变化 重新计算退款总额
  useEffect(()=>{
    caculateRefundAmount() // eslint-disable-next-line react-hooks/exhaustive-deps
  },[refundItems])

  // 计算退款总额
  const caculateRefundAmount = () => {
    let amount = 0
    refundItems.forEach((item) => {
      amount += parseFloat(item.qty) * parseFloat(item.price)
    })
    if(refund.refundShippingFee) amount += parseFloat(order.shipping_fee) // 加上要退的运费
    setRefund({
      ...refund,
      amount: amount.toFixed(2),
      refundItems: refundItems
    })
  }
  
  const onRefundShippingFee = (bool) => {
    
    let shipping_fee = parseFloat(order.shipping_fee)
    let amount = parseFloat(refund.amount)
    let refund_shipping_fee = false
    let refund_shipping_fee_amount = 0
    if(bool) {
      amount += shipping_fee
      refund_shipping_fee = true
      refund_shipping_fee_amount = shipping_fee
    } else {
      amount -= shipping_fee
      refund_shipping_fee = false
      refund_shipping_fee_amount = 0
    }
    setRefund({
      ...refund,
      amount: amount.toFixed(2),
      refundShippingFee: refund_shipping_fee,
      refundShippingFeeAmount: refund_shipping_fee_amount
    })
  }

  const setRefundReason = (reason) => {
    setRefund({
      ...refund,
      reason: reason.key
    })
  }

  return (
    <>
    <Chip label="退款" onClick={openDialog} />
    <RSDialog 
      title="退款" 
      open={open}
      onClose={closeDialog}
      maxWidth="xs"
      fullWidth
    >
      {order && 
      <Stack>

        <Autocomplete
          options={order.items}
          getOptionLabel={(option) => (option.product_id+' '+option.name_cn)}
          renderInput={(params) => <SearchInput {...params} />}
          fullWidth
        />

        {refundItems.map(item => <RefundItem item={item} key={item.id} setItem={setRefundItem} />)}
 
        
        <Stack direction="row" alignItems="center">

          { order.shipping_fee !== "0.00" && 
            <RSCheckbox 
              label="退运费"
              onChange={onRefundShippingFee}
            />
          }

          <Box flexGrow={1} />

          <RefundReasonSelector onClick={setRefundReason} />

        </Stack>

        <TextField 
          fullWidth
          name="amount" 
          label="退款金额" 
          type="number"
          value={refund.amount}
          onChange={handleInput}
          InputProps={{
            startAdornment: <InputAdornment position="start">-£</InputAdornment>,
          }}
        />

        
        <Button onClick={refundHandle} variant="contained">退款</Button>
      
      </Stack>
      }
    </RSDialog>
    </>
  )
}

// 退款项目列表单行
function RefundItem(props) {
  const { item, setItem } = props
  
  return (
  <Grid container spacing={2}>
    <Grid item xs={5}>
      {'#'+item.product_id+' '+item.name_cn}
    </Grid> 
    
    <Grid item xs={4}>
      {'£'+item.price} x {item.qty} = {'£'+(parseFloat(item.price) * parseFloat(item.qty)).toFixed(2)}
    </Grid>
   
    <Grid item xs={3}>
      <IconButton onClick={()=>setItem(item, -1)} size="small" style={{marginLeft:5, marginRight:5}}>
        <RemoveOutlined />
      </IconButton>

      <IconButton onClick={()=>setItem(item, 1)} size="small" style={{marginLeft:5, marginRight:5}}>
        <AddOutlined />
      </IconButton>
    </Grid>
  </Grid>
  )
}