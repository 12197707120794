/* 产品的上下架 */

import { useEffect, useState } from 'react';
import { Chip } from '@mui/material'
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { updateProduct } from 'api/product';

/********************************************************************/

export default function OnShelfChip({ product, callback }) {

  const [onShelf, setOnshelf] = useState(true)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if(product.hide === '1') setOnshelf(false)
  }, [])
  
  const toggle = async () => {
    setLoading(true)
    await updateProduct({id: product.id, hide:onShelf?1:0})
    setOnshelf(!onShelf)
    if(callback) callback()
    setLoading(false)
  }

  return <>
  {
    loading?
    <Chip label={<FontAwesomeIcon icon={faEllipsis} fade />} />:
    <Chip label={onShelf?"下架":"上架"} variant={onShelf?"contained":"outlined"} onClick={toggle} />
  }
  </>

}