import { useEffect, useState } from 'react'

// Redux
import { useSelector } from 'react-redux'
import { orderStates } from 'redux/orderSlice'

// UI 组件
import { Box, Stack, Typography, Chip, LinearProgress } from '@mui/material'

// 自定义 组件
import RSCard from 'rsui/RSCard'
import PaymentChip from 'components/order/dialog/payment/components/PaymentChip'
import RefundChip from 'components/order/dialog/payment/components/RefundChip'
import { deleteTestOrder, getRefundRecords } from 'api/order'
import { showSuccess, startLoading, stopLoading } from 'utils/layout'
import { closeOrderDialog } from '../OrderDialog'
import RefundReason from './components/RefundReason'

// *******************************************************************************************************
// Card 支付信息

export default function OrderPaymentCard() {
  const [ records, setRecords ] = useState([])
  const { order } = useSelector(orderStates)
  const [loading, setLoading] = useState(false)

  useEffect(()=>{
    loadRecords()
  }, [order])

  const loadRecords = async () => {
    const data = await getRefundRecords(order.id)
    if(data) setRecords(data)
    setLoading(false)
  }

  const ListItem = ({label, item, note, minus}) => <Stack spacing={0}>
  <Stack direction='row' alignItems="center">
    <Box flexGrow={1}>
      <Typography>{label} <Typography variant='caption'>{item.datetime}</Typography></Typography>
    </Box>
    {minus && <RefundReason defaultKey={item.reason} />}
    <Typography color={minus?"red":"green"}>{minus?'-':''}£{item.amount}</Typography>
  </Stack>
  {note && note !== '' && <Typography variant='caption' style={{ whiteSpace: 'pre-line'}}>{note}</Typography>}
  </Stack>

  const delTestOrder = async () => {
    if(window.confirm('Are you sure?')) {
      startLoading()
      await deleteTestOrder(order.id)
      closeOrderDialog()
      stopLoading()
      showSuccess('已删除订单')
    }
  }

  const Actions = () => {
    return (
      <Stack direction='row' spacing={1}> 
        {order.so_transaction_type === 'Test' && <Chip label="删除" onClick={delTestOrder} />}
        <PaymentChip order={order} />
        <RefundChip order={order} callback={loadRecords} setLoading={setLoading} />
      </Stack>
    )
  }
    
  return (
    <RSCard title="支付记录" action={<Actions />}>
      <Stack>
        <ListItem label='支付' item={order} />
        {records.map(record =>
          <ListItem 
            key={record.id} 
            label='退款' 
            item={record} 
            note={record.note} 
            minus 
          />
        )}
        {loading && <LinearProgress />}
      </Stack>
    </RSCard>
  )
}