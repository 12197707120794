import { Typography } from '@mui/material'
import RSCard from 'rsui/RSCard'

// 代金券 备忘
export default function VoucherNote() {
  
  return (
  <RSCard title="代金券说明" >
    <Typography>一个Code只能给一个人使用一次</Typography>
  </RSCard>
  )
}