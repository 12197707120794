import { useEffect, useRef, useState } from "react";
import { addBanner, delBanner, getBanners, sortBanners, updateBanner } from "./api";
import RSCard from "rsui/RSCard";
import RSDialog from "layout/components/RSDialog";

import { Button, Grid, IconButton, Stack, TextField } from "@mui/material"
import DragHandleIcon from '@mui/icons-material/DragHandle';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddIcon from '@mui/icons-material/Add';

import { DndContext } from '@dnd-kit/core';
import { SortableContext, arrayMove, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';


export default function Banner() {

  return (
  <Stack>
    <Banners title="大Banner 1000x418" position={1} xs={3} />
    <Banners title="小Banner 500x232" position={3} xs={3} />
  </Stack>
  )
}

// Banner 列表
function Banners({title, position, xs}) {
  const [banners, setBanners] = useState(null)

  const loadData = async () => {
    const data = await getBanners(position)
    setBanners(data)
  }

  useEffect(()=>{
    loadData()
  }, [])

  function handleDragEnd(event) {
    const {active, over} = event
    if (active.id !== over.id) {
      const newIndex = over.data.current.sortable.index
      const oldIndex = active.data.current.sortable.index
      const newItems = arrayMove(banners, oldIndex, newIndex)
      setBanners(newItems)
      sortBanners(newItems)
    }
  }

  const add = async () => {
    await addBanner(position)
    loadData()
  }

  const Actions = () => (
    <IconButton onClick={add} size="small">
      <AddIcon />
    </IconButton>
  )

  return (
  <RSCard
    title={title}
    action={<Actions />}
  >
    {banners && 
    <DndContext onDragEnd={handleDragEnd}>
      <SortableContext items={banners} >
        <Grid container>
        {banners.map((banner, index) => 
          <SortableItem key={index} item={banner} xs={xs} loadBanners={loadData} />
        )}
        </Grid>
      </SortableContext>
    </DndContext>
    }
  </RSCard>)
}

// Banner item
function SortableItem({item, xs, loadBanners}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: item.id});
  
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const Actions = () => <Stack direction="row" spacing={1}>
    <BannerEdit banner={item} loadBanners={loadBanners} />
    <IconButton size="small" {...listeners}>
      <DragHandleIcon />
    </IconButton>
  </Stack>

  return (
    <Grid item xs={xs} ref={setNodeRef} style={style} {...attributes} >
      <RSCard 
        action={<Actions />} 
        headerCss={{
          paddingY: 1.5
        }}
        contentCss={{
          padding:0, 
          "&:last-child": {
            paddingBottom: 0
          }
        }}
      >
        <img src={item.img} width="100%" alt="Banner" />
      </RSCard>
    </Grid>
  )
}

// Banner 编辑窗口
function BannerEdit({banner, loadBanners}) {

  const ref = useRef()
  const uploadRef = useRef()
  const [data, setData] = useState(null)

  const open = () => {
    ref.current.open()
  }

  useEffect(() => {
    setData(banner)
  }, [])

  const handleInputChange = (e) => {
    setData({
      ...data,
      [`${e.target.id}`]: e.target.id === 'file'?e.target.files[0]:e.target.value
    })
  }

  const del = async () => {
    if(window.confirm('Sure?')) {
      await delBanner(banner.id)
      ref.current.close()
      loadBanners()
    }
  };

  const upload = ()=> {
    uploadRef.current.click()
  }

  const submit = async () => {
    const formData = new FormData()
    formData.append('id', data.id)
    formData.append('file', data.file)
    formData.append('url', data.url)
    formData.append('position', data.position)
    formData.append('tag', data.tag)
    await updateBanner(formData)
    ref.current.close()
    loadBanners()
  }

  return (
    <>
    <IconButton size="small" onClick={open}>
      <EditOutlinedIcon />
    </IconButton>
    
    <RSDialog title="Banner" ref={ref}>
      {data &&
      <Stack>

        {data.img && <img src={data.img} width="100%" alt="Banner" />}

        <TextField
          id="url" 
          label="关联 URL, 网站上跳转地址"
          value={data.url} 
          onChange={handleInputChange}
          fullWidth
        />

        <TextField
          id="tag" 
          label="关联 Tag, APP跳转定位"
          value={data.tag} 
          onChange={handleInputChange}
          fullWidth
          placeholder="特殊tag: 新品，优惠"
        />

        <Stack direction="row-reverse">
          <Button variant="contained" onClick={submit}>保存</Button>
          <Button variant="contained" onClick={upload}>图片</Button>
          <input
            accept="image/*"
            id="file"
            type="file"
            onChange={handleInputChange}
            style = {{display:'none'}}
            ref={uploadRef}
          />
          <Button variant="outlined" onClick={del}>删除</Button>
        </Stack>
      </Stack>
      }
    </RSDialog>
    </>
  )
}