import { getAuth, onAuthStateChanged } from "firebase/auth"
import { useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import RSLoader from "rsui/RSLoader"
import RSLabel from "./components/RSLabel"
import { Box } from "@mui/material"
import { getRSLabels } from "api/order"

export default function RSOrderLabelsPrintPage() {

  const auth = getAuth()
  const { driver_id } = useParams()
  const [orders, setOrders] = useState()
  const [driver, setDriver] = useState()
  const ref = useRef()

  const fetchData = async () => {
    const res = await getRSLabels(driver_id)
    if(res) {
      setOrders(res.orders)
      setDriver(res.driver)
      setTimeout(() => {
        window.print()
        window.close()
      }, 500)
    }
    ref.current.stopLoading()
  }

  const handleAuthStateChanged = async (user) => {
    if(user) {
      fetchData()
    } 
  }

  useEffect(() => {
    console.log('开始监听登录状态')
    ref.current.startLoading()
    const unsubscribe = onAuthStateChanged(auth, handleAuthStateChanged);
    return () => unsubscribe();
  }, [])

  return (
    <>
     { orders && driver && orders.map((order, index)=> 
        <Box key={order.id}>
          <RSLabel order={order} driver={driver} /> 
          {(index < (orders.length - 1)) && <div style={{pageBreakAfter: 'always'}}></div> }
        </Box>
      )}
    <RSLoader ref={ref} />
    </>
  )
}