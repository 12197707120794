/* 订单接口 */
import call, { download, upload } from 'utils/call' 

/***************************************************************/
export async function addANewOrder(customer_id) {
  console.log('新建订单')
  await call('order/addANewOrder', {customer_id:customer_id})
}

/***************************************************************/
export async function viewOrder(order_id) {
  console.log('订单详情')
  return await call('order/viewOrder/'+order_id)
}

/***************************************************************/
export async function updateOrder(data) {
  console.log('更新订单')
  await call('order/updateOrder', data) 
}

/***************************************************************/
export async function updateFees(data) {
  console.log('更新订单金额')
  await call('order/updateFees', data) 
}

/***************************************************************/
export async function updateAItem(data) {
  console.log('更新一个订单产品')
  return await call('order/updateAItem', data) 
}

/***************************************************************/
export async function delAItem(data) {
  console.log('删除一个订单产品')
  return await call('order/delAItem', data) 
}

/***************************************************************/
export async function getNewOrders() {
  console.log('获取新订单列表')
  return await call('order/getNewOrders')
}

/***************************************************************/
export async function getNewOrdersNum() {
  console.log('获取新订单数量和最新订单ID')
  return await call('order/getNewOrdersNum')
}

/***************************************************************/
export async function getReceipt(order_id) {
  console.log('获取收据')
  return await call('order/getReceipt/'+order_id)
}

/***************************************************************/
export async function copyOrder(orderID) {
  console.log('复制订单')
  await call('order/copy/'+orderID)
}

/***************************************************************/
export async function downloadAddress(data, filename) {
  console.log("下载地址csv")
  await download('order/downloadAddress', data, filename)
}

/***************************************************************/
export async function uploadTrackingNumbers(data) {
  console.log("上传追踪码")
  await upload('order/uploadTrackingNumbers', data)
}

/***************************************************************/
export async function getLatLng(order_id) {
  console.log("获取经纬度")
  return await call('order/getLatLng/'+order_id)
}

/***************************************************************/
export async function searchOrder(data) {
  console.log("搜索订单")
  return await call('order/search', data)
}

/***************************************************************/
export async function getRefundRecords(order_id) {
  console.log("获得退款记录")
  return await call('order/getRefundRecords/'+order_id)
}

/***************************************************************/
export async function deleteTestOrder(order_id) {
  console.log("删除测试订单")
  await call('order/deleteTestOrder/'+order_id)
}

/***************************************************************/
export async function handleRefund(data) {
  console.log("退款")
  return await call('refund/refund', data)
}

/***************************************************************/
export async function getRSLabels(driver_id) {
  console.log("获得贴标数据")
  return await call('order/getRSLabels/'+driver_id)
}

/***************************************************************/
export async function getOrderNoticeList(order_id) {
  console.log("获得订单通知记录")
  return await call('order/getOrderNoticeList/'+order_id)
}


