import { createSlice } from '@reduxjs/toolkit'
import { today } from 'utils/RSDate'

// 产品
export const slice = createSlice({
  name: 'product',
  initialState: {
    dialog: false, // 编辑窗口
    search: false, // 搜索窗口
    searchCallback: null,
    product: null,

    // Discount Dialog
    showDiscountDialog: false,
    discountProductID: null,
    discountProductName: '',
    discountDate: today(),
    discountNote: '',
    discountTag: '76',
    discountCallback: null,


    products: [],
    api: 'product/list/',
    page: 1,
    total: 0,
    keywords: ''
  },
  reducers: {
    setDialog: (state, action) => {
      state.dialog = action.payload
    },
    setSearch: (state, action) => {
      state.search = action.payload
    },
    setSearchCallback: (state, action) => {
      state.searchCallback = action.payload
    },
    setProduct: (state, action) => {
      state.product = action.payload
    },
    setProducts: (state, action) => {
      state.products = action.payload
    },
    setTotal: (state, action) => {
      state.total = action.payload
    },
    setPage: (state, action) => {
      state.page = action.payload
    },
    setApi: (state, action) => {
      state.api = action.payload
    },
    setKeywords: (state, action) => {
      state.keywords = action.payload
    },

    // Discount Dialog
    setShowDiscountDialog: (state, action) => {
      state.showDiscountDialog = action.payload
    },
    setDiscountProductID: (state, action) => {
      state.discountProductID = action.payload
    },
    setDiscountProductName: (state, action) => {
      state.discountProductName = action.payload
    },
    setDiscountTag: (state, action) => {
      state.discountTag = action.payload
    },
    setDiscountDate: (state, action) => {
      state.discountDate = action.payload
    },
    setDiscountNote: (state, action) => {
      state.discountNote = action.payload
    },
    setDiscountCallback: (state, action) => {
      state.discountCallback = action.payload
    },
  },
})

export const { 
  setDialog, 
  setSearch, 
  setSearchCallback,
  // Discount Dialog
  setShowDiscountDialog,
  setDiscountProductID,
  setDiscountProductName,
  setDiscountTag,
  setDiscountDate,
  setDiscountNote,
  setDiscountCallback,

  setProduct,
  setProducts,
  setTotal,
  setPage,
  setApi,
  setKeywords
} = slice.actions

export const productStore = state => state.product
export default slice.reducer
