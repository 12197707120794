const columns = [
  {
    title: 'CompanyOrName',
    dataIndex: 'address_1',
  },
  {
    title: 'Attention',
    dataIndex: 'name',
  },
  {
    title: 'Address1',
    dataIndex: 'address_1',
  },
  {
    title: 'Address2',
    dataIndex: 'address_2',
  },
  {
    title: 'Address3',
    dataIndex: 'address_3',
  },
  {
    title: 'City',
    dataIndex: 'city',
  },
  {
    title: 'PostalCode',
    dataIndex: 'postcode',
  },
  {
    title: 'County',
    dataIndex: 'county',
  },
  {
    title: 'Country',
    render: () => ("GB"),
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'SendEmail',
    render: () => ("Y"),
  },
  {
    title: 'Service',
    render: () => ("ST"),
  },
  {
    title: 'PackageType',
    render: () => ("CP"),
  },
  {
    title: 'BillTransportionTo',
    render: () => ("SHP"),
  },
  {
    title: 'NoOfPackages',
    dataIndex: 'box',
  },
  {
    title: 'Weight',
    dataIndex: 'weight',
  },
  {
    title: 'OrderNo',
    dataIndex: 'order_id',
  },
];

export default columns
