import { Stack, Typography } from '@mui/material';

export default function SiderHeader() {

  return (
    <Stack spacing={0.1} ml={1} sx={{pl: '10px'}}>
      <Typography variant='h5'>红松鼠超市</Typography>
      <Typography>运营管理系统</Typography>
    </Stack>
  )
}