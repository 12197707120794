import { createSlice } from '@reduxjs/toolkit'

// 搜索状态

export const slice = createSlice({
  name: 'Search',
  initialState: {
    open: false,
    dialog: false,
    type: null, 
    callback: null,
  },
  reducers: {
    setOpen: (state, action) => {
      state.open = action.payload
    },
    setDialog: (state, action) => {
      state.dialog = action.payload
    },
    setType: (state, action) => {
      state.type = action.payload
    },
    setCallback: (state, action) => {
      state.callback = action.payload
    }
  },
})

export const { 
  setOpen,
  setDialog,
  setType,
  setCallback
} = slice.actions
export const searchStore = state => state.search
export default slice.reducer

