import { Chip } from "@mui/material";
import { useSelector } from "react-redux";
import { editOrder, orderStates } from "redux/orderSlice";
import { adminSite } from "utils/common";
import { closeOrderDialog } from "../dialog/OrderDialog";

export default function PickerChip() {

  const { order } = useSelector(orderStates)

  const print = () => {
    window.open(adminSite()+'/print/order-picker/'+order.id);
    closeOrderDialog()
    editOrder({
      id: order.id,
      printed: 1
    })
  }

  return <Chip label="打印" onClick={print} />
}