import { useState, useEffect } from 'react'
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined'
import RSDialog from 'rsui/RSDialog'
import OrderList from 'components/order/OrderList'
import DashboardPanel from '../DashboardPanel'
import { getAbnormalOrders } from './api'

/* 异常订单数量 */
export default function AbnormalOrders() {
  const [ open, setOpen ] = useState( false )
  const [ orders, setOrders] = useState([])

  const fetchData = async () => {
    const data = await getAbnormalOrders()
    if(data) setOrders(data)
  }

  useEffect(() => {
   fetchData()
  }, [])

  const openDialog = () => {
    setOpen(true)
  }

  const closeDialog = () => {
    setOpen(false)
  }

  return (
    <>
      {orders.length > 0 &&
      <DashboardPanel 
        icon={ <LiveHelpOutlinedIcon /> }
        label="异常订单" 
        num={orders.length}
        color="error" 
        onClick={openDialog}
      />
      }

      <RSDialog
        title="异常订单" 
        open={open}
        onClose={closeDialog}
        noContentPadding
        fullWidth
      >
        {orders && <OrderList orders={orders} />}
      </RSDialog>
    </>
  )
}