import RSTable from "rsui/RSTable"
import ProductName from "components/product/components/ProductName"
import ExpiryDateChip from "components/product/chips/ExpiryDateChip"
import { useEffect, useState } from "react"
import { getExpiryCheckList } from "api/stock"

// 产品列表
export default function ExpiryCheckPrintPage() {

  const [ products, setProducts ] = useState([])
  const days = 60
  const loadData = async () => {
    const data = await getExpiryCheckList(days)
    if(data) setProducts(data)
    setTimeout(() => {
      window.print()
      window.history.back()
    }, 500)
  }

  useEffect(() => {
    loadData()
  }, [])

  const columns = [
    {
      title: 'SKU',
      render: (product) => ('#'+product.id),
    },
    {
      title: '产品',
      render: (product) => <ProductName product={product} />
    },
    {
      title: '保质期',
      render: (product) => <ExpiryDateChip product={product} />
    }
  ]


  return <RSTable data={products} columns={columns} />
}