
import { Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react';
import call from 'utils/call' 


export default function ProductCost() 
{

  const [num, setNum] = useState()

  const getCostPrice = async () => {
    const data = await call('test/getProductsWithoutCostPrice');
    setNum(data)
  }

  const setCostPrice = async () => {
    await call('test/setProductCostPrice');
  }

  useEffect(() => {
    getCostPrice()
    setCostPrice()
    const intervalID = setInterval(()=>{
      getCostPrice()
    }, 60000)

    return () => clearInterval(intervalID)
  }, [])

  useEffect(() => {
   
    setCostPrice()
    const intervalID = setInterval(()=>{
      setCostPrice()
    }, 1000)

    return () => clearInterval(intervalID)
  }, [])

  return (
  <Stack>
    <Typography>新数据结构下 需要给 Product 表 添加成本价</Typography>
    <Typography>没有成本价的产品 {num}</Typography>
  </Stack>
  )
}