import { createSlice } from '@reduxjs/toolkit'
import store from './store'
import { findProduct } from 'api/product'

/* 框架状态 */
export const slice = createSlice({
  name: 'productEdit',
  initialState: {
    // 产品
    product: null,
    productEditDialog: false,
    productEditCallback: null,
  },
  reducers: {
    

    // 产品
    setProduct:(state, action) => {
      state.product = action.payload
    },
    setProductEditDialog:(state, action) => {
      state.productEditDialog = action.payload
    },
    setProductEditCallback:(state, action) => {
      state.productEditCallback = action.payload
    },

  },
})

export const { 
  
  // 产品
  setProduct,
  setProductEditDialog,
  setProductEditCallback

} = slice.actions

export const productEditStates = state => state.productEdit
export default slice.reducer

// 产品编辑窗口
export const openProductEditDialog = async (productID, callback) => {
  store.dispatch(setProductEditDialog(true))
  if(callback) store.dispatch(setProductEditCallback(callback))
  const data = await findProduct(productID)
  if(data) store.dispatch(setProduct(data))
}

export const closeProductEditDialog = () => {
  store.dispatch(setProductEditDialog(false))
  store.dispatch(setProductEditCallback(null))
  store.dispatch(setProduct(null))
}