// UI
import {  Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material'

// 收据 明细
export default function OrderReceiptContent({order}) {

  //******************************************************//
  // 订单 项目 Table Rows

  const ItemHead = () => {
    return (
    <TableRow>
      <TableCell>Item</TableCell>
      <TableCell>Price</TableCell>
      <TableCell>Qty</TableCell>
      <TableCell>VAT</TableCell>
      <TableCell>Total</TableCell>
    </TableRow>
    )
  }

  const ItemRow = (props) => {
    const { item } = props 
    return (
    <TableRow>
      <TableCell>{item.name}</TableCell>
      <TableCell>
        {item.price !== "0.00" && '£'+item.price}
        {item.price === "0.00" && 'Free'}
      </TableCell>
      <TableCell>{item.qty}</TableCell>
      <TableCell>{item.vat_rate}</TableCell>
      <TableCell>£{item.amount}</TableCell>
    </TableRow>
    )
  }

  const FeeRow = (props) => {
    const { item, amount } = props 
    return (
    <TableRow>
      <TableCell><b>{item}</b></TableCell>
      <TableCell colSpan={2}></TableCell>
      <TableCell>20%</TableCell>
      <TableCell>£{amount}</TableCell>
    </TableRow>
    )
  }

  const VoucherRow = (props) => {
    const { item, note, amount } = props 
    return (
    <TableRow>
      <TableCell><b>{item}</b> {note}</TableCell>
      <TableCell colSpan={2}></TableCell>
      <TableCell>-</TableCell>
      <TableCell>-£{amount}</TableCell>
    </TableRow>
    )
  }

  const TotalRow = (props) => {
    const { item, amount } = props 
    return (
    <TableRow>
      <TableCell></TableCell>
      <TableCell colSpan={3}><b>{item}</b></TableCell>
      <TableCell>£{amount}</TableCell>
    </TableRow>
    )
  }


  return (
      <Table size='small'>
        <TableHead>
          <ItemHead />
        </TableHead>

        <TableBody>

          {order.items.map((item, index) => <ItemRow item={item} key={index} />)}

          { order.pack_fee !== "0.00" && 
            <FeeRow item="Packing" amount={order.pack_fee} />
          }

          { order.shipping_fee !== "0.00" && 
            <FeeRow item="Shipping" amount={order.shipping_fee} />
          }

          { order.vip_discount !== "0.00" && 
            <VoucherRow item="VIP Discount" amount={order.vip_discount} />
          }

          { order.gift_fee !== "0.00" && 
            <VoucherRow item="Voucher Discount" note={order.code} amount={order.gift_fee} />
          }

          { order.wallet !== "0.00" && 
            <VoucherRow item="RS Wallet" amount={order.gift_fee} />
          }

          <TotalRow item="Total(inc. VAT)" amount={order.amount} />

          <TotalRow item={"VAT@20%"} amount={order.standard_rate} />
          
          { order.reduced_rate > 0 && 
            <TotalRow item={"VAT@5%"} amount={order.reduced_rate} />
          }

        </TableBody>
      </Table>
  )
}