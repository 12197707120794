import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Stack, Chip } from '@mui/material'

export default function RSChipsGroup(props) {
  const { data } = props
  const [ active, setActive ] = useState(null)

  const handleClick = (label) => {
    setActive(label)
  }

  return (
    <Stack direction="row" spacing={1} mb={2}>
      { 
        data && data.map((d,index) => 
          <Chip 
            key={index} 
            label={d.label} 
            to={d.to} 
            onClick={()=>handleClick(d.label)} 
            component={Link} 
            variant={active===d.label?"":"outlined"} 
          />
        )
      }
    </Stack>
  )
}