import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { appStates } from "redux/AppSlice";
import RSLoader from "rsui/RSLoader";

export default function LayoutLoader() {
  const { loading } = useSelector(appStates)
  const ref = useRef()

  useEffect(() => {
    if(loading) ref.current.startLoading()
    else ref.current.stopLoading()
  }, [loading])

  return (
    <RSLoader ref={ref} />
  )
}