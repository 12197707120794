import { useEffect, useState } from "react"
import { Chip, Grid, Pagination, Stack } from "@mui/material"
import PurchaseList from "./components/PurchaseList"
import RSCard from "rsui/RSCard"
import { addPurchase, getPurchaseList } from "api/stock"
import { startLoading, stopLoading } from "utils/layout"

// 进货记录页面
export default function PurchasePage() {

  const [ purchases, setPurchase ] = useState([])
  const [ page, setPage ] = useState(1)

  const loadData = async () => {
    startLoading()
    const data = await getPurchaseList(page)
    stopLoading()
    if(data) setPurchase(data)
  }

  const changePage = (e, p) => { 
    setPage(p)
  }

  const addNew = async () => {
    startLoading()
    await addPurchase()
    stopLoading()
    loadData()
  }

  useEffect(() => {
    loadData()
  }, [page])

  return (
  <Grid container justifyContent="center" alignItems="center">
    <Grid item lg={6}>
      <Stack>
        <RSCard title="进货记录" action={<Chip label="新建" onClick={addNew} />}>
          <PurchaseList purchases={purchases} />
        </RSCard>
        <Pagination count={50} onChange={changePage} page={page} />
      </Stack>
    </Grid>
  </Grid>
  )
}