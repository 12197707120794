import List from '@mui/material/List';
import Drawer from '@mui/material/Drawer'

// Redux
import { useSelector } from 'react-redux';
import { appStates } from 'redux/AppSlice';

// Menu
import SiderHeader from './SiderHeader';
import MenuItemDashboard from './MenuItemDashboard';
import MenuItemPromotion from './MenuItemPromotion';
import MenuItemCustomer from './MenuItemCustomer';
import MenuItemNewOrder from './MenuItemNewOrder';
import MenuItemProduct from './MenuItemProduct';
import MenuItemSystem from './MenuItemSystem';
import MenuItemAnalytics from './MenuItemAnalytics';
import MenuItemStock from './MenuItemStock';
import MenuItemShipping from './MenuItemShipping';
import MenuItemChat from './MenuItemChat';
import MenuItemDelivery from './MenuItemDelivery';
import { hasPermission } from 'utils/layout';
import MenuItemTest from './MenuItemTest';
import MenuItemWeb from './MenuItemWeb';

const drawerWidth = 200;

export default function SiderMenu() {
  const { siderOpen } = useSelector(appStates)

  return (
    <Drawer
      sx={{
        displayPrint: 'none',
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        }
      }}
      variant="persistent"
      anchor="left"
      open={siderOpen}
    >
      <SiderHeader />
      <List>

        { hasPermission('driver') && <MenuItemDelivery /> }

        { hasPermission('staff') && <MenuItemDashboard /> }
        { hasPermission('staff') && <MenuItemNewOrder /> }
        { hasPermission('staff') && <MenuItemProduct /> }
        { hasPermission('staff') && <MenuItemStock /> }
        { hasPermission('staff') && <MenuItemPromotion /> }
        { hasPermission('staff') && <MenuItemWeb /> }
        
        { hasPermission('admin') && <MenuItemShipping /> }
        { hasPermission('admin') && <MenuItemAnalytics /> }
        { hasPermission('admin') && <MenuItemSystem /> }

        { hasPermission('developer') && <MenuItemTest /> }


        {/*
        
       
        <MenuItemCustomer />
        <MenuItemChat />
      */}
      </List>
    </Drawer>
  )
}