import { useEffect, useState } from 'react'
import { delivery_method } from 'utils/configs'
import RSMenu from 'rsui/RSMenu'

// *******************************************************************************************************
// 按钮 切换物流公司

export default function LogisticsChip({ order, callback }) {

  const [selectedIndex, setSelectedIndex] = useState(0)
  const [selectedValue, setSelectedValue] = useState('')

  useEffect(()=>{
    setSelectedIndex(order.logistics_id)
  }, [])

  useEffect(() => {
    for(let i = 0; i < delivery_method.length; i++) {
      if(parseInt(delivery_method[i].k) === parseInt(selectedIndex)) {
        setSelectedValue(delivery_method[i].label)
      }
    }
  }, [selectedIndex])

  const onChangeState = (item) => {
    if(item.k !== selectedIndex) {
      setSelectedIndex(item.k)
      callback({
        id: order.id,
        logistics_id: item.k
      })
    }
  }

  return <RSMenu label={selectedValue} items={delivery_method} callback={onChangeState} />
}