/* 库存调整记录 Card */

import { useState, useEffect } from "react"

import { Pagination, Stack } from '@mui/material'

import RSCard from "rsui/RSCard"
import RSTable from "rsui/RSTable"
import { getRecordList } from "api/stock"
import StockRecordEditChip, { StockRecordAddChip } from "./components/StockRecordEditChip"
import OnShelfChip from "components/product/chips/OnShelfChip";
import { startLoading, stopLoading } from "utils/layout"
import ProductName from "components/product/components/ProductName"


export default function StockRecordsPage() {
  
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(1)
  const [records, setRecords] = useState([])

  const columns = [
    {
      title: '日期',
      dataIndex: 'date',
    },
    {
      title: '产品',
      render: (item) => <ProductName product={item} />
    },
    {
      title: '增加',
      render: (item) => Number(item.qty > 0)?('+'+item.qty):''
    },
    {
      title: '减少',
      render: (item) => Number(item.qty < 0)?item.qty:''
    },
    {
      title: '调整后库存',
      dataIndex: 'stock_qty',
    },
    {
      title: '备注',
      dataIndex: 'type',
    },
    {
      title: '',
      render: (item) => 
        <Stack direction="row">
          <OnShelfChip product={{id: item.product_id, hide: item.hide}} />
          <StockRecordEditChip record={item} callback={loadData} />
        </Stack>
    }
  ];


  const loadData = async () => {
    startLoading()
    const data = await getRecordList(page)
    stopLoading()
    if(data){
      setRecords(data.data)
      setPages(data.pages)
    }
  }

  useEffect(() => {
    loadData()  
  }, [page])



  const changePage = (e, p) => { 
    setPage(p)
  }

  return(
    <RSCard title="库存调整" action={<StockRecordAddChip callback={loadData} />}>
      <Stack spacing={2}>
        <RSTable data={records} columns={columns} />
        {pages > 1 && <Pagination count={pages} onChange={changePage} page={page} />}
      </Stack>
    </RSCard>
  )
}