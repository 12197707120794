/* 产品管理 - 价格调整 */

import { useEffect, useState } from "react"
import { Pagination, Stack,  TextField } from "@mui/material"
import RSCard from "rsui/RSCard"
import FeatureFilter from "./components/FeatureFilter"
import ProductList from "./components/ProductList"
import { filterProduct } from "api/product"
import RSCheckChip from "rsui/RSCheckChip"
import AddNewProductChip from "./components/AddNewProductChip"
import SortChip from "./components/SortChip"

export default function ProductManagement() {

  const [products, setProducts] = useState([])
  const [filter, setFilter] = useState({
    page: 1,
    featureKey: 0,
    feature: '全部', 
    shelfChecked: false,
    shelf: '仅上架',
    keywords:'',
    sort: {key: 'id', value: 'SKU'}
  })
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(1)

  useEffect(()=>{
    loadData()
  }, [filter])

  const loadData = async () => {
    //startLoading()
    const data = await filterProduct(filter)
    //stopLoading()
    if(data) {
      setProducts(data.products)
      setPages(data.pages)
    }
  }

  const changePage = (e, p) => { 
    setFilter({
      ...filter,
      page: p
    })
    setPage(p)
  }

  // Filter A 筛选 部分
  const handleFeatureFilterSelect= (option) => {
    setFilter({
      ...filter,
      featureKey: option.key,
      feature: option.value,
      page: 1,
    })
    setPage(1)
  }

  // Filter B 筛选 上架
  const handleShelfFilterSelect = (checked) => {
    setFilter({
      ...filter,
      shelfChecked: checked,
      shelf: checked?'':'仅上架',
      page: 1,
    })
    setPage(1)
  }

  //******************************************
  // 关键字搜索

  const onKeyUpKeywords = (e) => {
    if(e.key==="Enter") {
      setFilter({
        ...filter,
        keywords: e.target.value
      })
    }
  }


  const addNewCallback = () => {
    loadData()
    setFilter({
      ...filter,
      shelfChecked: true,
      shelf: '',
      page: 1,
    })
    setPage(1)
  }

  const Actions = () => 
  <Stack direction='row' alignItems="center">
    <AddNewProductChip callback={addNewCallback} />
    <FeatureFilter defaultKey={filter.featureKey} callback={handleFeatureFilterSelect} />
    <SortChip filter={filter} setFilter={setFilter} />
    <RSCheckChip label="含下架" checked={filter.shelfChecked} onClick={handleShelfFilterSelect} />
    <TextField 
      size="small" 
      placeholder="搜索产品"
      onKeyUp={onKeyUpKeywords}
    />
  </Stack>

  return (
    <RSCard title="产品价格管理" action={<Actions />}>
      <Stack spacing={2}>
        <ProductList 
          products={products} 
          reload={loadData}
        />
        <Pagination count={pages} hideNextButton={true} hidePrevButton={true} onChange={changePage} page={page} siblingCount={10} />
      </Stack>
    </RSCard>
  )
}