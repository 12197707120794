/* 代金券 接口 */

import call from 'utils/call' 

/***************************************************************/
export async function getAllTemplates() {
  console.log("获得代金券模板列表")
  return await call('voucher/getAllTemplates')
}

/***************************************************************/
export async function saveTemplate(data) {
  console.log("保存代金券模板")
  await call('voucher/saveTemplate', data)
}

/***************************************************************/
export async function deleteTemplate(id) {
  console.log("删除代金券模板")
  await call('voucher/deleteTemplate/'+id)
}

/***************************************************************/
export async function sendVoucher(data) {
  console.log("发送代金券")
  await call('voucher/send', data)
}

/***************************************************************/
export async function revokeVoucher(voucherID) {
  console.log("撤销代金券")
  await call('voucher/revoke/'+voucherID)
}

/***************************************************************/
export async function getVouchers(page) {
  console.log("获得代金券列表")
  return await call('voucher/getVouchers/'+page)
}

/***************************************************************/
export async function sumVouchers() {
  console.log("获得代金券统计")
  return await call('voucher/sumVouchers')
}
