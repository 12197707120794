import { useState } from "react";
import { addANewOrder } from "api/order";
import { Button, Chip, Stack, TextField } from "@mui/material";
import RSDialog from "rsui/RSDialog";
import { loadNewOrders } from "redux/newOrdersSlice";
import { startLoading, stopLoading } from "utils/layout";

// 新建订单
export default function AddNewChip() {

  const [open, setOpen] = useState(false)
  const [customerID, setCustomerID] = useState('')

  const openDialog = () => setOpen(true)

  const closeDialog = () => setOpen(false)

  const changeID = (e) => {
    setCustomerID(e.target.value)
  }

  const submit = async () => {
    if(customerID) {
      closeDialog()
      startLoading()
      await addANewOrder(Number(customerID.trim()))
      await loadNewOrders()
      stopLoading()
    }
  }

  return (
    <>
    <Chip label="新建" onClick={openDialog} />
    <RSDialog 
      open={open} 
      onClose={closeDialog}
      title="新建订单"
    >
      <Stack direction="row">
        <TextField 
          value={customerID} 
          type="number" 
          onChange={changeID} 
          size="small"
          label="客户ID"
        />
        <Button 
          variant="contained" 
          onClick={submit}
          disabled={!customerID}
        >新建</Button>
      </Stack>
    </RSDialog>
    </>
  )
}