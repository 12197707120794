/* 邮件通知接口 */

import call from 'utils/call' 

/***************************************************************/
export async function sendEmail(data) {
  console.log("发送邮件")
  await call('email/send', data)
}

/***************************************************************/
export async function getEmailTemplates() {
  console.log("获取通知模板")
  return await call('email/getTemplates')
}

/***************************************************************/
export async function updateEmailTemplate(data) {
  console.log("保存通知模板")
  return await call('email/updateTemplate', data)
}

/***************************************************************/
export async function batchEmail(data) {
  console.log("批量发送")
  return await call('email/batch', data)
}

