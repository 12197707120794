import { Avatar, Grid } from '@mui/material'
import { appStates } from 'redux/AppSlice'
import { GoogleAuthProvider, getAuth, signInWithPopup } from 'firebase/auth'
import { useSelector } from 'react-redux'
import { startLoading, stopLoading, showFail } from 'utils/layout'


export default function AuthPage() {

  const { signed } = useSelector(appStates)

  const handleClick = () => {
    const auth = getAuth()
    startLoading()
    const provider = new GoogleAuthProvider()
    signInWithPopup(auth, provider)
      .catch(err => {
        console.log(err)
        showFail(err.message)
        stopLoading()
      })
  }

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{minHeight: '100vh'}}
      spacing={0}
    >
      <Grid item>
        {signed !== null &&
          <Avatar 
            alt="logo" 
            src="/logo/avatar_108x108.png" 
            onClick={handleClick} 
            sx={{
              width: 100,
              height: 100,
              cursor: 'pointer'
            }}
          />
        }
      </Grid>
    </Grid>
  )
}