import { Dialog, DialogContent, LinearProgress } from "@mui/material";
import { forwardRef, useImperativeHandle, useState } from "react";

const RSLoader = forwardRef((props, ref) => {

  const [open, setOpen] = useState(false)

  useImperativeHandle(ref, ()=> ({
    startLoading() {
      setOpen(true)
    },
    stopLoading() {
      setOpen(false)
    }
  }))

  return (
    <Dialog
      open={open}
      maxWidth="xs"
    >
      <DialogContent sx={{width: 100}}>
        <LinearProgress />
      </DialogContent>
    </Dialog>
  )
}) 
 
export default RSLoader